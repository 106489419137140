
frappe.ui.form.Layout = class BondLayout{

	constructor(opts){
		this.views = {};
		this.pages = [];
		this.sections = [];
		this.fields_list = [];
		this.fields_dict = {};
		this.classes = {};
		$.extend(this, opts);
		if(this.frm && this.frm.forms){
			this.frm.forms.push(this);
		}
	}

	make() {
		if(!this.parent && this.body) {
			this.parent = this.body;
		}
		this.$fieldset = $(`<fieldset class="h-100" style="display:block;" id="display-1">
								<div class="d-flex flex-wrap h-100 justify-content-center overflow-auto">
									<div class="form-padding right-col-form active mx-0 row justify-content-center position-relative form-layout">
									</div>
								</div>
							</fieldset>`).appendTo(this.parent);

		this.wrapper = this.$fieldset.find(".form-layout");

		this.message = $('<div class="form-message text-muted small hidden"></div>').appendTo(this.wrapper);
		if(!this.fields) {
			this.fields = this.get_doctype_fields();
		}
		if(!bond_assets.device.is_mobile_device()){
			$(`<div class="bg-gradient"></div>`).appendTo(this.wrapper);
			$(`<div class="bg-gradient bg-gradient--bottom"></div>`).appendTo(this.wrapper);
		}
		this.parse_classes();
		this.set_layout_classes();
		this.setup_tabbing();
		this.render();

	}
	show_empty_form_message() {
		if(!(this.wrapper.find(".frappe-control:visible").length || this.wrapper.find(".section-head.collapsed").length)) {
			this.show_message(__("This form does not have any input"));
		}
	}
	set_layout_classes(){
		if(this.classes.right_col){
			this.$fieldset.find(".form-layout").addClass(this.classes.right_col);
		}
	}
	get_doctype_fields() {
		let fields = [
			{
				parent: this.frm.doctype,
				fieldtype: 'Data',
				fieldname: '__newname',
				reqd: 1,
				hidden: 1,
				label: __('Name'),
				get_status: function(field) {
					if (field.frm && field.frm.is_new()
						&& field.frm.meta.autoname
						&& ['prompt', 'name'].includes(field.frm.meta.autoname.toLowerCase())) {
						return 'Write';
					}
					return 'None';
				}
			}
		];
		fields = fields.concat(frappe.meta.sort_docfields(frappe.meta.docfield_map[this.doctype]));
		return fields;
	}
	show_message(html, color) {
		if (this.message_color) {
			// remove previous color
			this.message.removeClass(this.message_color);
		}
		this.message_color = (color && ['yellow', 'blue'].includes(color)) ? color : 'blue';
		if(html) {
			if(html.substr(0, 1)!=='<') {
				// wrap in a block
				html = '<div>' + html + '</div>';
			}
			this.message.removeClass('hidden').addClass(this.message_color);
			$(html).appendTo(this.message);
		} else {
			this.message.empty().addClass('hidden');
		}
	}
	parse_classes(classes){
		if(classes){
			return JSON.parse(classes);
		}else if(this.df && this.df.custom_classes){
			this.classes = JSON.parse(this.df.custom_classes);
		}
	}
	render(new_fields) {
		var me = this;
		var fields = new_fields || this.fields;

		this.section = null;
		this.column = null;

		if (this.with_dashboard) {
			this.setup_dashboard_section();
		}

		if (this.no_opening_section()) {
			this.make_section();
		}
		$.each(fields, function(i, df) {
			switch(df.fieldtype) {
				case "Fold":
					me.make_page(df);
					break;
				case "Section Break":
					me.make_section(df);
					break;
				case "Column Break":
					me.make_column(df);
					break;
				default:
					me.make_field(df);
			}
		});
	}

	no_opening_section() {
		return (this.fields[0] && this.fields[0].fieldtype!="Section Break") || !this.fields.length;
	}

	setup_dashboard_section() {
		if (this.no_opening_section()) {
			this.fields.unshift({fieldtype: 'Section Break'});
		}

		this.fields.unshift({
			fieldtype: 'Section Break',
			fieldname: '_form_dashboard',
			label: __('Dashboard'),
			cssClass: 'form-dashboard',
			collapsible: 1,
			//hidden: 1
		});
	}

	replace_field(fieldname, df, render) {
		df.fieldname = fieldname; // change of fieldname is avoided
		if (this.fields_dict[fieldname] && this.fields_dict[fieldname].df) {
			const fieldobj = this.init_field(df, render);
			this.fields_dict[fieldname].$wrapper.remove();
			this.fields_list.splice(this.fields_dict[fieldname], 1, fieldobj);
			this.fields_dict[fieldname] = fieldobj;
			if (this.frm) {
				fieldobj.perm = this.frm.perm;
			}
			this.section.fields_list.splice(this.section.fields_dict[fieldname], 1, fieldobj);
			this.section.fields_dict[fieldname] = fieldobj;
			this.refresh_fields([df]);
		}
	}

	make_field(df, colspan, render) {
		!this.section && this.make_section();
		!this.column && this.make_column();

		const fieldobj = this.init_field(df, render);
		this.fields_list.push(fieldobj);
		this.fields_dict[df.fieldname] = fieldobj;
		if(this.frm) {
			fieldobj.perm = this.frm.perm;
		}

		if(this.frm && this.frm.fields_dict){
			this.frm.fields_dict[this.step.df.doctype_fieldname][df.fieldname] = fieldobj;
			this.frm.fields.push(fieldobj);
		}

		this.section.fields_list.push(fieldobj);
		this.section.fields_dict[df.fieldname] = fieldobj;
		fieldobj.section = this.section;
	}

	init_field(df, render=false) {
		let fieldtype = df.fieldtype;
		if(bond_assets.device.is_mobile_device()){
			fieldtype = df.mobile_fieldtype;
		}
		df.fieldtype = fieldtype;
		const fieldobj = frappe.ui.form.make_control({
			df: df,
			doctype: this.doctype,
			parent: this.column.wrapper.get(0),
			frm: this.frm,
			render_input: render,
			doc: this.doc,
			step: this.step,
			layout: this,
			sub_step: this.sub_step
		});
		fieldobj.layout = this;

		if(this.sub_step){
			fieldobj.$wrapper.addClass("d-none");
		}

		return fieldobj;
	}

	make_page(df) {
		var me = this,
			head = $('<div class="form-clickable-section text-center">\
				<a class="btn-fold h6 text-muted">'+__("Show more details")+'</a>\
			</div>').appendTo(this.wrapper);

		this.page = $('<div class="form-page second-page hide"></div>').appendTo(this.wrapper);

		this.fold_btn = head.find(".btn-fold").on("click", function() {
			var page = $(this).parent().next();
			if(page.hasClass("hide")) {
				$(this).removeClass("btn-fold").html(__("Hide details"));
				page.removeClass("hide");
				frappe.utils.scroll_to($(this), true, 30);
				me.folded = false;
			} else {
				$(this).addClass("btn-fold").html(__("Show more details"));
				page.addClass("hide");
				me.folded = true;
			}
		});

		this.section = null;
		this.folded = true;
	}

	unfold() {
		this.fold_btn.trigger('click');
	}

	make_section(df) {
		this.section = new frappe.ui.form.BondSection(this, df);

		// append to layout fields
		if(df) {
			this.fields_dict[df.fieldname] = this.section;
			this.fields_list.push(this.section);
		}

		this.column = null;
	}

	make_column(df) {
		this.column = new frappe.ui.form.BondColumn(this.section, df);
		if(df && df.fieldname) {
			this.fields_list.push(this.column);
		}
	}

	refresh(doc) {
		var me = this;
		if(doc) this.doc = doc;

		if (this.frm) {
			this.wrapper.find(".empty-form-alert").remove();
		}

		// NOTE this might seem redundant at first, but it needs to be executed when frm.refresh_fields is called
		me.attach_doc_and_docfields(true);

		if(this.frm && this.frm.wrapper) {
			$(this.frm.wrapper).trigger("refresh-fields");
		}

		// dependent fields
		this.refresh_dependency();

		// refresh sections
		this.refresh_sections();


		// collapse sections
		if(this.frm) {
			this.refresh_section_collapse();
		}
	}

	refresh_sections() {
		var cnt = 0;
		return new Promise((resolve, reject)=>{

			// hide invisible sections and set alternate background color
			this.wrapper.find(".form-section:not(.hide-control)").each(function() {
				var $this = $(this).removeClass("empty-section")
					.removeClass("visible-section")
					.removeClass("shaded-section");
				if(!$this.find(".frappe-control:not(.hide-control)").length
					&& !$this.hasClass('form-dashboard')) {
					// nothing visible, hide the section
					$this.addClass("empty-section");
				} else {
					$this.addClass("visible-section");
					if(cnt % 2) {
						$this.addClass("shaded-section");
					}
					cnt++;
				}
			});
			resolve(this);
		});
	}

	refresh_fields(fields) {
		let fieldnames = fields.map((field) => {
			if(field.fieldname) return field.fieldname;
		});

		this.fields_list.map(fieldobj => {
			if(fieldnames.includes(fieldobj.df.fieldname)) {
				fieldobj.refresh();
				if(fieldobj.df["default"]) {
					fieldobj.set_input(fieldobj.df["default"]);
				}
			}
		});
	}
	add_fields(fields) {
		this.render(fields);
		this.refresh_fields(fields);
	}

	refresh_section_collapse() {
		if(!this.doc) return;

		for(var i=0; i<this.sections.length; i++) {
			var section = this.sections[i];
			var df = section.df;
			if(df && df.collapsible) {
				var collapse = true;

				if(df.collapsible_depends_on) {
					collapse = !this.evaluate_depends_on_value(df.collapsible_depends_on);
				}

				if (collapse && section.has_missing_mandatory()) {
					collapse = false;
				}

				if(df.fieldname === '_form_dashboard') {
					collapse = localStorage.getItem('collapseFormDashboard')==='yes' ? true : false;
				}

				section.collapse(collapse);
			}
		}
	}

	attach_doc_and_docfields(refresh) {
		var me = this;
		for(var i=0, l=this.fields_list.length; i<l; i++) {
			var fieldobj = this.fields_list[i];
			if(me.doc) {
				fieldobj.doc = me.doc;
				fieldobj.doctype = me.doc.doctype;
				fieldobj.docname = me.doc.name;
				fieldobj.df = frappe.meta.get_docfield(me.doc.doctype,
					fieldobj.df.fieldname, me.frm ? me.frm.doc.name : me.doc.name) || fieldobj.df;

				// on form change, permissions can change
				if(me.frm) {
					fieldobj.perm = me.frm.perm;
				}
			}
			refresh && fieldobj.df && fieldobj.refresh && fieldobj.refresh();
		}
	}

	refresh_section_count() {
		this.wrapper.find(".section-count-label:visible").each(function(i) {
			$(this).html(i+1);
		});
	}
	setup_tabbing() {
		var me = this;
		this.wrapper.on('keydown', function(ev){
			if(ev.which==9) {
				let $target = $(ev.target);
				if($target.hasClass("html-input-field")){
					return true;
				}
				if($target.hasClass("nice-select") && !$target.attr("data-fieldname")){
					$target = $target.prev();
				}else if($target.is("ul")){
					$target = $target.parent().prev();
				}
				let fieldname = $target.attr("data-fieldname");
				if(!is_null(fieldname)) {
					return me.tab_to_next_field(fieldname);
				}else{	
					// if fieldname is null then don't allow tab
					return false;
				}
			}
		});
	}
	tab_to_next_field(fieldname){
		var cur_field = this.fields_dict[fieldname];

		let field_idx = this.fields_list.indexOf(cur_field);

		// don't move to next step if current field is null;
		if(cur_field.df.reqd && cur_field.get_fullfill_status(true) === false){
			let msg = __("{0} is required.", [cur_field.df.placeholder]);
			cur_field.show_error_msg(msg);
			return false;
		}else{
			cur_field.hide_error_msg();
		}

		// check if it's grouped field using colum-break
		if(cur_field.$wrapper.hasClass("column-break")){
			let _fields = cur_field.$wrapper.find("select, input");
			let $input = null, found=false;;
			for(var j=0;j<_fields.length; j++){
				$input = $(_fields[j]);
				if(this.fields_dict[$input.attr("data-fieldname")].df.fieldname === cur_field.df.fieldname){
					cur_field.idx = j;
				}else if(!is_null(cur_field.idx) && j > cur_field.idx){
					this._tab_focus_to_field(this.fields_dict[$input.attr("data-fieldname")]);
					cur_field.idx = undefined;
					found = true;
					break;
				}
			}
			if(found)return found;
		}
		for(var i=field_idx+1; i < this.fields_list.length; i++){
			cur_field = this.fields_list[i];
			if(!cur_field.$wrapper.hasClass("hide-control")){
				this._tab_focus_to_field(this.fields_dict[cur_field.$wrapper.attr("data-fieldname")]);
			}
			if(this._focused){
				break;
			}
			cur_field = this.fields_list[i];
		}
		if(this._focused)return true;
		return false;
	}
	_tab_focus_to_field(field){
		field.enable_field();
		if(field.df.fieldtype == "Select"){
			field.$wrapper.find(`select[data-fieldname='${field.df.fieldname}']`).next()[0].click();
		}else{
			field.$input.focusin();
		}
		this._focused = true;
	}
	handle_tab(doctype, fieldname, shift) {
		var me = this,
			grid_row = null,
			prev = null,
			fields = me.fields_list,
			in_grid = false,
			focused = false;

		// in grid
		if(doctype != me.doctype) {
			grid_row = me.get_open_grid_row();
			if(!grid_row || !grid_row.layout) {
				return;
			}
			fields = grid_row.layout.fields_list;
		}

		for(var i=0, len=fields.length; i < len; i++) {
			if(fields[i].df.fieldname==fieldname) {
				if(shift) {
					if(prev) {
						this.set_focus(prev);
					} else {
						$(this.primary_button).focus();
					}
					break;
				}
				if(i < len-1) {
					focused = me.focus_on_next_field(i, fields);
				}

				if (focused) {
					break;
				}
			}
			if(this.is_visible(fields[i]))
				prev = fields[i];
		}

		if (!focused) {
			// last field in this group
			if(grid_row) {
				// in grid
				if(grid_row.doc.idx==grid_row.grid.grid_rows.length) {
					// last row, close it and find next field
					grid_row.toggle_view(false, function() {
						grid_row.grid.frm.layout.handle_tab(grid_row.grid.df.parent, grid_row.grid.df.fieldname);
					});
				} else {
					// next row
					grid_row.grid.grid_rows[grid_row.doc.idx].toggle_view(true);
				}
			} else {
				$(this.primary_button).focus();
			}
		}

		return false;
	}
	focus_on_next_field(start_idx, fields) {
		// loop to find next eligible fields
		for(var i= start_idx + 1, len = fields.length; i < len; i++) {
			var field = fields[i];
			if(this.is_visible(field)) {
				if(field.df.fieldtype==="Table") {
					// open table grid
					if(!(field.grid.grid_rows && field.grid.grid_rows.length)) {
						// empty grid, add a new row
						field.grid.add_new_row();
					}
					// show grid row (if exists)
					field.grid.grid_rows[0].show_form();
					return true;

				} else if(!in_list(frappe.model.no_value_type, field.df.fieldtype)) {
					this.set_focus(field);
					return true;
				}
			}
		}
	}
	is_visible(field) {
		return field.disp_status==="Write" && (field.$wrapper && field.$wrapper.is(":visible"));
	}
	set_focus(field) {
		// next is table, show the table
		if(field.df.fieldtype=="Table") {
			if(!field.grid.grid_rows.length) {
				field.grid.add_new_row(1);
			} else {
				field.grid.grid_rows[0].toggle_view(true);
			}
		} else if(field.editor) {
			field.editor.set_focus();
		} else if(field.$input) {
			field.$input.focus();
		}
	}
	get_open_grid_row() {
		return $(".grid-row-open").data("grid_row");
	}
	refresh_dependency() {
		// Resolve "depends_on" and show / hide accordingly
		if(this.frm && this.frm.handle_awesomplete_bugs){
			this.frm.handle_awesomplete_bugs();
		}
		return new Promise((resolve, reject)=>{
			if(this.frm && this.frm.sub_steps_dict){
				this.refresh_step_dependency();
				this.frm.sub_steps.forEach((sub_step)=>{
					this.refresh_sub_step_dependency(sub_step);
					this._refresh_depdenency(sub_step.form);
				});
			}else{
				this._refresh_depdenency(this);
			}
			resolve(this);
		});
	}
	
	refresh_step_dependency(){
		if(this.frm.init_form){
			return;
		}
		let has_dep = false;
		this.frm.steps.forEach((step)=>{
			step.dependencies_clear = true;
			if(step.df.depends_on){
				has_dep = true;
			}
		});
		if(!has_dep){
			return;
		}
		this.frm.steps.forEach((step)=>{
			step.guardian_has_value = true;
			if(step.df.depends_on) {
				// evaluate guardian

				if(step.df.depends_on){
					step.guardian_has_value = this.evaluate_depends_on_value(step.df.depends_on);
				}

				// show / hide
				if(step.guardian_has_value) {
					if(step.df.hidden_due_to_dependency) {
						step.df.hidden_due_to_dependency = false;
						step.refresh_dependency();
					}
				} else {
					if(!step.df.hidden_due_to_dependency) {
						step.df.hidden_due_to_dependency = true;
						step.refresh_dependency();
					}
				}
			}
		});
	}
	refresh_sub_step_dependency(sub_step){
	}
	_refresh_depdenency(me){
		
		// build dependants' dictionary
		var has_dep = false;
		for(var fkey in me.fields_list) {
			var f = me.fields_list[fkey];
			f.dependencies_clear = true;
			if(f.df.depends_on || f.df.read_only_depends_on) {
				has_dep = true;
			}
		}

		if(!has_dep)return;
		// show / hide based on values
		for(var i=me.fields_list.length-1;i>=0;i--) {
			var f = me.fields_list[i];
			f.guardian_has_value = true;
			if(f.df.depends_on || f.df.read_only_depends_on) {
				// evaluate guardian

				if(f.df.depends_on){
					f.guardian_has_value = me.evaluate_depends_on_value(f.df.depends_on);
				}

				if(f.df.read_only_depends_on){
					f.df.disable_field = me.evaluate_depends_on_value(f.df.read_only_depends_on);
				}

				// show / hide
				if(f.guardian_has_value) {
					if(f.df.hidden_due_to_dependency) {
						f.df.hidden_due_to_dependency = false;
						f.refresh();
					}
				} else {
					if(!f.df.hidden_due_to_dependency) {
						f.df.hidden_due_to_dependency = true;
						f.refresh();
					}
				}
			}
		}

		me.refresh_section_count();
	}
	evaluate_depends_on_value(expression) {
		var out = null;
		var doc = this.doc;

		if (!doc && this.get_values) {
			var doc = this.get_values(true);
		}

		if (!doc) {
			return;
		}

		var parent = this.frm ? this.frm.doc : null;
		var frm = this.frm;


		if(typeof(expression) === 'boolean') {
			out = expression;

		} else if(typeof(expression) === 'function') {
			out = expression(doc);

		} else if(expression.substr(0,5)=='eval:') {
			try {
				out = eval(expression.substr(5));
				if(parent && parent.istable && expression.includes('is_submittable')) {
					out = true;
				}
			} catch(e) {
				console.log(e);
				console.log(expression);
				if(bond_assets.dev.is_developer_mode()){
					console.error(e);
					console.error(expression);
				}
				frappe.throw(__('Invalid "depends_on" expression'));
			}

		} else if(expression.substr(0,3)=='fn:' && this.frm) {
			out = this.frm.script_manager.trigger(expression.substr(3), this.doctype, this.docname);
		} else {
			var value = doc[expression];
			if($.isArray(value)) {
				out = !!value.length;
			} else {
				out = !!value;
			}
		}

		return out;
	}
}

frappe.ui.form.BondSection = class BondSection{
	constructor(layout, df) {
		var me = this;
		this.layout = layout;
		this.df = df || {};
		this.fields_list = [];
		this.fields_dict = {};

		this.make();
		// if(this.frm)
		// 	this.section.body.css({"padding":"0px 3%"})
		this.row = {
			wrapper: this.wrapper
		};

		if (this.df.collapsible && this.df.fieldname !== '_form_dashboard') {
			this.collapse(true);
		}

		this.refresh();
	}
	make() {
		if(!this.layout.page) {
			this.layout.page = this.layout.wrapper;
		}

		this.wrapper = this.layout.page;
		this.layout.sections.push(this);

		if(this.df) {
			if(this.df.label) {
				this.make_head();
			}
			if(this.df.description) {
				$('<div class="col-sm-12 small text-muted form-section-description">' + __(this.df.description) + '</div>')
					.appendTo(this.wrapper);
			}
			if(this.df.cssClass) {
				this.wrapper.addClass(this.df.cssClass);
			}
		}


		// for bc
		this.body = this.wrapper;
	}
	make_head() {
		var me = this;
		if(!this.df.collapsible) {
			$('<div class="col-sm-12"><h6 class="form-section-heading uppercase">'
				+ __(this.df.label) + '</h6></div>')
				.appendTo(this.wrapper);
		} else {
			this.head = $('<div class="section-head"><a class="h6 uppercase">'
				+__(this.df.label)+'</a><span class="octicon octicon-chevron-down collapse-indicator"></span></div>').appendTo(this.wrapper);

			// show / hide based on status
			this.collapse_link = this.head.on("click", function() {
				me.collapse();
			});

			this.indicator = this.head.find(".collapse-indicator");
		}
	}
	refresh() {
		if(!this.df)
			return;

		// hide if explictly hidden
		var hide = this.df.hidden || this.df.hidden_due_to_dependency;

		// hide if no perm
		//if(!hide && this.layout && this.layout.frm && !this.layout.frm.get_perm(this.df.permlevel || 0, "read")) {
		//	hide = true;
		//}

		this.wrapper.toggleClass("hide-control", !!hide);
	}
	collapse(hide) {
		// unknown edge case
		if (!(this.head && this.body)) {
			return;
		}

		if(hide===undefined) {
			hide = !this.body.hasClass("hide");
		}

		if (this.df.fieldname==='_form_dashboard') {
			localStorage.setItem('collapseFormDashboard', hide ? 'yes' : 'no');
		}

		this.body.toggleClass("hide", hide);
		this.head.toggleClass("collapsed", hide);
		this.indicator.toggleClass("octicon-chevron-down", hide);
		this.indicator.toggleClass("octicon-chevron-up", !hide);

		// refresh signature fields
		this.fields_list.forEach((f) => {
			if (f.df.fieldtype=='Signature') {
				f.refresh();
			}
		});
	}
	is_collapsed() {
		return this.body.hasClass('hide');
	}
	has_missing_mandatory() {
		var missing_mandatory = false;
		for (var j=0, l=this.fields_list.length; j < l; j++) {
			var section_df = this.fields_list[j].df;
			if (section_df.reqd && this.layout.doc[section_df.fieldname]==null) {
				missing_mandatory = true;
				break;
			}
		}
		return missing_mandatory;
	}
}

frappe.ui.form.BondColumn = class BondColumn{
	constructor(section, df) {
		if(!df) df = {};

		this.df = df;
		this.section = section;
		this.make();
		this.resize_all_columns();
	}
	make() {
		this.wrapper = this.section.body;

		if (this.df.label) {
			$('<label class="control-label">' + __(this.df.label)
				+ '</label>').appendTo(this.wrapper);
		}
	}
	resize_all_columns() {
		// distribute all columns equally
		var colspan = cint(12 / this.section.wrapper.find(".form-column").length);

		this.section.wrapper.find(".form-column").removeClass()
			.addClass("form-column")
			.addClass("col-sm-" + colspan);

	}
	refresh() {
		this.section.refresh();
	}
}
