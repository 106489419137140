
frappe.ui.form.ControlTableMultiSelect = class BondControlTableMultiSelect extends frappe.ui.form.ControlInput{
	make_input() {
		if(this.$input){
			return;
		}
		this.generate_fields();
		this.$input = $("");
		this.data = [];
		this.rows = [];
		this.rows_dict = {};
		this.add_row(true);
	}
	make_wrapper(){
		super.make_wrapper();
		this.$wrapper.find(".field-label")
			.addClass("field-label-table");

		this.$wrapper.find(".field-description")
			.addClass("field-description-table");
	}

	generate_fields(){
		this.fields = [];
		if(this.custom_df && this.custom_df.fields){
			this.fields = this.custom_df.fields;
		}
	}

	add_row(is_primary=false){
		let row = new frappe.ui.form.ControlTableMultiSelectRow({
			fields: this.fields,
			table: this,
			render_input: true,
			parent: this.$wrapper,
			is_primary: is_primary,
			frm: this.frm,
			doc: this.doc,
			step: this.step,
			sub_step: this.sub_step,
			layout: this.layout,
		});
		if(is_primary){
			this.primary_row = row;
		}
		this.rows.push(row);
		this.adjust_classes();
		this.handle_add_and_remove();
		this.rows_dict[row.row_id] = row;
	}

	adjust_classes(){
		this.$wrapper.find(".js-form-field").removeClass("col-lg-11 col-xl-9");
		this.$wrapper.removeClass("col-lg-11 col-xl-9");
		if(bond_assets.device.is_mobile_device()){
			this.$wrapper.find(".js-form-field").removeClass("pl-4 pr-4").addClass("pl-1 pr-1");
		}
	}

	handle_add_and_remove(){
		this.$wrapper.find(".actions-btns").remove();
		this.$btn_wrapper = $(`<div class="actions js-form-field text-center position-relative col-12 mt-5 mt-md-0 pt-3 pt-sm-0 mb-4 actions-btns">
									<button type="button" name="submit" class="btn btn-success btn-lg mt-3 mx-1 mb-2 btn-add-row" style="background-image: none; background-color:none">Add</button>
									<button type="button" name="back" class="btn btn-danger btn-lg mt-3 mx-1 mb-2 btn-del-row" disabled>Delete</button>
								</div>`).appendTo(this.$wrapper);
		
		
		this.$btn_add  = this.$btn_wrapper.find(".btn-add-row");
		this.$btn_del = this.$btn_wrapper.find(".btn-del-row");

		if(this.rows.length > 1){
			this.enable_delete_action();
		}
		this.$btn_add.on("click", (e)=>{
			e.preventDefault();
			this.add_row(false);
			return false;
		});

		this.$btn_del.on("click", (e)=>{
			e.preventDefault();
			if(this.rows.length == 1){
				this.disable_delete_action();
				return;
			}
			let row = this.rows.pop();
			row.delete();
			if(this.rows.length == 1){
				this.disable_delete_action();
			}
			this.handle_table_actions(row);
			return false;
		});

	}

	handle_table_actions(fieldobj, row){
		let data = this.get_values();
		try{
			if(!this.layout){
				return;
			}
			if(this.layout && this.layout.call_events){
				this.layout.call_events({
					target: this.$wrapper
				}, this).then(()=>{
					this.parse_validate_and_set_in_model(data);
				});
			}else{
				this.parse_validate_and_set_in_model(data);
			}
		}catch(e){
			console.log(e);
		}
	}

	disable_delete_action(){
		this.$btn_del
			.addClass("disabled")
			.attr("disabled", true)
	}
	
	enable_delete_action(){
		this.$btn_del
			.removeClass("disabled")
			.attr("disabled", false);
	}

	disable_add_action(){
		this.$btn_add
			.addClass("disabled")
			.attr("disabled", true);
	}

	enable_add_action(){
		this.$btn_add
			.removeClass("disabled")
			.attr("disabled", false);
	}

	parse(value) {
		return value;
	}

	validate(value) {
		const rows = (value || []).slice();
		return value;
	}

	set_input(value){
		this.data = value;
	}

	get_input_value(){
		return this.data;
	}

	toggle_label(show) {
		this.$wrapper.find(".field-info .field-label-table").toggleClass("hide", !show);
	}
	toggle_description(show) {
		this.$wrapper.find(".field-info .field-description-table").toggleClass("hide", !show);
	}
	set_label(label) {
		this.$label = this.$wrapper.find(".field-info .field-label-table").empty();
		if(this.df.label){
			this.$label.text(`${this.df.label}`);
			if(bond_assets.device.is_mobile_device()){
				this.$label.addClass("form-question__title")
					.removeClass("form-font font-weight-normal");
			}
		}
	}
	set_description(description) {
		if(this.df.hide_on_mobile && bond_assets.device.is_mobile_device()){
			return;
		}
		if (!is_null(description)) {
			this.df.description = description;
		}
		if (this.only_input || this.df.description===this._description) {
			return;
		}
		
		this.$description = this.$wrapper.find(".field-info .field-description-table");
		if (this.df.description) {
			this.$description.text(`${this.df.description}`).addClass("font-description mt-2 mt-sm-1 mb-0");
		} else {
			this.set_empty_description();
		}

		if(this.$description.hasClass("text-muted") && this.df.bold_description){
			this.$description.removeClass("text-muted");
		}
		this._description = this.df.description;
	}

	set_new_description(description) {
		this.$description.text(`${this.df.description}`);
	}

	get_next_field($table_field){}

	get_values(ignore_errors){
		var errors = [];
		let data  = [];
		this.rows.forEach((row)=>{
			let ret = {};
			for(var key in row.fields_dict) {
				var f = row.fields_dict[key];
				if(f.get_value) {
					var v = f.get_value();
					if(f.df.reqd && is_null(v))
						errors.push(__(f.df.label));
					if(!is_null(v)) ret[f.df.fieldname] = v;
					ret[key] = v;
				}
			}
			data.push(ret);
		});
		

		if(errors.length && !ignore_errors) {
			if(bond_assets.dev.is_developer_mode()){
				console.log(errors);
			}
			return null;
		}
		return data;
	}

	get_fullfill_status(from_tab){
		let values = this.get_values();
		if($.isArray(values) && !values.length && cint(this.df.reqd) === 1){
			return false;
		}else if(is_null(values)){
			return false;
		}
		return true;
	}

}

frappe.ui.form.ControlTableMultiSelectRow = class ControlTableMultiSelectRow{

	constructor(args){
		$.extend(this, args);
		this.make();
	}

	make(){
		this.fields_dict = {};
		this.values = {};
		this.row_id = frappe.dom.get_unique_id();
		this.$wrapper = $(this.parent);
		this.fields.forEach((fieldinfo)=>{
			this.make_control(fieldinfo);
		});
		this.init_handlers();
	}

	make_control(fieldinfo){
		let fieldobj = frappe.ui.form.make_control({
			df: fieldinfo,
			doctype: this.doctype,
			frm: this.frm,
			render_input: true,
			doc: this.doc,
			step: this.step,
			layout: this,
			sub_step: this.sub_step,
			parent: this.parent,
			is_multiselect_table_field: true,
		});
		fieldobj.$wrapper.attr("row-id", this.row_id);
		fieldobj.$wrapper.find("input, select").addClass("table-field");
		this.on_field_render(fieldobj);
		this.fields_dict[fieldinfo.fieldname] = fieldobj;
	}
	
	init_handlers(){
		this.$wrapper.find(`[row-id=${this.row_id}]`).find("input, select").on("change", (e)=>{
			e.preventDefault();
			let fieldname = $(e.target).attr("data-fieldname");
			let fieldobj = this.fields_dict[fieldname];
			if(fieldobj){
				this.handle_table_actions(fieldobj);
			};
		});
	}

	handle_table_actions(fieldobj){
		let value = fieldobj.get_input_value();
		this.values[fieldobj.df.fieldname] = value;
		this.table.handle_table_actions(fieldobj, this);
	}

	delete(){
		for(var fieldname in this.fields_dict){
			this.fields_dict[fieldname].$wrapper.remove();
		}
	}

	get_values(validate=false){
		let values = {};
		for(var fieldname in this.fields_dict){
			values[fieldname] = this.fields_dict[fieldname].get_value();
		}
		return values;
	}

	set_value(fieldname, value){
		if(this.fields_dict[fieldname]){
			this.fields_dict[fieldname].set_value(value);
		}
	}

	on_field_render(fieldobj){
		if(this.frm && this.frm){
			this.frm.trigger_table_field_on_render(this.sub_step.form, fieldobj, this.table)
		}
	}
}
/*
	Override the standard Table Multi Select field format.
*/
$.extend(frappe.form.formatters, {

    TableMultiSelect: function(value) {

	},
});
