/*
    Disclaimer
*/

frappe.provide("bond_assets.form.disclaimer");

bond_assets.form.disclaimer.Disclaimer = class Disclaimer{

    constructor(args){
        $.extend(this, args);
        this.make();
    }

    make(){
        this.only_initiate = true;
        this.handle_disclaimer();
    }

    handle_disclaimer(){
        let message = this.get_message()
        this.popup = frappe.confirm_popup(message, ()=>{
            if(this.primary_action){
                this.primary_action(this);
            }
        }, ()=>{
            if(this.secondary_action){
                this.secondary_action(this);
            }else{
                this.popup.hide();
            }
        }, "",
        "Accept", "Close",{
            only_initiate: true
        });
        this.popup.$wrapper.css("overflow", "auto");
        this.popup.$title.remove();
        this.popup.get_secondary_action().addClass("d-none");
        this.handle_terms_and_policy_urls(this.popup.$wrapper);
        this.popup.show();
    }

    get_message(){
        let msg = this.message;
        try{
            msg = frappe.render_template(msg, {frm: this.frm});
        }catch(e){
            console.log(e);
        }
        msg = `<div class="form-description">
            <p class='text-center'>${msg}</p>
        </div>`;
        return msg;
    }
    handle_terms_and_policy_urls($wrapper){
        if($wrapper){
            $wrapper.find(".redirect-to-privacy-policy").on("click", (e)=>{
                let url = `${frappe.boot.landing_server}/privacy-policy`;
                window.open(url, "_blank");
                return false;
            });

            $wrapper.find(".redirect-to-terms-of-use").on("click", (e)=>{
                let url = `${frappe.boot.landing_server}/terms-of-use`;
                window.open(url, "_blank");
                return false;
            });
        }
    }
}