
frappe.ui.form.ControlInt = class BondControlInt extends frappe.ui.form.ControlData{
	input_type = "number";
	html_element = "input";
	make_input() {
		if(this.$input) return;
		super.make_input();

		this.$wrapper.find(".input-group-prepend").remove();
		this.set_input_icon();
		this.$input.attr("pattern", "\\d*");
		this.set_input_attributes();
		
		this.init_input_mask();
		// somehow this event does not bubble up to document
		// after v7, if you can debug, remove this
	}

	set_input_icon(){
		let $icon_wrapper = super.get_input_icon();
		let is_currency_field = false;
		if(this.df.fieldtype == "Currency"){
			is_currency_field = true;
			$icon_wrapper = `<div class="input-group-prepend">
						<span class="input-group-text">
							<span class="circle-icon">
								<svg id="Dollar" xmlns="http://www.w3.org/2000/svg" width="8.895" height="16.04" viewBox="0 0 8.895 16.04">
									<path id="Контур_92596" data-name="Контур 92596" d="M5.414-1.644A2.251,2.251,0,0,0,6.425-1.96a1.618,1.618,0,0,0,.554-1.4,1.485,1.485,0,0,0-.519-1.2A3.765,3.765,0,0,0,5.414-5.08Zm-.835-9.185a1.542,1.542,0,0,0-1.134.409,1.493,1.493,0,0,0-.369,1.068A1.4,1.4,0,0,0,3.63-8.183a3.139,3.139,0,0,0,.949.439ZM5.432,2.083H4.579V.334A5.994,5.994,0,0,1,2-.422,3.791,3.791,0,0,1,.519-3.867H2.892a4.305,4.305,0,0,0,.343,1.485,1.81,1.81,0,0,0,1.345.765V-5.37l-.712-.211A4.553,4.553,0,0,1,1.5-6.952,3.326,3.326,0,0,1,.809-9.07a3.955,3.955,0,0,1,.268-1.485,3.4,3.4,0,0,1,.751-1.151,3.363,3.363,0,0,1,1.389-.861,6.365,6.365,0,0,1,1.362-.22v-1.169h.835v1.187a4.407,4.407,0,0,1,2.25.7A3.447,3.447,0,0,1,9.2-9.018H6.891a3.144,3.144,0,0,0-.264-1.143,1.37,1.37,0,0,0-1.213-.686V-7.5A11.053,11.053,0,0,1,8.227-6.223a3.105,3.105,0,0,1,1.187,2.6A3.494,3.494,0,0,1,7.787-.4,5.161,5.161,0,0,1,5.432.316Z" transform="translate(-0.518 13.957)" fill="#fff"/>
								</svg>
								<svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
									<g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
										<path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
									</g>
								</svg>
							</span>
						</span>
					</div>`;
		}
		if(is_currency_field){
			$($icon_wrapper).insertBefore(this.$input);
		}else{
			$($icon_wrapper).insertAfter(this.$input);
		}

	}

	validate(v) {
		return v;
	}
	eval_expression(value) {
		if (typeof value === 'string') {
			if (value.match(/^[0-9+\-/* ]+$/)) {
				// If it is a string containing operators
				try {
					return eval(value);
				} catch (e) {
					// bad expression
					return value;
				}
			}
		}
		return value;
	}
	parse(v){
		return cint(this.eval_expression(v), null);
	}
	init_input_mask(){
		this.$input.attr("inputmode", "numeric");
		let allow_partial_input = false;
		if(this.custom_df && this.custom_df.allow_partial_input){
			allow_partial_input = true;
		}
		if(["Currency", "Float"].includes(this.df.fieldtype)){
			Inputmask({
				alias: "numeric",
				radixPoint: ".",
				groupSeparator: ",",
				autoGroup: true,
				digits: 0,
				clearMaskOnLostFocus: false,
				rightAlignNumerics: false,
			}).mask(this.$input.get(0));
		}else if(["Percent"].includes(this.df.fieldtype)){
			Inputmask({ 
				alias: "numeric",
				radixPoint: ".",
				groupSeparator: ",",
				autoGroup: true,
				suffix: " %",
				integerDigits:3,
				digits: 0,
				max:100,
				clearMaskOnLostFocus: false,
				rightAlignNumerics: false,
			}).mask(this.$input.get(0));
		}else if (this.df.length){
			Inputmask({
				mask: `9{${1, this.df.length}}`,
				clearIncomplete: allow_partial_input?false:true,
				reverse: false,
				rightAlignNumerics: false,
				showMaskOnFocus: true,
				showMaskOnHover: false,
			}).mask(this.$input.get(0));
		}else{
			Inputmask("decimal", { 
				radixPoint: ".", 
				autoGroup: true, 
				groupSeparator: ",", 
				groupSize: 3, 
				rightAlignNumerics: false,
				autoUnmask: true
			}).mask(this.$input.get(0));
		}
		this.$input.css("text-align", "left");		
	}
}