/*
    Step Form
*/
import './sub_step.js';

frappe.ui.Step = class Step {
    constructor(args){
        $.extend(this, args);
        this.make();
    }

    make(){

        this.$wrapper = $(`<div class="step h-100" style="display: none;" step-name='${this.df.fieldname}'>
                        </div>`).appendTo(this.parent);
        this.fields = [];
        this.sub_steps_objs = [];
        this.add_progress_handler();
        this.make_sub_steps();
    }

    make_sub_steps(){
        this.sub_steps.forEach((sub_step, j)=>{
            try{
                sub_step = this.init_sub_step(j, sub_step)
                // init step buttons
                sub_step.init_step_handler();
                this.frm.sub_steps_dict[sub_step.df.fieldname] = sub_step;
                this.frm.trigger_refresh(sub_step);
                this.frm.sub_steps.push(sub_step);
                this.sub_steps_objs.push(sub_step);
                this.add_fields_to_step(sub_step);
            }catch(e){
                console.log(e);
            }
        });
        if(this.idx == 1){
            let sub_step = this.sub_steps_objs[0];
            this.toggle_step_active_status(sub_step);
            // below code adjust the margin bottom or place the fields on center for first step
            sub_step.show_step(null, null, true);
        }
        // update handler button in sub step form
    }

    init_sub_step(idx, sub_step){
        let args = $.extend({}, {
            frm: this.frm,
            step: this,
            sub_step: sub_step,
            next_step: null,
            prev_step:null,
            prev_sub_step_obj: null,
            next_sub_step_obj: null,
            fields: sub_step.fields,
            df:sub_step.df,
            parent: this.$wrapper,
            idx: idx+1,
            next_sub_step: null,
            prev_sub_step: null,
        });
        return new frappe.ui.SubStep(args);
    }

    refresh_dependency(){
		this.$wrapper
			&& this.$wrapper.toggleClass("hide-control", this.df.hidden_due_to_dependency === true);
        this.$step
			&& this.$step.toggleClass("hide-control", this.df.hidden_due_to_dependency === true);
    }

    add_fields_to_step(sub_step){
        // merge sub step fields together
        this.fields = this.fields.concat(sub_step.form.fields_list);
    }

    add_progress_handler(){

        if(this.is_path){
            this.$step = $("<div></div>");
            return;
        }
        this.$step = this.get_progress_wrapper();

        $(`<button disabled page-fieldname="${this.df.fieldname}">${this.df.label}</button>`)
                .appendTo(this.$step.find(".progress-title"))

        if(this.idx !== 1){
            this.$step.css("opacity",  "0.5");
            this.$wrapper.hide();
        }
        if(bond_assets.device.is_mobile_device()){
            this.$step.find("button").addClass("d-none");
        }
        this.$step.find("button").on("click", (e)=>{
            e.preventDefault();

            if(this.$step.hasClass("is-active")){
                return false;
            }
            else if(this.$step.hasClass("is-completed")){
                this.move_to_first_substep();
                return false;
            }else if(!this.$step.hasClass("is-completed")){
                return false;
            }

            let next_step = this.frm.steps_dict[this.df.fieldname];
            next_step.toggle_step_active_status(next_step);
            this.move_to_first_substep();
            return false;
        });

    }

    get_progress_wrapper(){
        let cls = "col-6 col-sm-3";
        if(this.idx != 1){
            if(bond_assets.device.is_mobile_device()){
                cls = "col-2";
            }
        }
        return $(`<div class="progress-step-wrapper progress-steps ${cls}" step-name='${this.df.fieldname}'>
                    <div class="progress-title d-flex justify-content-center text-center text-black"></div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>`).appendTo(this.frm.$steps_wrapper.find(".steps-container"));
    }

    move_to_first_substep(){
        let $sub_step_wrapper = this.$wrapper.find(".substep-form").first();
        this.move_to_substep(this.frm.sub_steps_dict[$sub_step_wrapper.attr("substep-name")]);
    }

    move_to_last_substep(){
        let $sub_step_wrapper = this.$wrapper.find(".substep-form").last();
        this.move_to_substep(this.frm.sub_steps_dict[$sub_step_wrapper.attr("substep-name")]);

    }

    move_to_substep(sub_step){
        sub_step.show_step(sub_step);
    }

    toggle_step_active_status(sub_step, is_back_btn=false){
        if(!sub_step){
            return;
        }
        let step = sub_step.step;
        if(step.$wrapper.is(":visible")){
            return;
        }
        sub_step.step.$step.find("button").removeAttr("disabled");
        sub_step.step.$step.css("opacity", "1");
        if(bond_assets.device.is_mobile_device()){
            this.frm.$steps_wrapper.find(".progress-step-wrapper")
                .removeClass("col-6 col-sm-3");
            sub_step.step.$step.addClass("col-6");
            if(is_back_btn){
                $(step.$wrapper).find(".js-form-field.d-none:not(.hide-control)").last().removeClass("d-none");
            }else{
                $(step.$wrapper).find(".js-form-field.d-none:not(.hide-control)").first().removeClass("d-none");
            }
            step.$step.find("button").removeClass("d-none");

        }else{
            $(step.$wrapper).find(".js-form-field.d-none").removeClass("d-none");
        }
        
        if(!step.$step.hasClass("is-completed")
            && !step.$step.hasClass("is-active")){
            step.$step.find(".progress-bar").css("width", "10%");
        }
        this.frm.$steps_wrapper.find(".progress-step-wrapper").removeClass("is-active");

        // add active class to current active step
        step.$step.addClass("is-active active");

        // hide all other and show current step
        step.frm.$parent.find(".step").hide();
        step.$wrapper.find(".substep-form").hide();
        step.$wrapper.find(`.substep-form[substep-name='${sub_step.df.fieldname}']`).show();
        step.add_margin_to_progress_icon();
        step.$wrapper.show();

    }

    toggle_complete_status(sub_step){
        let step = sub_step.step;
        if(step.$step.hasClass("is-completed")){
            return;
        }
        if(bond_assets.device.is_mobile_device()){
            step.add_margin_to_progress_icon();
            step.$step.find("button").addClass("d-none");
            this.frm.$steps_wrapper.find(".progress-step-wrapper")
                    .removeClass("col-6 col-sm-3").addClass("col-2");
        }
        step.$step.toggleClass("is-completed", true);
        step.$step.css("opacity", "1");
        step.$step.removeClass("is-active");
        step.$step.addClass("active");
        step.$step.find("button").removeAttr("disabled");

        step.$step.find(".progress-title").find("img").addClass("mb-2");

        step.$step.find(".progress-bar").css('width', "100%");

    }

    add_margin_to_progress_icon(){
        this.$step.find(".progress-title").find("img").addClass("mb-2");
        this.$step.find(".progress-title").find("svg").addClass("mb-2");
    }
    set_progress_for_next_step(cur_sub_step_obj, next_sub_step_obj){
        return new Promise((resolve, reject)=>{
            let promise;
            if(bond_assets.device.is_mobile_device()){
                promise = this.set_progress_for_next_step_mobile(cur_sub_step_obj, next_sub_step_obj);
            }else{
                promise = this.set_progress_for_next_step_desktop(cur_sub_step_obj, next_sub_step_obj);
            }
            promise.then((args)=>{
                if(cint(args.progress) >= 100){
                    this.toggle_complete_status(cur_sub_step_obj);
                    if(cur_sub_step_obj && bond_assets.device.is_mobile_device()){
                        cur_sub_step_obj.step.$step.find("button").addClass("d-none");
                    }
                    this.toggle_step_active_status(next_sub_step_obj);
                }else{
                    this.$step.find(".progress-bar").css('width', cstr(args.progress)+"%");
                    this.make_sub_step_active(args.sub_step);
                }
                resolve(args.sub_step);
            });
        });
    }
    set_progress_for_next_step_mobile(cur_sub_step_obj, next_sub_step_obj){
        return new Promise((resolve, reject)=>{
            let cur_field = cur_sub_step_obj.get_cur_field();
            // setup progress for mobile phone
            let fields = this.get_visible_step_fields(cur_sub_step_obj);
            let cur_step_fields = cur_sub_step_obj.get_visible_fields();
            let cur_step_field_idx = cur_step_fields.indexOf(cur_field);
            let field_idx = fields.indexOf(cur_field) + 1;
            let args = {
                sub_step: cur_sub_step_obj,
                progress: "10"
            }
            if(cur_step_field_idx == cur_step_fields.length-1){
                args.sub_step = next_sub_step_obj;
                cur_sub_step_obj.$wrapper.find(".js-form-field:not(.actions)").addClass("d-none");
                cur_sub_step_obj.$wrapper.hide();
                next_sub_step_obj.toggle_back_btn(false);
                next_sub_step_obj.$wrapper.find(".js-form-field:not(.actions)").addClass("d-none");
                next_sub_step_obj.$wrapper.show()
                // copy the current field to next substep;
                next_sub_step_obj.cur_field = cur_sub_step_obj.cur_field;
            }
            if(field_idx >= 1){
                let total_fields = fields.length;
                args.progress = field_idx/total_fields*100;
            }
            resolve(args);
        });
    }

    set_progress_for_next_step_desktop(cur_sub_step_obj, next_sub_step_obj){
        return new Promise((resolve, reject)=>{
            let args = {
                sub_step: next_sub_step_obj,
                progress: "10"
            };
            let sub_step_idx = this.sub_steps_objs.indexOf(cur_sub_step_obj)+1;
            let total_sub_steps = this.sub_steps_objs.length;
            if(sub_step_idx >= 1){
                args.progress = sub_step_idx/total_sub_steps*100;
            }
            resolve(args);
        });
    }

    set_progress_for_prev_step(cur_sub_step_obj, prev_sub_step_obj){
        return new Promise((resolve, reject)=>{
            let promise;
            if(bond_assets.device.is_mobile_device()){
                promise = this.set_progress_for_prev_step_mobile(cur_sub_step_obj, prev_sub_step_obj);
            }else{
                promise = this.set_progress_for_prev_step_desktop(cur_sub_step_obj, prev_sub_step_obj);
            }

            promise.then((args)=>{
                cur_sub_step_obj.step.$step.removeClass("is-completed");
                if(bond_assets.device.is_mobile_device() && args.is_last_prev_step && args.prev_step){
                    args.prev_step.step.$step.find("button").addClass("d-none");
                }
                if(cint(args.progress) == 10){
                    this.toggle_step_active_status(prev_sub_step_obj, true);
                }else{
                    this.$step.find(".progress-bar").css('width', cstr(args.progress)+"%");
                    this.make_sub_step_active(args.sub_step);
                }
                resolve(args.sub_step);
            });
        });
    }
    set_progress_for_prev_step_mobile(cur_sub_step_obj, prev_sub_step_obj){
        return new Promise((resolve, reject)=>{ 
            let cur_field = cur_sub_step_obj.get_cur_field(true);
            // setup progress for mobile phone
            let step_fields = this.get_visible_step_fields(cur_sub_step_obj);
            let sub_step_fields = cur_sub_step_obj.get_visible_fields();
            let cur_step_field_idx = sub_step_fields.indexOf(cur_field);
            let step_field_idx = step_fields.indexOf(cur_field) - 1;
            let args = {
                sub_step: cur_sub_step_obj,
                progress: "10",
                is_last_prev_step: false,
            }
            if(cur_step_field_idx === -1 && prev_sub_step_obj){
                args.sub_step = prev_sub_step_obj;
                args.is_last_prev_step = true;
                args.prev_step = cur_sub_step_obj;
                cur_sub_step_obj.$wrapper.hide();
                prev_sub_step_obj.$wrapper.show()
                // copy the current field to next substep;
                prev_sub_step_obj.cur_field = cur_sub_step_obj.cur_field;
            }
            if(step_field_idx >= 0){
                if(step_field_idx == 0){
                    step_field_idx = 1;
                }
                let total_fields = step_fields.length;
                args.progress = step_field_idx/total_fields*100;
            }
            resolve(args);
        });
    }
    set_progress_for_prev_step_desktop(cur_sub_step_obj, prev_sub_step_obj){
        return new Promise((resolve, reject)=>{
            let args = {
                sub_step: prev_sub_step_obj,
                progress: "10"
            };
            let sub_step_idx = this.sub_steps_objs.indexOf(prev_sub_step_obj) + 1;
            let total_sub_steps = this.sub_steps_objs.length;
            if(sub_step_idx >= 1){
                args.progress = sub_step_idx/total_sub_steps*100;
            }
            resolve(args);
        });
    }

    make_sub_step_active(sub_step){
        if(sub_step.$wrapper.is(":visible")){
            // control the flow for mobile phone
            return;
        }
        let step = sub_step.step;
        step.$wrapper.find(".substep-form").hide();
        step.$wrapper.find(`.substep-form[substep-name='${sub_step.df.fieldname}']`).show();
    }
    get_visible_step_fields(sub_step){
        return sub_step.step.fields.filter((field_obj)=>{
            return !field_obj.$wrapper.hasClass("hide-control");
        });
    }
}
