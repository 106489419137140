
frappe.ui.form.ControlDatetime = class BondControlDatetime extends frappe.ui.form.ControlDate{
	set_date_options() {
		super.set_date_options();
		this.today_text = __("Now");
		this.date_format = frappe.defaultDatetimeFormat;
		$.extend(this.datepicker_options, {
			timepicker: true,
			timeFormat: "hh:ii:ss"
		});
	}
	get_now_date() {
		return frappe.datetime.now_datetime(true);
	}
	set_description() {
		const { description } = this.df;
		const { time_zone } = frappe.sys_defaults;
		if (!frappe.datetime.is_timezone_same()) {
			if (!description) {
				this.df.description = time_zone;
			} else if (!description.includes(time_zone)) {
				this.df.description += '<br>' + time_zone;
			}
		}
		super.set_description();
	}
}
