

frappe.ui.form.ControlDate = class BondControlDate extends frappe.ui.form.ControlData{

	static input_type = "text";
	make_input() {
		if(this.$input){
			return
		}
		this.placeholder = this.df.placeholder || "mm/dd/yyyy";
		this.moment_input_format = "MM/DD/YYYY";
		this.moment_system_format= "YYYY-MM-DD";
		super.make_input();
		this.only_input = true;
		this.set_date_options();
		this.setup_masking();
	}

	setup_masking(){
		this.min_date = null;
		this.max_date = null;
		if(!is_null(this.df.min_date) && !is_null(this.df.max_date)){
			this.min_date = frappe.datetime.add_months(frappe.datetime.nowdate(), this.df.min_date);
			this.max_date = frappe.datetime.add_months(frappe.datetime.nowdate(), this.df.max_date);
		}else if(!is_null(this.df.min_date)){
			this.min_date = frappe.datetime.add_months(frappe.datetime.nowdate(), this.df.min_date);
		}else if(!is_null(this.df.max_date)){
			this.max_date = frappe.datetime.add_months(frappe.datetime.nowdate(), this.df.max_date);
		}
		this.min_date = this.convert_to_input_format(this.min_date);
		this.max_date = this.convert_to_input_format(this.max_date);

		let options = {
			inputFormat: "mm/dd/yyyy",
			outputFormat: "yyyy-mm-dd",
			placeholder: "mm-dd-yyyy",
			alias: "datetime",
			clearIncomplete: true,
		};
		Inputmask(options).mask(this.$input.get(0));
	}

	convert_to_input_format(value){
		if(value){
			value = moment(value, this.moment_system_format).format(this.moment_input_format);
		}
		return value;
	}

	convert_to_system_format(value){
		if(value){
			value = moment(value, this.moment_input_format).format(this.moment_system_format);
		}
		return value;
	}

	set_date_options() {
		// webformTODO:
		let sysdefaults = frappe.boot.sysdefaults;

		let lang = 'en';

		let date_format = sysdefaults && sysdefaults.date_format
			? sysdefaults.date_format : 'yyyy-mm-dd';

		let now_date = new Date();

		this._date_format = date_format;
		
	}

	get_now_date() {
		return frappe.datetime.now_date(true);
	}
	parse(value) {
		return this.convert_to_system_format(value);
	}
	format_for_input(value) {
		return this.convert_to_input_format(value);

	}
	validate(value) {
		let error = undefined;
		if(value){
			let temp = moment(value, this.moment_system_format);
			if(this.min_date && this.max_date){
				if(!temp.isAfter(this.min_date)
					|| !temp.isBefore(this.max_date)){
					error = __("Date must be between {0} and {1}", [this.min_date, this.max_date]);
				}
			}else if(this.min_date){
				if(temp.isBefore(this.min_date)){
					error = __("Date must be greater than {0}", [this.min_date]);
				}

			}else if(this.max_date){
				if(temp.isAfter(this.max_date)){
					error = __("Date must be less than {0}", [this.max_date]);
				}
			}
		}
		if(error !== undefined){
			if(this.custom_df && this.custom_df.error_message){
				error = this.custom_df.error_message;
			}
			this.show_error_msg(error);
			return '';
		}


		if(this.df.options == 'DOB' && !is_null(value)){
			let year = cint(value.split("-")[0]);
			if(cstr(year).length == 4  && year < 1900){
				this.show_error_msg(__("Invalid date"));
				return '';
			}
		}
		if(value && !frappe.datetime.validate(value)) {
			let sysdefaults = frappe.sys_defaults;
			let date_format = sysdefaults && sysdefaults.date_format
				? sysdefaults.date_format : 'yyyy-mm-dd';
			this.show_error_msg(__("Date {0} must be in format: {1}", [value, date_format]));
			return '';
		}else if(value){
			this.hide_error_msg();
		}
		return value;
	}
}
