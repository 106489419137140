frappe.ui.form.custom_icons = {

    "Miles": `<div class="input-group-prepend">
        <span class="input-group-text">
            <span class="circle-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="19"
                    height="9" viewBox="0 0 19 9">
                    <g id="Miles_icon" data-name="Miles icon"
                        transform="translate(940.554 502) rotate(180)">
                        <g id="Эллипс_24" data-name="Эллипс 24"
                            transform="translate(921.554 493)"
                            fill="none" stroke="#feffff"
                            stroke-width="2">
                            <circle cx="4.5" cy="4.5" r="4.5"
                                stroke="none" />
                            <circle cx="4.5" cy="4.5" r="3.5"
                                fill="none" />
                        </g>
                        <g id="Эллипс_25" data-name="Эллипс 25"
                            transform="translate(934.554 494.5)"
                            fill="#fff" stroke="#feffff"
                            stroke-width="2">
                            <circle cx="3" cy="3" r="3"
                                stroke="none" />
                            <circle cx="3" cy="3" r="2"
                                fill="none" />
                        </g>
                        <path id="Контур_92649"
                            data-name="Контур 92649"
                            d="M6733.356,21274h6.252"
                            transform="translate(-5803.689 -20776.5)"
                            fill="none" stroke="#feffff"
                            stroke-width="2" />
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="15.897" height="13.689"
                    viewBox="0 0 15.897 13.689">
                    <g id="icon_done" data-name="icon done"
                        transform="translate(1.062 1.087)">
                        <path id="Контур_92528"
                            data-name="Контур 92528"
                            d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z"
                            transform="translate(0 0.057)"
                            fill="#feffff" stroke="#feffff"
                            stroke-width="1.5" />
                    </g>
                </svg>
            </span>
        </span>
    </div>`,
    "Square Footage": `<div class="input-group-prepend">
        <span class="input-group-text">
            <span class="circle-icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.568" height="16.568" viewBox="0 0 16.568 16.568">
                    <defs>
                    <clipPath id="clip-path">
                        <rect id="Прямоугольник_5242" data-name="Прямоугольник 5242" width="16.575" height="6.855" fill="none"/>
                    </clipPath>
                    </defs>
                    <g id="Lineup_icon" data-name="Lineup icon" transform="translate(0 11.721) rotate(-45)">
                    <g id="Сгруппировать_37073" data-name="Сгруппировать 37073" transform="translate(0 0)" clip-path="url(#clip-path)">
                        <path id="Контур_92724" data-name="Контур 92724" d="M16.575,5.344V1.512A1.512,1.512,0,0,0,15.066,0H1.5A1.514,1.514,0,0,0,0,1.5L0,5.355a1.513,1.513,0,0,0,1.5,1.5H6.653V4.4a.518.518,0,0,1,.506-.53h.025a.538.538,0,0,1,.53.53V6.855h1.94V4.4a.531.531,0,0,1,1.061,0V6.855h1.94V4.4a.531.531,0,0,1,1.061,0V6.855h1.349a1.511,1.511,0,0,0,1.511-1.511M3.635,5.135a1.7,1.7,0,0,1-1.7-1.7,1.745,1.745,0,0,1,1.7-1.711v0a1.7,1.7,0,1,1,0,3.41" transform="translate(0 0)" fill="#feffff"/>
                    </g>
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="15.897" height="13.689"
                    viewBox="0 0 15.897 13.689">
                    <g id="icon_done" data-name="icon done"
                        transform="translate(1.062 1.087)">
                        <path id="Контур_92528"
                            data-name="Контур 92528"
                            d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z"
                            transform="translate(0 0.057)"
                            fill="#feffff" stroke="#feffff"
                            stroke-width="1.5" />
                    </g>
                </svg>
            </span>
        </span>
    </div>`,

    "Data": `<div class="input-group-prepend">
        <span class="input-group-text">
            <span class="circle-icon">
                <svg id="PENCIL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.352" height="13.394" viewBox="0 0 12.352 13.394">
                    <defs>
                        <clipPath id="clip-path">
                        <rect id="Прямоугольник_5177" data-name="Прямоугольник 5177" width="12.352" height="13.394" fill="#fff"/>
                        </clipPath>
                    </defs>
                    <g id="Сгруппировать_36833" data-name="Сгруппировать 36833" clip-path="url(#clip-path)">
                        <path id="Контур_92588" data-name="Контур 92588" d="M11.415.733A3.268,3.268,0,0,1,11.609,5.1L7.051,1.024A3.175,3.175,0,0,1,11.415.733ZM10.83,5.907,5.437,11.746,1.071,13.327A.761.761,0,0,1,.037,12.4L.879,7.673,6.272,1.834Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd"/>
                    </g>
                </svg>  
                <svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
                    <g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
                        <path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
                    </g>
                </svg>                                                                    
            </span>
        </span>
    </div>`,

    "Check": `<span class="circle-icon">
        <svg id="Star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.256" height="13.183" viewBox="0 0 13.256 13.183">
            <defs>
                <clipPath id="clip-path">
                <rect id="Прямоугольник_5179" data-name="Прямоугольник 5179" width="13.256" height="13.183" transform="translate(0 0)" fill="none"/>
                </clipPath>
            </defs>
            <g id="Сгруппировать_36839" data-name="Сгруппировать 36839" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Контур_92589" data-name="Контур 92589" d="M6.154.341a.5.5,0,0,1,.948,0l1.29,3.837a.5.5,0,0,0,.461.341l3.917.105a.5.5,0,0,1,.3.889L9.919,8.052a.5.5,0,0,0-.167.525l1.121,3.97a.5.5,0,0,1-.774.541l-3.177-2.3a.5.5,0,0,0-.586,0l-3.177,2.3a.5.5,0,0,1-.774-.541l1.121-3.97a.5.5,0,0,0-.167-.525L.186,5.513a.5.5,0,0,1,.3-.889L4.4,4.519a.5.5,0,0,0,.461-.341Z" fill="#fff"/>
            </g>
        </svg>
    </span>`,

    "Pencil": `<div class="control-btn-icon">
        <svg id="PENCIL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.352" height="13.394" viewBox="0 0 12.352 13.394">
            <defs>
                <clipPath id="clip-path">
                <rect id="Прямоугольник_5177" data-name="Прямоугольник 5177" width="12.352" height="13.394" fill="#161616"/>
                </clipPath>
            </defs>
            <g id="Сгруппировать_36833" data-name="Сгруппировать 36833" clip-path="url(#clip-path)">
                <path id="Контур_92588" data-name="Контур 92588" d="M11.415.733A3.268,3.268,0,0,1,11.609,5.1L7.051,1.024A3.175,3.175,0,0,1,11.415.733ZM10.83,5.907,5.437,11.746,1.071,13.327A.761.761,0,0,1,.037,12.4L.879,7.673,6.272,1.834Z" transform="translate(0 0)" fill="#161616" fill-rule="evenodd"/>
            </g>
        </svg>                                                                 
    </div>`,

    'Cross': `<div class="control-btn-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.213" height="12.214" viewBox="0 0 12.213 12.214">
            <g id="remove_icon" data-name="remove icon" transform="translate(-167.564 -1297.614) rotate(45)">
                <path id="Контур_71762" data-name="Контур 71762" d="M1038.51,803.435V792.163" transform="translate(6.164 1.268)" fill="none" stroke="#161616" stroke-linecap="square" stroke-width="3"/>
                <path id="Контур_71763" data-name="Контур 71763" d="M0,11.272V0" transform="translate(1050.31 799.066) rotate(90)" fill="none" stroke="#161616" stroke-linecap="square" stroke-width="3"/>
            </g>
        </svg>                                                               
    </div>`,

    'Plus': `<div class="circle-btn-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.51" height="12.51" viewBox="0 0 12.51 12.51">
            <g id="Add_Guest_icon" data-name="Add Guest icon" transform="translate(-1037.538 -791.931)">
                <path id="Контур_71762" data-name="Контур 71762" d="M1038.51,801.673v-9.51" transform="translate(5.283 1.268)" fill="none" stroke="#feffff" stroke-linecap="square" stroke-width="3"/>
                <path id="Контур_71763" data-name="Контур 71763" d="M0,9.51V0" transform="translate(1048.548 798.185) rotate(90)" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="3"/>
            </g>
        </svg>
    </div>`,

    'Close': `<svg xmlns="http://www.w3.org/2000/svg" width="12.245" height="11.717" viewBox="0 0 12.245 11.717">
        <g id="close_popup_icon" data-name="close popup icon" transform="translate(2.228 2.228)">
            <g id="close" transform="translate(7.79) rotate(90)">
            <path id="Контур_41696" data-name="Контур 41696" d="M0,0,5.591,6.045,6.882,7.44" transform="translate(0 0.36) rotate(-3)" fill="none" stroke="#161617" stroke-linecap="square" stroke-width="3"/>
            <path id="Контур_41697" data-name="Контур 41697" d="M0,0,5.946,5.489,7.318,6.755" transform="matrix(-0.035, 0.999, -0.999, -0.035, 7.134, 0.356)" fill="none" stroke="#161617" stroke-linecap="square" stroke-width="3"/>
            </g>
        </g>
    </svg> `,

    "Back Button": `<svg xmlns="http://www.w3.org/2000/svg" width="15.541" height="11.039" viewBox="0 0 15.541 11.039">
        <g id="arrow" transform="translate(2074.035 1089.548) rotate(180)">
            <g id="Сгруппировать_11542" data-name="Сгруппировать 11542" transform="translate(2059.344 1079.711)">
                <line id="Линия_209" data-name="Линия 209" x2="13.558" transform="translate(0 4.398)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                <path id="Контур_27836" data-name="Контур 27836" d="M2072.273,1083.088l4.317,4.317-4.317,4.318" transform="translate(-2062.749 -1083.088)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
            </g>
        </g>
    </svg>`
}