
frappe.ui.form.ControlPictureCheck = class BondControlPictureCheck extends frappe.ui.form.ControlCheck{
	input_type =  "checkbox";
	make_input() {
		//this._super();

		if(this.$input){
			return
		}
		
		let $wrapper = this.$wrapper.find(".input-main-wrapper");

		$wrapper.attr("data-toggle", "buttons");

		let $input = null;
		if(this.df.is_radio){
			$input = this.get_radio_wrapper(this.df);
		}else{
			$input = this.get_checkbox_wrapper(this.df);
		}

		this.$input = $($input).appendTo($wrapper);

		let option_length = cstr(this.df.options).split("\n").length;
		if(this.df.is_radio){
			if(option_length <= 2){
				$wrapper.addClass("justify-content-center");
				$wrapper.find(".checkbox-wrapper").addClass("col-sm-5");
				$wrapper.find(".checkbox-wrapper").removeClass("mb-3");
			}
			else if(option_length >= 3){
				if(!bond_assets.device.is_mobile_device()){
					$wrapper.find(".checkbox-wrapper:nth-last-child(-n+3)")
					.removeClass("mb-3");
				}
			}
		}else{
			//this.$wrapper.find(".checkbox-wrapper").addClass("mb-3 mb-sm-4")
		}

		this.set_input_attributes();
		this.input = this.$input;
		this.has_input = true;
		this.bind_change_event();
		this.$input.removeClass("form-control");

	}
	get_checkbox_wrapper(df){
		return `<div class="col-6 col-md-4 btn-group btn-group-toggle mb-3 px-2 checkbox-wrapper">
					<label class="btn tab-link rounded-lg text-center pt-3 pt-xxl-4 px-2"
						data-fieldname="${df.fieldname}" data-value"${df.placeholder}">
						<input type="checkbox" class="input-area" name="${df.fieldname}"  data-fieldname='${df.fieldname}'/>
						${this.get_star_icon()}
						<img src="/assets/bond_assets/images/icons/mobile-home.svg" alt="${df.placeholder}"
							class="img-fluid mt-3 mb-2">
						<img src="/assets/bond_assets/images/icons/mobile-home-active.svg" alt="${df.placeholder}"
							class="img-fluid mt-3 mb-2">
						<span class="field-description font-form-description mt-2 mt-md-0 mb-0 d-block">${df.placeholder}</span>
					</label>
				</div>`;
	}

	get_radio_wrapper(df){
		let options = cstr(df.options).split("\n");
	
		let temp = []
		let img_info = {};

		$.each(options, (idx, opt)=>{
			img_info = this.get_img_info(opt);
			temp.push(`<div class="col-6 col-md-4 btn-group btn-group-toggle mb-3 px-2 checkbox-wrapper">
						<label class="btn tab-link rounded-lg text-center pt-3 pt-xxl-4 px-2" data-value="${opt}"
							data-fieldname="${df.fieldname}">
							<input type="radio" class="input-area" name="${df.fieldname}" value="${opt}" data-fieldname='${df.fieldname}'/>
							${this.get_star_icon()}
							<img src="${img_info.active_img}" alt="${opt}"
								class="img-fluid active-img">
							<span class="tab-link-name text-center mb-0 d-block">${opt}</span>
						</label>
					</div>`);
		});
		return temp.join("\n");
	}

	get_img_info(value){
		let img_info = {};
		if(this.custom_df && this.custom_df.images && this.custom_df.images[value]){
			img_info = this.custom_df.images[value];
		}
		if(!img_info.label_classes){
			if(bond_assets.device.is_mobile_device()){
				img_info.label_classes = "px-2";
			}else{
				img_info.label_classes = "pt-3 pt-xxl-4 px-2";
			}
		}
		if(!img_info.inactive_classes){
			if(bond_assets.device.is_mobile_device()){
				img_info.inactive_classes = "mt-3 mb-1 px-1"
			}else{
				img_info.inactive_classes = "mt-3 mb-2";
			}
		}
		if(!img_info.active_classes){
			if(bond_assets.device.is_mobile_device()){
				img_info.inactive_classes = "mt-3 mb-1 px-1"
			}else{
				img_info.active_classes = "mt-3 mb-2";
			}
		}
		if(!img_info.text_classes){
			img_info.text_classes = "mb-0";
		}
		if(bond_assets.device.is_mobile_device() && img_info.mobile_text_classes){
			img_info.text_classes = img_info.mobile_text_classes;
		}

		return img_info;
	}
	get_input_value() {

		let value = null;
		if(this.df.is_radio){
			value =  this.$wrapper.find(`input[name='${this.df.fieldname}']:checked`).val();

		}else{
			value = this.$wrapper.find(`input[data-fieldname='${this.df.fieldname}']`)
													.is(":checked")?1:0;
		}
		return value;

	}
	validate(value) {
		return value;
	}
	set_input(value) {

		let $input = this.$wrapper.find(`input[data-fieldname='${this.df.fieldname}']`);

		if($input.attr("type") == "radio"){
			if(is_null(value)){
				$input.prop("checked", false);
				$input.parent().removeClass('focus active');
			}else{
				$input.find(`[value='${value}']`).prop("checked", true);
				$input.find(`[value='${value}']`).parent().addClass('focus active');
			}

		}else if($input.attr("type") === "checkbox"){
			
			$input.prop("checked", value===1?true:false);
			if(!$input.is(":checked")){
				$input.parent().removeClass('focus active');
			}else{
				$input.parent().addClass("focus active");
			}
		}

		this.last_value = value;
		this.set_mandatory(value);
		this.set_disp_area(value);
	}

	bind_change_event() {
		var me = this;
		me.values = {};

		this.$input.find("input").on("change", function(e) {
			let value = undefined;

			if($(this).attr("type") === "checkbox"){
				value = $(this).is(":checked")?1:0;
			}else{
				value = $(this).val();
			}
			me.values[$(this).attr("data-fieldname")] = value;
			me.parse_validate_and_set_in_model(value, e);
			me.set_icons(value);
		});

	}
	set_icons(value){
		this.$wrapper.find(`label[data-fieldname='${this.df.fieldname}']`)
				.removeClass("active");
		
		let $label = this.$wrapper.find(`label[data-fieldname='${this.df.fieldname}'][data-value='${value}']`)
						.addClass("active");

		// find the checked img;
		let $img_wrapper = $label.find(`img[data-value='${value}']`);
		if(value){
			//$img_wrapper.find(".inactive-img").addClass("d-none");
			//$img_wrapper.find(".active-img").removeClass("d-none");
		}else{
			//$img_wrapper.find(".inactive-img").removeClass("d-none");
			//$img_wrapper.find(".active-img").addClass("d-none");
		}
	}

	sync_with_field_bottom(){
		this._super();
		this.$wrapper.find(".checkbox-wrapper")
			.removeClass("mb-3 mb-sm-4");
	}

	clear_checked_items(){
		this.$wrapper.find("input").prop("checked", false);
	}

	set_field_placeholder(placeholder){
		this.$wrapper.find(`label[data-fieldname='${this.df.fieldname}']`)
			.find(".label-placeholder").text(placeholder);
	}

	get_star_icon(){
		return  `<span class="circle-icon">
			<svg id="Star" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				width="13.256" height="13.183"
				viewBox="0 0 13.256 13.183">
				<defs>
					<clipPath id="clip-path">
						<rect id="Прямоугольник_5179"
							data-name="Прямоугольник 5179"
							width="13.256" height="13.183"
							transform="translate(0 0)" fill="none" />
					</clipPath>
				</defs>
				<g id="Сгруппировать_36839"
					data-name="Сгруппировать 36839"
					transform="translate(0 0)"
					clip-path="url(#clip-path)">
					<path id="Контур_92589" data-name="Контур 92589"
						d="M6.154.341a.5.5,0,0,1,.948,0l1.29,3.837a.5.5,0,0,0,.461.341l3.917.105a.5.5,0,0,1,.3.889L9.919,8.052a.5.5,0,0,0-.167.525l1.121,3.97a.5.5,0,0,1-.774.541l-3.177-2.3a.5.5,0,0,0-.586,0l-3.177,2.3a.5.5,0,0,1-.774-.541l1.121-3.97a.5.5,0,0,0-.167-.525L.186,5.513a.5.5,0,0,1,.3-.889L4.4,4.519a.5.5,0,0,0,.461-.341Z"
						fill="#fff" />
				</g>
			</svg>
		</span>`;
	}
}
