frappe.provide("geo_data.address");
frappe.provide("geo_data.smarty_street");

import SmartyStreetsSDK from "../../libs/smarty_street/smartystreets-sdk-1.6.2.min";

frappe.ui.form.ControlAddress = class BondControlAddress extends frappe.ui.form.ControlInput{
	html_element = "input";
	input_type = "text";

	make_input(){
		if(this.$input){
			return
		}

		let me = this;
		let is_mobile_device = bond_assets.device.is_mobile_device();
		let $wrapper = $(`<div class="form-row">
			<div class="col-12">
				<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark w-100">
					<input type="text" class="awesomplete js-text-form form-control border-0"
								address-name="address_line1" placeholder="${__('Street Address')}" name="notASearchField"
								data-fieldname='${this.df.fieldname}'>
					${this.get_input_icon()}
					<div class="w-100 invalid-tooltip small py-3 d-none" address-name="address_line1">
						<div class="d-flex align-items-center justify-content-center">
							<div class="mr-2 pb-1">
								<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
							</div>
							<div class="text-left error-message"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark w-100">
					<input type="text" class="js-text-form js-text-not-empty-tab form-control border-0" name="notASearchField"
								address-name="address_line2" placeholder="${__('Apartment, Suite, Building, Etc.')}"
								data-fieldname='${this.df.fieldname}'>
					${this.get_input_icon()}
					<div class="w-100 invalid-tooltip small py-3 d-none" address-name="address_line2">
						<div class="d-flex align-items-center justify-content-center">
							<div class="mr-2 pb-1">
								<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
							</div>
							<div class="text-left error-message"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6 mb-sm-0">
				<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark w-100">
					<input type="text" class="js-text-form js-text-not-empty-tab form-control border-0" 
						address-name="city" placeholder="${__('City')}" name="notASearchField"
						data-fieldname='${this.df.fieldname}'>
					${this.get_input_icon()}
					<div class="w-100 invalid-tooltip small py-3 d-none" address-name="city">
						<div class="d-flex align-items-center justify-content-center">
							<div class="mr-2 pb-1">
								<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
							</div>
							<div class="text-left error-message"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6 mb-0">
				<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark w-100">
					<input type="text" class="js-text-form js-text-not-empty-tab form-control border-0"
						address-name="state" placeholder="${__('State')}" name="notASearchField"
						data-fieldname='${this.df.fieldname}'>
					${this.get_input_icon()}
					<div class="w-100 invalid-tooltip small py-3 d-none" address-name="state">
						<div class="d-flex align-items-center justify-content-center">
							<div class="mr-2 pb-1">
								<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
							</div>
							<div class="text-left error-message"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6 mb-0">
				<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark w-100">
					<input type="text" class="js-text-form js-text-not-empty-tab form-control border-0" 
						inputmode="numeric" address-name="zip_code" placeholder="${__('Zip')}" name="notASearchField"
						data-fieldname='${this.df.fieldname}'>
					${this.get_input_icon()}
					<div class="w-100 invalid-tooltip small py-3 d-none" address-name="zip_code">
						<div class="d-flex align-items-center justify-content-center">
							<div class="mr-2 pb-1">
								<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
							</div>
							<div class="text-left error-message"></div>
						</div>
					</div>
				</div>
			</div>
		</div>`).appendTo(this.$wrapper.find(".input-main-wrapper"));

		this.$address_line1 = $wrapper.find("input[address-name='address_line1']")

		this.$address_line1.on("focus", function() {
			setTimeout(function() {

				if(!me.$address_line1.val()) {
					me.$address_line1.val("").trigger("input");
				}
			}, 500);
		});

		//this.$wrapper.addClass("mt-4");
		this.$input = this.$wrapper.find("input");
		this.has_input = true;
		this.input = this.$address_line1.get(0);
		this.bind_change_event();
		this.setup_awesomeplete();
		this.set_input_attributes();
	}

	set_input_attributes() {
		
		this.$input
			.attr("data-fieldtype", this.df.fieldtype)
			.attr("data-fieldname", this.df.fieldname);
		// placeholder is no longer required here
		/*
		if(window.navigator && window.navigator.vendor.search("Google Inc.") >= 0){
			this.$input.attr("autocomplete", "chrome-off");
		}else{
			this.$input.attr("autocomplete", "off");
		}
		*/

		if(this.doctype) {
			this.$input.attr("data-doctype", this.doctype);
		}
		if(this.df.input_css) {
			this.$input.css(this.df.input_css);
		}
		if(this.df.input_class) {
			this.$input.addClass(this.df.input_class);
		}
	}

	get_input_icon(){
		return `<div class="input-group-prepend">
					<span class="input-group-text">
						<span class="circle-icon">
							<svg id="PENCIL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.352" height="13.394" viewBox="0 0 12.352 13.394">
								<defs>
								<clipPath id="clip-path">
									<rect id="Прямоугольник_5177" data-name="Прямоугольник 5177" width="12.352" height="13.394" fill="#fff"/>
								</clipPath>
								</defs>
								<g id="Сгруппировать_36833" data-name="Сгруппировать 36833" clip-path="url(#clip-path)">
								<path id="Контур_92588" data-name="Контур 92588" d="M11.415.733A3.268,3.268,0,0,1,11.609,5.1L7.051,1.024A3.175,3.175,0,0,1,11.415.733ZM10.83,5.907,5.437,11.746,1.071,13.327A.761.761,0,0,1,.037,12.4L.879,7.673,6.272,1.834Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd"/>
								</g>
							</svg>  
							<svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
								<g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
								<path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
								</g>
							</svg>                                                                    
						</span>
					</span>
				</div>`;
	}
	set_input(value, fieldname) {
		return new Promise((resolve, reject)=>{
			if($.isPlainObject(value)){
				$.each(value, (key, val)=>{
					this.highlight_field(key, val)
					this.$wrapper.find(`input[address-name='${key}']`).val(val);
					this.highlight_field(key, val);
				});
			}else if(value && fieldname){
				this.$wrapper.find(`input[address-name='${fieldname}']`).val(value);
				this.highlight_field(fieldname, value);
			}else{
				this.$wrapper.find(`input[data-fieldname='address_line1']`).val(value);
				this.highlight_field("address_line1", value);
			}
			this.last_value = this.value;
			this.value = value;
			resolve();
		});
	}

	highlight_field (fieldname, value){
		if(value){
			if(fieldname == "address_line1"){
				this.$wrapper.find(`input[address-name='${fieldname}']`).val(value)
						.parent().parent()
						.addClass("not-empty");
			}else{
				this.$wrapper.find(`input[address-name='${fieldname}']`).val(value)
						.parent()
						.addClass("not-empty");
			}
		}else{
			if(fieldname == "address_line1"){
				this.$wrapper.find(`input[address-name='${fieldname}']`).val(value)
						.parent().parent()
						.removeClass("not-empty");
			}else{
				this.$wrapper.find(`input[address-name='${fieldname}']`).val(value)
						.parent()
						.removeClass("not-empty");
			}
		}
	}
	set_formatted_input (value, fieldname) {
		if(this.$input && !is_null(fieldname)){
			this.$input.find(`[data-fieldname="${fieldname}"]`).val(value);
		}
	}

	get_input_value(ignore_errors) {
		if(is_null(this.$wrapper.find("input[address-name='address_line1']").val())
			|| is_null(this.$wrapper.find("input[address-name='city']").val())
			|| is_null(this.$wrapper.find("input[address-name='state']").val())
			|| is_null(this.$wrapper.find("input[address-name='zip_code']").val())){
			return null;

		}else{
			return this.values;
		}
	}

	get_fullfill_status (){
		let flag = this.get_input_value();
		return flag?true:false;
	}

	bind_change_event(){

		var me = this;
		this.values = {};

		this.$input.on("change", this.change || function(e){
			let fieldname = $(this).attr("address-name");
			let val = $(this).val();
			if(fieldname == "zip_code" && !is_null(val)){
				if(!me.is_valid_zip_code(val)){
					val = "";
					$(this).val(val);
				}
			}
			me.values[fieldname] = val;
			me.parse_validate_and_set_in_model(me.values, fieldname, e);
		});
	}

	format_for_input(val) {
		return val==undefined? {} : val;
	}

	validate(v) {
		return v;

	}
	setup_awesomeplete() {
		var me = this;

		this.$address_line1.cache = {};
		this.awesomplete = new Awesomplete(me.$address_line1.get(0), {
			minChars: 0,
			maxItems: 99,
			list: [],
			data: function (item) {
				return {
					label: item.label || item.value,
					value: item.value
				};
			},
			filter: function() {
				return true;
			},
			item: function (item) {
				var d = this.get_item(item.value);
				if(!d.label) {	d.label = d.value;}
				var _label = (me.translate_values) ? __(d.label) : d.label;
				var html = d.html ||  _label ;
				if(d.secondary) {
					html += '<br><span class="small">' + __(d.secondary) + '</span>';
				}
				return $(`<li></li>`)
					.data('item.autocomplete', d)
					.attr("role", "option")
					.attr("id", `awesomplete_list_1_item_${d.idx}`)
					.html(`${html}`)
					.get(0);
			},
			sort: function() {
				return 0;
			}
		});

		this.init_client(false);

		this.$address_line1.on("blur", function() {
			if(me.selected) {
				me.selected = false;
				return;
			}
			var value = me.get_input_value();
			if(value && value!==me.last_value) {
				me.parse_validate_and_set_in_model(value);
			}
		});

		this.$address_line1.on("awesomplete-open", function() {
			me.autocomplete_open = true;
		});

		this.$address_line1.on("awesomplete-close", function() {
			me.autocomplete_open = false;
		});

		this.$address_line1.on("awesomplete-select", function(e) {
			var o = e.originalEvent;
			var item = me.awesomplete.get_item(o.text.value);

			me.autocomplete_open = false;

			// prevent selection on tab
			var TABKEY = 9;
			if(e.keyCode === TABKEY) {
				e.preventDefault();
				me.awesomplete.close();
				return false;
			}

			if(item && item.action) {
				item.value = "";
				item.action.apply(me);
			}

			// if remember_last_selected is checked in the doctype against the field,
			// then add this value
			// to defaults so you do not need to set it again
			// unless it is changed.
			if(me.df.remember_last_selected_value) {
				frappe.boot.user.last_selected_values[me.df.options] = item.value;
			}


			me.values = {
				'address_line1': item.value,
				'city': item.city,
				'state': item.state,
				'zip_code': item.zipcode,
				'address_line2': item.secondary,
			}
			
			me.parse_validate_and_set_in_model(me.values);

			me.set_input(me.values).then(()=>{
				me.$input.trigger("change");
				me.sub_step.form.move_to_next_field(me);
			});

		});

		this.$input.on("awesomplete-selectcomplete", function(e) {
			var o = e.originalEvent;
			if(o.text.value.indexOf("__link_option") !== -1) {
				me.$address_line1.val("");
			}
		});
	}

	init_client(){
		let me = this;
		this.retry = 0;
		let selectedStates = [];
		if(this.frm && this.frm.doctype && frappe.boot.enabledStates
						&& frappe.boot.enabledStates[this.frm.doctype]){
			selectedStates = frappe.boot.enabledStates[this.frm.doctype];
		}
		if(bond_assets.dev.is_developer_mode()){
			bond_assets.dev.logger.log_info(selectedStates);
		}
		geo.utils.get_client().then((client)=>{
			this.client = client;
			this.$address_line1.on("input", frappe.utils.debounce((e)=> {
				var doctype = "Address";
				if(!doctype) return;
				if (!me.$address_line1.cache[doctype]) {
					me.$address_line1.cache[doctype] = {};
				}

				var term = $(e.target).val();

				if (this.$address_line1.cache[doctype][term]!=null) {
					// immediately show from cache
					this.awesomplete.list = this.$address_line1.cache[doctype][term];
				}

				const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(term);
				//lookup.includeOnlyStates = selectedStates;
				this.client.autocomplete.send(lookup).then((r)=>{
					if(!this.$address_line1.is(":focus")) {
						return;
					}
					$.each(r.result, (idx, val)=>{
						val.value  = val.streetLine;

						val.label = frappe.utils.format("{0} {1}, {2} {3}", [
									val.streetLine, val.city, val.state, val.zipcode]);

						val.description = val.label;
						val.idx = idx;
					});
					r.results = this.merge_duplicates(r.result);
					this.$address_line1.cache[doctype][term] = r.results;
					this.awesomplete.list = this.$address_line1.cache[doctype][term];
				}).catch((r)=>{
					if(geo.utils.has_error(r)){
						if(this.retry <= 5){
							geo.utils.get_client(true).then((client)=>{
								this.retry = +1 	;
								this.client = client;
							});
						}
					}
				});
			}, 500));
		});
	}

	merge_duplicates(results) {
		// in case of result like this
		// [{value: 'Manufacturer 1', 'description': 'mobile part 1'},
		// 	{value: 'Manufacturer 1', 'description': 'mobile part 2'}]
		// suggestion list has two items with same value (docname) & description
		return results.reduce((newArr, currElem) => {
			if (newArr.length === 0) return [currElem];
			let element_with_same_value = newArr.find(e => e.value === currElem.value);
			if (element_with_same_value) {
				element_with_same_value.description += `, ${currElem.description}`;
				return [...newArr];
			}
			return [...newArr, currElem];
		}, []);
		// returns [{value: 'Manufacturer 1', 'description': 'mobile part 1, mobile part 2'}]
	}

	is_valid_zip_code(zip_code){
		let flag = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip_code);
		if(!flag)
			return flag;

		if(this.df.apply_state_validation){
			geo.utils.validate_zip_code(zip_code).then((data)=>{
				console.log(data);
			});
		}
		return true;
	}
}

if(Awesomplete) {
    Awesomplete.prototype.get_item = function(value) {
        return this._list.find(function(item) {
            return item.value === value;
        });
    };
}
