
frappe.ui.form.ControlSelect = class BondControlSelect extends frappe.ui.form.ControlData{
	static html_element = 'select';
	make_input() {
		
		if(this.$input){
			return;
		}

		let $input = $(this.get_select_wrapper(this.df)).appendTo(this.$wrapper.find(".input-main-wrapper"));

		this.$input = this.$wrapper.find(`select[data-fieldname='${this.df.fieldname}']`);
		if(!bond_assets.device.is_mobile_device()){
			//$input.addClass("pl-0");
		}
		this.sync_with_field_up($input);

		//this.only_input = true;
		this.set_options(this.df.options);
		this.$input.niceSelect()
			.attr("data-fieldname", this.df.fieldname)
			.find("ul").attr("data-fieldname", this.df.fieldname);
		
		this.bind_change_event();

	}
	set_icons(value){
		super.set_icons(value);
		this.$wrapper.find(`.control-input-field[data-fieldname='${this.df.fieldname}']`).addClass("not-empty");
	}
	get_select_wrapper(df){
		return `<div class="col-12 d-flex justify-content-center">
					<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper" data-fieldname=${df.fieldname}>
						<div class="control-tooltip"></div>
						<select class="wide nice-select-custom text-muted" data-fieldname="${df.fieldname}">
						</select>
						<div class="input-group-prepend">
							<span class="input-group-text">
								<span class="circle-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="10.916" height="11.713" viewBox="0 0 10.916 11.713">
										<g id="icon_Selet" data-name="icon Selet" transform="translate(0 1.25)">
										<path id="Контур_92592" data-name="Контур 92592" d="M6999.462-3296.692h10.916" transform="translate(-6999.462 3296.692)" fill="none" stroke="#fff" stroke-width="2.5"/>
										<path id="Контур_92593" data-name="Контур 92593" d="M6999.462-3296.692h10.916" transform="translate(-6999.462 3301.299)" fill="none" stroke="#fff" stroke-width="2.5"/>
										<path id="Контур_92594" data-name="Контур 92594" d="M6999.462-3296.692h10.916" transform="translate(-6999.462 3305.905)" fill="none" stroke="#fff" stroke-width="2.5"/>
										</g>
									</svg>
									<svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
										<g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
										<path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
										</g>
									</svg>                                                                    
								</span>
							</span>
						</div>
					</div>
				</div>`;
	}

	set_options(options) {
		// reset options, if something new is set
		if(typeof options==="string") {
			options = options.split("\n");
		}

		// nothing changed
		if (JSON.stringify(options) === this.last_options) {
			return;
		}

		this.last_options = JSON.stringify(options);
		options = [this.df.placeholder].concat(options);
		this.selected = options[0];
		if($.isPlainObject(this.selected)){
			this.selected = this.selected.value;
		}

		this.$input.empty().add_options(options || []);
		if(this.selected) {
			this.$input.val(this.selected);
		}
		this.$input.niceSelect("update")
			.attr("data-fieldname", this.df.fieldname)
			.find("ul").attr("data-fieldname", this.df.fieldname);
	
		return Promise.resolve();
	}

	set_formatted_input(value) {
		// refresh options first - (new ones??)
		if(is_null(value)){
			// select disabled value;
			this.$input.val(this.selected);
			value = null;
		}else{
			this.$input.val(value);
		}

		this.$input.niceSelect("update");

		if(value){
			this.$input.next().removeClass("text-muted").addClass("select-active");
		}else{
			this.$input.next().removeClass("select-active").addClass("text-muted");
		}
	}

	get_input_value(){
		return this.$input.val();
	}
};

// add <option> list to <select>
(function($) {
	$.fn.add_options = function(options_list) {
		// create options
		for(var i=0, j=options_list.length; i<j; i++) {
			var v = options_list[i];
			var value = null;
			var label = null;
			if (!is_null(v)) {
				var is_value_null = is_null(v.value);
				var is_label_null = is_null(v.label);
				var is_disabled = Boolean(v.disabled);

				if (is_value_null && is_label_null) {
					value = v;
					label = __(v);
				} else {
					value = is_value_null ? "" : v.value;
					label = is_label_null ? __(value) : __(v.label);
				}
			}
			if(i===0){
				is_disabled=true;
			}
			$('<option>').html(cstr(label))
				.attr('value', value)
				.prop('disabled', is_disabled)
				.appendTo(this);
		}
		// select the first option
		this.selectedIndex = 0;
		return $(this);
	};
	$.fn.set_working = function() {
		this.prop('disabled', true);
	};
	$.fn.done_working = function() {
		this.prop('disabled', false);
	};
})(jQuery);