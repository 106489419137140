/*
*/

frappe.provide("frappe.ui.form.static_controls");

$.extend(frappe.ui.form.static_controls, {

    make_date_field: function($parent, placeholder, options){
        if(!options)
            options = {};
        
        let sysdefaults = frappe.boot.sysdefaults;

        let lang = 'en';

        let date_format = sysdefaults && sysdefaults.date_format
            ? sysdefaults.date_format : 'yyyy-mm-dd';

        let now_date = new Date();

        let today_text = __("Today");
        let today = true;

        if(!placeholder)
            placeholder  = date_format;

        let $input = $(`<input class="js-text-form form-control border-0" type="date" />`).appendTo($parent);
        let $icon = $(`<div class="input-group-prepend">
                        <span class="input-group-text">
                            <span class="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <g><g><path fill="currentColor" d="M9.256.036a2.833 2.833 0 0 0-2.013.833L2.08 6.036a4.679 4.679 0 0 0-1.381 3.33v2.075h2.076a4.679 4.679 0 0 0 3.33-1.381l5.167-5.162A2.85 2.85 0 0 0 9.256.036z">
                                    </path></g></g>
                                </svg>
                            </span>
                        </span>
                    </div>`).appendTo($parent);
        frappe.ui.form.static_controls.handle_empty_not_empty($input);
        
        $input.attr("type", "date")
            .attr("aria-describedby", "mm/dd/yyyy")
            .attr("placeholder", "mm/dd/yyyy")
            .attr("pattern", "\d{2}-\d{2}-\d{4}")
    
        let min_date = null;
        let max_date = null;
        if(!is_null(options.min_date) && !is_null(options.max_date)){
            min_date = frappe.datetime.add_months(frappe.datetime.nowdate(), options.min_date);
            max_date = frappe.datetime.add_months(frappe.datetime.nowdate(), options.max_date);
        }else if(!is_null(options.min_date)){
            min_date = frappe.datetime.add_months(frappe.datetime.nowdate(), options.min_date);
        }else if(!is_null(options.max_date)){
            max_date = frappe.datetime.add_months(frappe.datetime.nowdate(), options.max_date);
        }
        min_date && this.$input.attr("min", min_date);
        max_date && this.$input.attr("max", max_date);
        return $input;
    },

    make_data_field: function($wrapper, placeholder, additional_classes){

        let $input = $(`<input class="js-text-form js-text-not-empty-tab form-control border-0"/>`)
                            .appendTo($wrapper);

        $input.attr("type", "text")
            .attr("aria-describedby", frappe.scrub(placeholder || ''))
            .attr("placeholder", placeholder || "");
        
        if(additional_classes){
            $input.addClass(additional_classes);
        }
        frappe.ui.form.static_controls.add_pen_icon($wrapper);
        frappe.ui.form.static_controls.handle_empty_not_empty($input);

        return $input
    },

    make_integer_field: function($wrapper, placeholder, additional_classes){

        let $input = $(`<input class="js-text-form js-text-not-empty-tab form-control border-0"/>`)
                            .appendTo($wrapper);

        $input.attr("type", "number")
            .attr("aria-describedby", frappe.scrub(placeholder))
            .attr("placeholder", placeholder || "")
            .attr("inputmode", "numeric")
            .attr("pattern", "\\d*"); //for chrome;
        
        
        if(additional_classes){
            $input.addClass(additional_classes);
        }
        frappe.ui.form.static_controls.add_pen_icon($wrapper);
        frappe.ui.form.static_controls.handle_empty_not_empty($input);
        return $input
    },
    make_radio_field_with_image: function($wrapper, fieldname, options){

        $.each(options, (idx, opt)=>{
            $(`<label class="btn tab-link border rounded-lg py-5 py-md-4 py-xxl-5 px-4 text-center mx-2 mx-lg-2">
                <input type="radio" name="${fieldname}" value="${opt.value}">
                <img src="${opt.image_src}" alt="${opt.label}" class="img-fluid mb-xl-2 mx-auto">
                <span class="font-form-description mt-3 mt-xl-4 mb-0 d-block">${opt.label}</span>`
            ).appendTo($wrapper);
        });
        
    },
    add_pen_icon: function($wrapper){
        $(`<div class="input-group-prepend">
            <span class="input-group-text">
                <span class="circle-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g><g><path fill="currentColor" d="M9.256.036a2.833 2.833 0 0 0-2.013.833L2.08 6.036a4.679 4.679 0 0 0-1.381 3.33v2.075h2.076a4.679 4.679 0 0 0 3.33-1.381l5.167-5.162A2.85 2.85 0 0 0 9.256.036z"/>
                        </g></g>
                    </svg>
                </span>
            </span>
        </div>`).appendTo($wrapper);
    },
    handle_empty_not_empty: function($input){
        $input.on('change', function(e) {
            if ($(this).val()) {
                $(this).parent().addClass("not-empty");
            } else {
                $(this).parent().removeClass("not-empty");
            }
        });
    },
});