/*
    Sub Step Form
*/

import "./disclaimer";

frappe.ui.SubStep = class SubStep{
    constructor(args){
        // sync step property
        $.extend(this, args);
        this.make();
    }

    make(){

        this.$wrapper = $(`<form class="substep-form h-100" autocomplete="off">
            </form`).on("submit", (e)=>{
                return false;
            })
            .attr('substep-name', this.df.fieldname)
            .appendTo(this.parent).hide();

        let args = $.extend({}, {
            frm: this.frm,
            doc: this.frm.doc,
            fields: this.fields,
            parent: this.$wrapper,
            step: this.step,
            sub_step:this,
            df: this.df,
            idx: this.idx,
        });
        this.form = this.init_layout(args);
        this.onload();
    }

    init_layout(args){
        let layout = new frappe.ui.BondFieldGroup(args);
        layout.make();
        return layout;
    }

    onload(){
        try{
            if(this.frm.form_controller && this.frm.form_controller[this.df.fieldname+"_onload"]){
                this.frm.form_controller[this.df.fieldname+"_onload"](this);
            }
        }catch(e){
            console.error("Error while calling onload");
        }
    }

    setup_privacy_policy_wrapper(){
        this.$policy_and_info_wrapper = $(`<div class="col-12 d-none">
                <div class="row">
                    <div class="col-12 mb-3 pb-1 info-wrapper d-none">
                        <div class="info-box d-flex">
                            <div class="info-box-icon">
                                <img src="/assets/bond_assets/images/icons/info-bulb-icon.png"
                                                    alt="info-bulb-icon.png" class="img-fluid">
                            </div>
                            <div class="info-box-text helvetica-font"></div>
                        </div>
                    </div>
                    <div class="col-12 policy-wrapper">
                        <div class="form-description policy-description">
                        </div>
                    </div>
                </div>
            </div>
        </div>`).appendTo(this.$wrapper.find(".form-layout"));

        let tooltip = this.df.tooltip;
        if(tooltip){
            try{
                tooltip = frappe.render_template(this.df.tooltip, {frm: this.frm});
                if(bond_assets.device.is_mobile_device()){
                    this.$policy_and_info_wrapper.removeClass("d-none");
                }
            }catch(e){
            }
            $(`<p>${tooltip}</p>`).appendTo(this.$policy_and_info_wrapper.find(".policy-description"));
            //this.$policy_and_info_wrapper.removeClass("d-none");
        }


        this.$policy_and_info_wrapper.find(".redirect-to-privacy-policy").on("click", (e)=>{
            let url = `${frappe.boot.landing_server}/privacy-policy`;
            window.open(url, "_blank");
            return false;
        });

        this.$policy_and_info_wrapper.find(".redirect-to-terms-of-use").on("click", (e)=>{
            let url = `${frappe.boot.landing_server}/terms-of-use`;
            window.open(url, "_blank");
            return false;
        });
    }

    show_terms_and_policy(){
        this.$policy_and_info_wrapper.removeClass("d-none");
    }

    hide_terms_and_policy(){
        this.$policy_and_info_wrapper.removeClass("d-none");
    }

    init_step_handler(){
        this.setup_privacy_policy_wrapper();
        let $actions = `<div id="submit-auto-form-1" class="actions js-form-field form-row-disabled text-center position-relative col-12 mt-5 mt-md-0 pt-0 mb-0 pb-0" data-fieldname='form-actions'>
                                <div class="form-wrap mx-auto form-info d-none">
                                    <div class="form-description"></div>
                                </div>
                        </div>`;
        if(bond_assets.device.is_mobile_device()){
            $actions = `<div id="submit-mob-1" class="position-fixed d-none d-sm-none">
                        <div class="container-form">
                            <div class="d-flex justify-content-center mobile-actions">
                            </div>
                        </div>
                    </div>`;
        }
        this.$actions = $($actions).appendTo(this.$wrapper.find(".form-layout"))

        let promise;
        if(bond_assets.device.is_mobile_device()){
            promise = this.setup_mobile_actions();
        }else{
            promise = this.setup_desktop_actions();
        }
        return new Promise((resolve, reject)=>{
            promise.then(()=>{
                this.init_actions();
                resolve();
            });
        });
    }

    setup_desktop_actions(){
        return new Promise((resolve, reject)=>{
            this.$terms_wrapper = this.$actions.find(".form-description");
            this.$back_btn = $(`<button type="button" name="back"  class="d-none documents-upload-form__back-btn btn-back-btn">
                                    <span>${__("Back")}</span>
                            </button>`).appendTo(this.$actions);
            this.$submit_btn = $(`<input type="button" name="next" class="next btn btn-dark btn-xl mt-3 mt-md-0 primary-action-btn"
                                    value='${__("Continue")}'/>`).appendTo(this.$actions);
            this.$actions.addClass("d-none");
            resolve();
        });

    }
    setup_mobile_actions(){
        
        return new Promise((resolve, reject)=>{
            this.$terms_wrapper = $(`<div class="form-description d-sm-none legal-copy"></div>`);

            this.$back_btn = $(`<button type="button" name="back" class="documents-upload-form__back-btn btn-back-btn js-form-submit-back d-none">
                                <span>${__("Back")}</span>
                            </button>`).appendTo(this.$actions.find(".mobile-actions"));
            
            this.$submit_btn = $(`<input class="next btn btn-dark btn-xl mt-0 mt-md-0 d-sm-none js-form-submit-next primary-action-btn"
                            type="button" name="submit" value='${__("Continue")}'/>`).appendTo(this.$actions.find(".mobile-actions"));
            this.$actions.addClass("px-0");
            resolve();
        });
    }

    init_actions(){
        this.$submit_btn.on("click", (e)=>{
            e.preventDefault();
            if(this.$actions.hasClass("form-completed")){
                this.validate(true, false).then((args)=>{
                    if(args.validate_results == true){
                        this.save_form($(e.target), e);
                    }
                });
                return false;
            }
            this.validate(true, false).then((args)=>{
                if(args.validate_results == true){
                    if(bond_assets.device.is_mobile_device()){
                        this.toggle_legal_copy(args.show_legal_copy===true?false:true, args);
                        this.toggle_back_btn(args.show_back_btn===true?false:true, args.is_last_step);
                    }else if(args.sub_step){
                        args.sub_step.toggle_legal_copy(args.show_legal_copy===true?false:true, args);
                        args.sub_step.toggle_back_btn(args.show_back_btn===true?false:true, args.is_last_step);
                    }
                    if(this.step.is_path){
                        // if is path then start over the form using by making new steps
                        return this.frm.make_steps(true);
                    }
                    this.move_to_next_step();
                }
            });

        });

        this.$back_btn.on("click", (e)=>{
            e.preventDefault();
            this.validate(false, true).then((args)=>{
                this.move_to_prev_step(args).then(()=>{
                    this.toggle_back_btn(args.show_back_btn===true?false:true, args.is_last_step);
                });
            });
        });
    }

    save_form($ele, e){
        this.frm.save($ele, e);
    }

    validate(is_submit_btn=false, is_back_btn=false){
        return new Promise((resolve, reject)=>{
            if(!this.cur_field){
                this.cur_field = this.get_cur_field();
            }
            try{
                this.validate_actions(is_submit_btn, is_back_btn).then((args)=>{
                    // toggle d-none class
                    if(is_back_btn == false && this.frm.form_controller){
                        let global_validate = "validate_sub_step";
                        let sub_step_validate = `${this.df.fieldname}_validate`;
                        let func = null
                        if(this.frm.form_controller[global_validate]){
                            func = this.frm.form_controller[global_validate](this);
                            if(func && func.then){
                                func.then((flag)=>{
                                    if(flag && this.frm.form_controller[sub_step_validate]){
                                        func = this.frm.form_controller[sub_step_validate](this);
                                        if(func && func.then){
                                            func.then((flag)=>{
                                                args.validate_results = flag;
                                                resolve(args)
                                            });
                                        }else{
                                            args.validate_results = func;
                                            resolve(args);
                                        }
                                    }else{
                                        args.validate_results = flag;
                                        resolve(args);
                                    }
                                });
                            }else{
                                args.validate_results = func;
                                resolve(args);
                            }
                        }else if(this.frm.form_controller[sub_step_validate]){
                            func = this.frm.form_controller[sub_step_validate](this);
                            if(func && func.then){
                                func.then((flag)=>{
                                    args.validate_results = flag;
                                    resolve(args);
                                })
                            }else{
                                args.validate_results = func;
                                resolve(args);
                            }
                        }else{
                            args.validate_results = true;
                            resolve(args);
                        }
                    }else{
                        args.validate_results =  true;
                        resolve(args);
                    }
                });
            }catch(e){
                reject(e);
            }
        });
    }
    enable_actions(cur_field){
        this.cur_field = cur_field;
        this.$submit_btn.addClass("active-c");
        // if actions are hidden due to first step
        if(bond_assets.device.is_mobile_device()){
            if(this.cur_field && this.frm.is_last_form_field(this.cur_field, true, true)){
                // if next field isn't visible due to dependency
                this.complete_submit_form("center");
            }else if(this.cur_field && this.$actions.hasClass("form-completed")){
                this.uncomplete_submit_form("center");
            }
        }
        this.$actions.removeClass("d-none");
    }
    disable_actions(cur_field){
        this.cur_field = cur_field;
        this.$submit_btn.removeClass("active-c");
    }

    validate_actions(is_submit_btn, is_back_btn){
        let args = {
            show_legal_copy: true,
            show_back_btn: false,
            last_step: false,
        };
        return new Promise((resolve, reject)=>{
            if(bond_assets.device.is_mobile_device()){
                let field = this.get_conditional_field(is_submit_btn, is_back_btn);
                args.field = field;
                if(!this.frm.is_first_form_field(field)){
                    // hide the legal privacy and terms notice and show back btn
                    args.show_legal_copy = false;
                    args.show_back_btn = true;
                }
                if(this.frm.is_last_form_field(field)){
                    args.is_last_step = true;
                }
            }else{
                let sub_step_obj;
                if(is_submit_btn){
                    sub_step_obj = this.next_sub_step_obj;
                }else{
                    sub_step_obj = this.prev_sub_step_obj;
                }
                if(!this.frm.is_first_sub_step(sub_step_obj)){
                    // show the legal privact  and  terms notice
                    args.show_legal_copy = false;
                    args.show_back_btn = true;
                }
                if(this.frm.is_last_sub_step(sub_step_obj)){
                    args.is_last_step = true;
                }
                args.sub_step = sub_step_obj;
            }
            resolve(args);
        });
    }

    toggle_legal_copy(hide, args){
        let sub_step = args.sub_step;
        if(!sub_step && args.field && args.field.sub_step){
            sub_step = args.field.sub_step;
        }
        if(!sub_step){
            sub_step = this;
        }
        if(sub_step.df.tooltip){
            sub_step.$policy_and_info_wrapper.toggleClass("d-none", hide);
        }
    }

    toggle_back_btn(hide, is_last_step=false){
        this.$back_btn.toggleClass("d-none", hide);
        if(!hide){
            if(!is_last_step){
                this.set_btn_text(this.$submit_btn, "Save & Continue");
            }else{
                this.complete_submit_form("center");
            }
        }else{
            this.set_btn_text(this.$submit_btn, "Continue");
            if(is_last_step){
                this.complete_submit_form("center");
            }
        }
        if(!hide){
            this.$actions.addClass("form-with-legal-copy__submit-wrapper form-with-legal-copy__submit-wrapper--addon-btn");
            this.$submit_btn.addClass("next-no-fade btn-lg").removeClass("btn-xl");
        }else{
            this.$actions.removeClass("form-with-legal-copy__submit-wrapper form-with-legal-copy__submit-wrapper--addon-btn");
        }        
    }

    complete_submit_form(text_align){
        this.set_btn_text(this.$submit_btn, this.frm.primary_action_title || "Submit");
        this.$actions.addClass("form-completed");
    }

    uncomplete_submit_form(text_align){
        this.set_btn_text(this.$submit_btn, "Save & Continue");
        this.$actions.removeClass("form-completed");
    }

    set_btn_text(btn, text){
        let _btn = btn;
        if(!(btn && btn.nodeName)){
            _btn = btn.get(0);
        }
        if(_btn.nodeName == "BUTTON"){
            btn.text(__(text));
        }else{
            btn.val(__(text));
        }
    }

    show_current_step(){
        this.show_step(this);
    }

    is_actions_disabled(){
        return this.$actions.find(".form-disabled").length >= 1;
    }
    move_to_next_step(){
        if(this.next_sub_step_obj){
            this.set_progress_for_next_step(this, this.next_sub_step_obj);
        }else if(bond_assets.device.is_mobile_device()){
            this.set_progress_for_next_step(this, null);
        }
    }

    move_to_prev_step(args){
        return new Promise((resolve, reject)=>{
            if(this.prev_sub_step_obj && this.prev_sub_step_obj.$actions.hasClass("form-completed")){
                this.prev_sub_step_obj.$actions.removeClass("form-completed");
            }else if(this.$actions.hasClass("form-completed")){
                this.$actions.removeClass("form-completed");
            }
            if(bond_assets.device.is_mobile_device()){
                this.set_progress_for_prev_step(this, null);
            }else if(this.prev_sub_step_obj){
                this.set_progress_for_prev_step(this, this.prev_sub_step_obj);
            }

            resolve(args);
        });
        
    }
    submit_form(){
        if(this.df.tooltip){
            new bond_assets.form.disclaimer.Disclaimer({
                secondary_action: ()=>{
                    // don't do anything
                },
                primary_action: ()=>{
                    this.frm.submit_form();
                },
                message: this.df.tooltip
            });
        }else{
            this.frm.submit_form();
        }
    }

    set_progress_for_next_step(){
        this.step.set_progress_for_next_step(this, this.next_sub_step_obj).then((sub_step)=>{
            sub_step.show_step(true, false);
        });
    }

    set_progress_for_prev_step(){
        this.step.set_progress_for_prev_step(this, this.prev_sub_step_obj).then((sub_step)=>{
            sub_step.show_step(false, true);
        });
    }

    show_step(is_submit_btn, is_back_btn, is_first_step=false){
        /*
            In mobile/iPad device don't move to next field, rather move to
            the next field wrapper and make other wrapper hidden.
        */
        let field = this.get_conditional_field(is_submit_btn);
        if(bond_assets.device.is_mobile_device() && field){
            this.hide_cur_field();
            field.$wrapper.removeClass("d-none");
            if(!is_null(field.get_value()) || !field.df.reqd){
                this.enable_actions(field);
                let next_field = this.get_next_visible_field();
                if(next_field){
                    next_field.enable_field();
                }
            }else{
                this.disable_actions(field);
            }
        }else if(bond_assets.device.is_mobile_device()){

        }else{
        }
        this.show_first_field_if_not_init();
        this.adjust_form_top_margin();
        if(is_first_step && this.frm.is_one_sub_step_form_only()){
            this.complete_submit_form();
        }else if(is_back_btn){
            this.uncomplete_submit_form();
        }
        if(this.frm.form_controller && this.frm.form_controller.enable_actions_on_sub_step_render === true){
            this.enable_actions();
        }
    }

    show_all_fields_if_init(){
        if(bond_assets.device.is_mobile_device()){
        }else{
            if(!this.is_disabled_centric_fields() && this._first_field_init){
                // for mobile device
                let $fields = $(this.form.parent).find(".js-form-field.hidden-due-to-with-init");
                if(bond_assets.device.is_mobile_device()){
                    $fields.removeClass("hidden-due-to-with-init");
                    $(this.form.parent).find(".js-form-field[data-fieldname='form-actions']").removeClass("d-none");
                }else{
                    $fields.removeClass("hidden-due-to-with-init d-none");
                }
                $(this.form.parent).find(".first-form-visible-field-margin-top").addClass("mt-4");
                $(this.form.parent).find(".js-form-field").removeClass("vertical-align first-form-visible-field-margin-top");
                this.get_first_visible_field().$wrapper
                    .removeClass("mt-4")
                    .addClass("first-form-visible-field-margin-top");

                this.toggle_legal_copy(false, {});
            }
        }
    }

    show_first_field_if_not_init(){
        /*
            Show only first step if the form isn't init already.
        */

        if(this.disable_fields_margin){
            return;
        }
        if(bond_assets.device.is_mobile_device()){
        }else {
            if(!this.is_disabled_centric_fields() && !this._first_field_init){
                $(this.form.parent).find(".js-form-field:not(:hidden)").slice(1)
                    .addClass("hidden-due-to-with-init d-none");
                this._first_field_init = true;
                this.get_first_visible_field().$wrapper.addClass("vertical-align");
            }
        }
    }

    adjust_form_top_margin(){
        if(this.disable_fields_margin){
            return;
        }
        if(bond_assets.device.is_mobile_device()){

        }else{
            if(!this.is_disabled_centric_fields() && this._first_field_init
                    && !$(this.form.parent).find(".js-form-field:visible").first().hasClass("vertical-align")){
                $(this.form.parent).find(".js-form-field").removeClass("first-form-visible-field-margin-top");
                this.get_first_visible_field().$wrapper.addClass("first-form-visible-field-margin-top");
                
            }else if(!$(this.form.parent).find(".js-form-field:visible").first().hasClass("vertical-align")){
                $(this.form.parent).find(".js-form-field.form-visible-field-margin-top").removeClass("form-visible-field-margin-top mt-4");
                this.get_first_visible_field()
                    .$wrapper.removeClass("mt-4")
                    .addClass("form-visible-field-margin-top first-form-visible-field-margin-top");
                this.$policy_and_info_wrapper.removeClass("d-none");
            }
        }
    }

    get_sub_step(step_fieldname){
        return this.frm?this.frm.sub_steps_dict[step_fieldname]: {};
    }

    get_visible_fields(){
        let fields = [];
        this.form.fields_list.forEach((fieldobj, idx)=>{
            if(!fieldobj.$wrapper.hasClass("hide-control")){
                fields.push(fieldobj);
            }
        });
        return fields;
    }

    get_next_visible_field(){
        return this.frm.get_next_field(this.get_cur_field());
    }

    get_prev_visible_field(){
        return this.frm.get_prev_field(this.get_cur_field());
    }

    get_cur_field(reverse=false, prev_step=null){
        /*
            reverse means user is going to previous step
        */
        if(reverse){
            /*
            let next_field = this.get_next_visible_field();
            if(next_field && next_field.$wrapper.is(":visible") && !next_field.get_value()){
                this.cur_field = next_field;
            }
            */
        }
        if(reverse && this.cur_field && (this.cur_field.$wrapper.hasClass("column-break")
                || this.cur_field.$wrapper.hasClass("sync-with-field-up-control"))){
            return this.form.fields_dict[this.cur_field.$wrapper.find("input, select, button").first().attr("data-fieldname")];

        }else if(!reverse && this.cur_field && this.cur_field.$wrapper.hasClass("sync-with-field-up-control")){
            return this.form.fields_dict[this.cur_field.$wrapper.find("input, select, button").last().attr("data-fieldname")];
        }
        return this.cur_field;
    }

    hide_cur_field(){
        this.get_cur_field().$wrapper.addClass("d-none");
    }

    get_conditional_field(is_submit_btn){
        let curfield = this.get_cur_field();
        return is_submit_btn===true?this.get_next_visible_field():this.get_prev_visible_field();
    }

    get_first_visible_field(){
        let fieldname = this.$wrapper.find(".js-form-field:visible").first().attr("data-fieldname");
        return this.form.fields_dict[fieldname];
    }

    is_disabled_centric_fields(){
        return this.df.disable_centric_fields === 1;
    }
}
