
frappe.ui.form.ControlSearchableData = class BondControlSearchableData extends frappe.ui.form.ControlData{
	make_input() {
        if(this.$input){
            return;
        }
        var me = this;
        super.make_input();
        this.setup_awesomeplete();
        this.set_options(this.df.options, false);

        this.$input.on("focus", function() {
            setTimeout(function() {

                if(!me.$input.val()) {
                    me.$input.val("").trigger("input");
                }
            }, 500);
        });
    }
    set_input_attributes(){
        super.set_input_attributes();
        this.$wrapper.find(`${this.html_element}[data-fieldname='${this.df.fieldname}']`)
                .addClass("awesomplete ")
    }
    bind_change_event(){
        let me = this;

        this.$input.on("change", (e)=>{
            let value = this.get_input_value();
            this.parse_validate_and_set_in_model(value, me.df.fieldname, e);
            this.set_icons(value, e);
            let _$wrapper = this.$wrapper.find(`.control-input-field[data-fieldname="${this.df.fieldname}"]`);
            if(value){
                _$wrapper.addClass("not-empty");
            }else{
                _$wrapper.removeClass("not-empty");
            }
		});
    }

    refresh(flag){
        super.refresh(flag);
    }
	get_input_value(){
		return this.validate(this.$input.val());
	}
    setup_awesomeplete() {
        var me = this;
        this.$input.cache = {};
        this.awesomplete = new Awesomplete(me.$input.get(0), {
            minChars: 0,
            maxItems: 99,
            list: [],
            data: function (item) {
                return {
                    label: item.label || item.value,
                    value: item.value
                };
            },
            filter: function() {
                return true;
            },
            item: function (item) {
                var d = this.get_item(item.value);
                if(!d.label) {	d.label = d.value;}
                var _label = (me.translate_values) ? __(d.label) : d.label;
                var html = d.html ||  _label ;
                if(d.secondary) {
                    html += '<br><span class="small">' + __(d.secondary) + '</span>';
                }
                return $(`<li></li>`)
                    .data('item.autocomplete', d)
                    .attr("role", "option")
                    .attr("id", `awesomplete_list_1_item_${d.idx}`)
                    .html(`${html}`)
                    .get(0);
            },
            sort: function() {
                return 0;
            }
        });


        this.$input.on("input", frappe.utils.debounce(function(e) {
            var doctype = "Search";
            me.hide_error_msg();
            if (!me.$input.cache[doctype]) {
                me.$input.cache[doctype] = {};
            }

            var term = e.target.value;
            let results = me.filter_values(term, me.options);
            if (me.$input.cache[doctype][term]!=null) {
                // immediately show from cache
                me.awesomplete.list = me.$input.cache[doctype][term];
            }else{
                me.$input.cache[doctype][term] = results;
                me.awesomplete.list = me.$input.cache[doctype][term];
            }

        }, 500));

        this.$input.on("blur", function(e) {
            if(me.selected) {
                me.selected = false;
                return;
            }
            var value = me.get_input_value();
            if(value!==me.last_value) {
                me.set_value(value);
            }
        });

        this.$input.on("awesomplete-open", ()=> {
            this.hide_error_msg();
            this.autocomplete_open = true;
        });

        this.$input.on("awesomplete-close", function() {
            me.autocomplete_open = false;
        });

        this.$input.on("awesomplete-select", function(e) {
            var o = e.originalEvent;
            var item = me.awesomplete.get_item(o.text.value);

            me.autocomplete_open = false;

            // prevent selection on tab
            var TABKEY = 9;
            if(e.keyCode === TABKEY) {
                e.preventDefault();
                me.awesomplete.close();
                return false;
            }

            if(item && item.action) {
                item.value = "";
                item.action.apply(me);
            }

            me.$input.val(me.validate(item.value)).trigger("change");

        });

        this.$input.on("awesomplete-selectcomplete", function(e) {
            var o = e.originalEvent;
            if(o.text.value.indexOf("__link_option") !== -1) {
                me.$input.val("");
            }
        });
    }

    filter_values(term, options){
        return options.filter((item, idx)=>{
            return item.label.toLowerCase().search(cstr(term.toLowerCase())) >= 0;
        });
    }
    set_options(options, removeFirst=false){
        // reset options, if something new is set
        if(!options || (!$.isArray(options) && is_null(options))){
            options = []
        }
		if(typeof options==="string") {
			options = options.split("\n");
		}
		if(removeFirst){
			// remove the first option from the list
			options = options.splice(1);
		}

		// nothing changed
		if (JSON.stringify(options) === this.last_options) {
			return;
		}

		this.last_options = JSON.stringify(options);


		this.selected = options[0];
		if($.isPlainObject(this.selected)){
			this.selected = this.selected.value;
		}

		this.options = this.get_options(options);

		return Promise.resolve();
    }

    get_options(options){
        let data = [];
        for(var i=0, j=options.length; i<j; i++) {
			var v = options[i];
			var value = null;
			var label = null;
			if (!is_null(v)) {
				var is_value_null = is_null(v.value);
				var is_label_null = is_null(v.label);
				var is_disabled = Boolean(v.disabled);

				if (is_value_null && is_label_null) {
					value = v;
					label = __(v);
				} else {
					value = is_value_null ? "" : v.value;
					label = is_label_null ? __(value) : __(v.label);
				}
			}
			if(i===0){
				is_disabled=true;
			}
            data.push({
                label: label,
                value: value
            });
        }
        return data;
    }

	validate(value, e){
		value = this.options?this.options.find(item=>value === item.value):"";
		if(is_null(value)){
			value = '';
		}else{
			value = value.value;
		}
		return value;
	}

}
