import '../form/layout';

frappe.provide('frappe.ui');

frappe.ui.BondFieldGroup = class BondFieldGroup extends frappe.ui.form.Layout{
	first_button = false;
	constructor(opts) {
		super(opts);
		this.dirty = false;
		$.each(this.fields || [], function(i, f) {
			if(!f.fieldname && f.label) {
				f.fieldname = f.label.replace(/ /g, "_").toLowerCase();
			}
		});
		if(this.values) {
			this.set_values(this.values);
		}
	}
	make() {
		if(this.fields) {
			super.make();
			this.refresh();
			// set default
			$.each(this.fields_list, (i, field)=> {
				if(i != 0){
					this.disable_field(field);
				}
				if (field.df["default"]) {
					let def_value = field.df["default"];

					if (def_value == 'Today' && field.df["fieldtype"] == 'Date') {
						def_value = frappe.datetime.get_today();
					}

					field.set_input(def_value);
					// if default and has depends_on, render its fields.
				}
			});
			this.refresh_dependency();

			//if(!this.no_submit_on_enter) {
			//	this.catch_enter_as_submit();
			//}

			$(this.wrapper).find('input:not(.table-field, .attachment), select:not(.table-field)').on('change', (e)=>{
				try{
					this.call_events(e);
				}catch(e){
					console.log(e);
				}
			});
		}
	}

	call_events(e, fieldobj){
		return new Promise((resolve, reject)=>{
			this.dirty = true;
			let field_obj;
			if(fieldobj){
				field_obj = fieldobj;
			}else{
				let $input = $(e.target);
				field_obj = this.get_field_obj($input.attr("data-fieldname"));
			}
			this.show_all_fields_if_init(field_obj).then(()=>{
				this.refresh_dependency(field_obj).then(()=>{						
					frappe.run_serially([
						() => this.move_to_next_field(field_obj),
						() => frappe.timeout(0.1),
						() => this.toggle_actions_buttons(field_obj),
						() => frappe.timeout(0.1),
						() => this.store_form(field_obj),
						() => frappe.timeout(0.1),
						() => this.trigger_third_party_form_events(field_obj),
						() => resolve(this)
					]);
				});
			});
		});
	}

	show_all_fields_if_init(field_obj){
		return new Promise((resolve, reject)=>{
			if(this.sub_step && field_obj){
				let flag = true;
				if(field_obj.$wrapper.hasClass("column-break")){
					let val = this.get_column_break_fields_values(field_obj, true);
					if(!val){
						flag = false;
					}
				}else{
					if(is_null(field_obj.get_value())){
						flag = false;
					}
				}
				if(flag){
					this.sub_step.show_all_fields_if_init(this.sub_step);
				}
			}
			resolve();
		});
	}

	disable_field(field){
		let fields = $(this.wrapper).find(".js-form-field")
							.first()
							.find(`input, select, button`);

		let flag = true;

		$.each(fields, (idx, $field)=>{
			if($($field).attr("data-fieldname") === field.df.fieldname){
				flag = false
			}
		});
		flag && field.disable_field && field.disable_field();
	}
	move_to_next_field(field_obj){
		let is_focus_on_next_field = field_obj.df.focus_on_next_field;

		let field_idx = this.fields_list.indexOf(field_obj);

		// don't move to next step if current field is null;
		if(field_obj.get_fullfill_status() === false){
			return false;
		}

		this._moved = false;
		for(var i=field_idx+1; i < this.fields_list.length; i++){

			this._enable_next_field(field_obj).then(($next_wrapper)=>{
				if(is_focus_on_next_field && $next_wrapper){
					this._move_to_next_field($next_wrapper)
				}
			});

			if(this._moved){
				break;
			}

			field_obj = this.fields_list[i];
		}

		this._moved = false;

	}
	_move_to_next_field($next_wrapper){
		$('html, body').animate({
			scrollTop: $next_wrapper.offset().top - 200
		}, 500);
	}
	_enable_next_field(field_obj){

		let $next_wrapper = field_obj.$wrapper.next();
		let next_field = this.get_next_field(field_obj, false);
		if(this._moved === false && !$next_wrapper.hasClass("hide-control")
				&& next_field && !next_field.df.ignore_disabled){

			this._moved = true;
			// enable all fields, if it's colum break
			next_field.enable_field();

			return Promise.resolve($next_wrapper);
		}
		return Promise.resolve(false);
	}
	focus_on_first_input() {
		if(this.no_focus) return;
		$.each(this.fields_list, function(i, f) {
			if(!in_list(['Date', 'Datetime', 'Time', 'Check'], f.df.fieldtype) && f.set_focus) {
				f.set_focus();
				return false;
			}
		});
	}
	/*
	catch_enter_as_submit: function() {
		var me = this;
		$(this.body).find('input[type="text"], input[type="password"], select').keypress(function(e) {
			if(e.which==13) {
				if(me.has_primary_action) {
					e.preventDefault();
					me.get_primary_btn().trigger("click");
				}
			}
		});
	},
	*/
	toggle_actions_buttons(field_obj, dynamical=false, refresh_actions=false){
		if(!refresh_actions && dynamical){
			return;
		}
		if(this.sub_step){
			let values = this.get_values(false, field_obj);
			if(values == null){
				this.sub_step.disable_actions(field_obj);
			}else{
				this.sub_step.enable_actions(field_obj);
			}
		}
	}

	trigger_third_party_form_events(fieldobj){
		if(this.third_party_forms && this.third_party_forms.length >= 1){
			this.third_party_forms.forEach(third_party_form => {
				try{
					if(third_party_form && third_party_form.handle_form_event){
						third_party_form.handle_form_event(this, fieldobj)
					}
				}catch(e){
					console.log(e);
				}
			});
		}
	}

	get_input(fieldname) {
		var field = this.fields_dict[fieldname];
		return $(field.txt ? field.txt : field.input);
	}
	get_field(fieldname) {
		return this.get_field_obj(fieldname);
	}
	get_values(ignore_errors, field_obj) {
		var ret = {};
		var errors = [];
		let fields_dict = this.fields_dict;
		if(bond_assets.device.is_ipad_device() && field_obj){
			fields_dict =  {}
			let f = field_obj;
			if(f.$wrapper.hasClass("column-break") || f.$wrapper.hasClass("sync-with-field-up-control")){
				fields_dict = this.get_column_break_fields_dict(field_obj);
			}else{
				fields_dict[field_obj.df.fieldname] = field_obj;
			}
		}
		for(var key in fields_dict) {
			var f = this.fields_dict[key];
			if(f.get_value) {
				var v = f.get_value();
				if(f.df.reqd && is_null(v))
					errors.push(__(f.df.label));

				if(!is_null(v)) ret[f.df.fieldname] = v;
			}
		}

		if(errors.length && !ignore_errors) {
			if(bond_assets.dev.is_developer_mode()){
				console.log(errors);
			}
			return null;
		}
		return ret;
	}
	get_value(key) {
		var f = this.fields_dict[key];
		return f && (f.get_value ? f.get_value() : null);
	}
	set_value(key, val, refresh_actions=false){
		return new Promise(resolve => {
			var f = this.fields_dict[key];
			if(f) {
				f.set_value(val).then(() => {
					f.set_input(val);
					f.set_icons(val);
					this.refresh_dependency();
					this.toggle_actions_buttons(f, true, refresh_actions);
					resolve(this);
				});
			} else {
				resolve(this);
			}
		});
	}
	set_input(key, val) {
		return this.set_value(key, val);
	}
	set_values(dict) {
		for(var key in dict) {
			if(this.fields_dict[key]) {
				this.set_value(key, dict[key]);
			}
		}
	}
	clear() {
		for(var key in this.fields_dict) {
			var f = this.fields_dict[key];
			if(f && f.set_input) {
				f.set_input(f.df['default'] || '');
			}
		}
	}
	set_df_property (fieldname, prop, value) {
		const field    = this.get_field(fieldname);
		field.df[prop] = value;
		field.refresh();
	}
	store_form(field_obj){

		if(this.frm && this.frm.store_form){
			try{
				this.frm.store_form(field_obj.df.fieldname, this);
			}catch(e){
				console.error(e);
			}
		}
	}

	get_column_break_fields_dict(field_obj){
		let fields_dict =  {};
		$.each(this.get_column_break_fields(field_obj), (idx, fieldname)=>{
			fields_dict[fieldname] = this.get_field_obj(fieldname);
		});
		return fields_dict;
	}

	get_next_field(field_obj, ignore_colum_break=true){
		/*
			Ignore column break will fetch the next field wrapper
			and will ignore the field column break field
		*/
		return this.frm.get_next_field(this.get_field_obj(field_obj));

	}

	get_column_break_fields_values(field_obj, validate=false){
		let values =  {};
		let flag = false;
		$.each(this.get_column_break_fields(field_obj), (idx, fieldname)=>{
			let sub_field_obj = this.get_field_obj(fieldname);
			if(validate && sub_field_obj.df.reqd && !sub_field_obj.get_value()){
				flag = true;
			}
			values[fieldname] = this.get_field_obj(fieldname).get_value();
		});
		if(validate && flag){
			return null;
		}
		return values;
	}

	get_column_break_fields(field_obj){
		let fields = [];
		if(field_obj){
			$.each(field_obj.$wrapper.find("input, select"), (idx, $input)=>{
				fields.push($($input).attr("data-fieldname"));
			});
		}

		return fields;
	}

	get_field_obj(fieldname){
		let field = fieldname;
		if(typeof(field) != "object"){
			field = this.fields_dict[fieldname];
		}
		return field;
	}

	toggle_ipad_fields(field_obj){
		this.sub_step.toggle_ipad_fields(field_obj);
	}
}
