import Awesomplete from 'awesomplete';
import Inputmask from "inputmask";
import "./icons";
import './base_control';
import './base_input';
import './scanning';
import './data';
import './searchable_data';
import './data_range';
import './int';
import './float';
import './currency';
import './select';
import './date';
import './time';
import './datetime';
import './date_range';
import './link';
import './dynamic_link';
import './text';
import './code';
import './text_editor';
//import './comment';
import './check';
import './picture_check';
import './image';
import './attach';
import './attach_image';
import 'frappe/public/js/frappe/form/controls/table';
import './color';
import './signature';
import './signature_v2';
import './password';
import './read_only';
import './button';
import './html';
import './markdown_editor';
import './html_editor';
import './heading';
import './autocomplete';
import './barcode';
import './geolocation';
import './multiselect';
import './multicheck';
import './table_multiselect';
import './multiselect_pills';
import './multiselect_list';
import './rating';

import './address';

frappe.ui.form.make_control = function (opts) {
	var control_class_name = "Control" + opts.df.fieldtype.replace(/ /g, "");
	if(frappe.ui.form[control_class_name]) {
		return new frappe.ui.form[control_class_name](opts);
	} else {
		// eslint-disable-next-line
		console.log("Invalid Control Name: " + opts.df.fieldtype);
	}
};

frappe.ui.form.set_input_mask = function($input, options){
	if(in_list(["Phone", "Mobile"], options)){
		Inputmask({
			mask: "(999)-999-9999",
			clearIncomplete: true,
		}).mask($input.get(0));
		$input.attr("inputmode", "numeric");

	}else if(options == "Email!!"){
		Inputmask({
			mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
			greedy: false,
			onBeforePaste: function (pastedValue, opts) {
				pastedValue = pastedValue.toLowerCase();
				return pastedValue.replace("mailto:", "");
			},
			definitions: {
				'*': {
					validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
					casing: "lower"
				}
			}
		}).mask($input.get(0));
	}
};


frappe.ui.form.add_default_handler = ($wrapper)=>{
    $.each($wrapper.find("input"), (e, $ele)=>{
        $ele = $($ele);
        frappe.ui.form.set_input_mask($ele, $ele.attr("data-options"));
    });
    $wrapper.find("input, select").on("change", (e)=>{
        let $target = $(e.currentTarget);
        let options = $target.attr("data-options");
        let value = $target.val();
        if(options == "Email" && !frappe.utils.validate_type(value, "email")){
            value = "";
        }else if(options == "Name" && !frappe.utils.validate_type(value, "name")){
            value = "";
        }
        if($target.get(0).nodeName == "INPUT"){
            if(is_null(value)){
                $target.val('');
            }
            $target.parent().toggleClass("not-empty", is_null(value)?false:true);
        }
    });
};