/*
    Website Form
*/

portal.ui.WebForm = class WebForm{
    constructor(opts){
        $.extend(this, opts);
        this.$parent = this.parent;
        this.fields = [];
        this.fields_dict = {};
        this.fields_list = [];
        this.make(opts.fields);
    }

    make(fields){
        this.error_msg = $(`<div class="col-12 mb-2 text-center error-msg"></div>`)
                            .appendTo(this.$parent);
        $.each(fields, (idx, df)=>{
            this.make_control(df);
        });
        this.handle_submit();
    }

    make_control(df){

        let fieldname = df.fieldname;
        if(!fieldname && df.label){
            fieldname = frappe.scrub(df.label);
        }
        $.extend(df,{
            hide_field_info: true,
            hide_icon: true,
            sync_with_field_bottom: true,
        })
        let field = frappe.ui.form.make_control({
            df:df,
            parent: this.$parent,
            render_input: true,
        });

        field.layout = this;
        field.$wrapper.removeClass("col-lg-11 col-xl-9");
        
        this.fields.push(field);
        this.fields_list.push(field);
        this.fields_dict[fieldname] = field;

    }

    handle_submit(){

        let title = this.primary_button_label || __("Submit");
        this.$submit = $(`<div class="text-center mt-3 mt-md-4 mt-lxg-3 mt-xxl-5 mb-4 pt-2 pt-md-4">
                            <input type="submit" class="next btn btn-dark btn-xl js-form-submit-next" value="${title}"/>
                        </div>`).appendTo(this.$parent);

        this.$submit.find("input").on("click", (e)=>{
            e.preventDefault();
            let values = this.get_values();
            if(!values){
                return;
            }
            this.submit_callback(values);
            return false;
        });
    }

    submit_callback(values){
        console.warn("Implement this feature in sub class");
    }

    get_values(ignore_errors){
        var ret = {};
        var has_errors = false;
        for(var key in this.fields_dict) {
            var f = this.fields_dict[key];
            if(f.get_value) {
                var v = f.get_value();
                if(f.df.reqd && is_null(v) && !ignore_errors){
                    has_errors = true;
                    f.show_error_msg(frappe.utils.format(__("{0} is required"), [f.df.label]));
                }else{
                    f.hide_error_msg();
                }

                if(!is_null(v)) ret[f.df.fieldname] = v;
            }
        }
        if(has_errors && !ignore_errors) {
            return null;
        }
        return ret;
    }

    set_values(values){
        return new Promise((resolve, reject)=>{
            try{
                this.fields_list.forEach((field, idx)=>{
                    if(values && values[field.df.fieldname] && field.set_value){
                        field.set_value(values[field.df.fieldname]);
                    }
                });
                this.refresh_dependency().then(()=>{
                    resolve();
                });
            }catch(e){
                reject(e);
            }
        });
    }

    get_value(fieldname){
        return new Promise((resolve, reject)=>{
            try{
                resolve(this.fields_dict[fieldname].get_value());

            }catch(e){
                reject(e);
            }
        });
    }

    set_value(fieldname, value){
        return new Promise((resolve, reject)=>{
            try{
                this.fields_dict[fieldname].set_value(value);
                this.refresh_dependency().then(()=>{
                    resolve();
                })
            }catch(e){
                reject(e);
            }
        });
    }

    refresh(){
        this.refresh_dependency();
    }

    refresh_field(fieldname){
        return new Promise((resolve, reject)=>{
            try{
                if(fieldname && this.fields_dict[fieldname]){
                    this.fields_dict[fieldname].refresh();
                }
                resolve(this.fields_dict[fieldname]);
            }catch(e){
                reject(e);
            }
        });
    }

    refresh_fields(){
        return new Promise((resolve, reject)=>{
            try{
                for(var fieldname in this.fields_dict){
                    if(this.fields_dict[fieldname] && this.fields_dict[fieldname].refresh){
                        this.fields_dict[fieldname].refresh();
                    }
                }
                resolve(this);
            }catch(e){
                reject(e);
            }
        });
    }

    refresh_dependency(){
        return new Promise((resolve, reject)=>{
            try{
                var has_dep = false;

                for(var fkey in this.fields_list) {
                    var f = this.fields_list[fkey];
                    f.dependencies_clear = true;
                    if(f.df.depends_on || f.df.read_only_depends_on) {
                        has_dep = true;
                    }
                }

                if(!has_dep)return;
                // show / hide based on values
                for(var i=this.fields_list.length-1;i>=0;i--) {
                    var f = this.fields_list[i];
                    f.guardian_has_value = true;
                    if(f.df.depends_on || f.df.read_only_depends_on) {
                        // evaluate guardian

                        if(f.df.depends_on){
                            f.guardian_has_value = this.evaluate_depends_on_value(f.df.depends_on);
                        }

                        if(f.df.read_only_depends_on){
                            f.df.disable_field = this.evaluate_depends_on_value(f.df.read_only_depends_on);
                        }


                        // show / hide
                        if(f.guardian_has_value) {
                            if(f.df.hidden_due_to_dependency) {
                                f.df.hidden_due_to_dependency = false;
                                f.refresh();
                            }
                        } else {
                            if(!f.df.hidden_due_to_dependency) {
                                f.df.hidden_due_to_dependency = true;
                                f.refresh();
                            }
                        }
                    }
                }
                resolve(this);
            }catch(e){
                reject(e);
            }
        });
    }

    evaluate_depends_on_value(expression) {
		var out = null;
		var doc = this.get_values(true);

		if (!doc) {
			return;
		}

		var parent = this.frm ? this.frm.doc : null;

		if(typeof(expression) === 'boolean') {
			out = expression;

		} else if(typeof(expression) === 'function') {
			out = expression(doc);

		} else if(expression.substr(0,5)=='eval:') {
			try {
				out = eval(expression.substr(5));
				if(parent && parent.istable && expression.includes('is_submittable')) {
					out = true;
				}
			} catch(e) {
				frappe.throw(__('Invalid "depends_on" expression'));
			}

		} else if(expression.substr(0,3)=='fn:' && this.frm) {
			out = this.frm.script_manager.trigger(expression.substr(3), this.doctype, this.docname);
		} else {
			var value = doc[expression];
			if($.isArray(value)) {
				out = !!value.length;
			} else {
				out = !!value;
			}
		}

		return out;
    }
    
    set_df_property(fieldname, property, value){
        if(this.fields_dict[fieldname]){
            let field = this.fields_dict[fieldname];
        }
    }
};
