
frappe.ui.form.ControlButton = class BondButtonControl extends frappe.ui.form.ControlData{
	can_write() {
		// should be always true in case of button
		return true;
	}
	make_input() {
		let $input = this.get_wrapper()
						.appendTo(this.$wrapper.find(".input-main-wrapper"))
						.on("click", ()=> {
							this.onclick();
					});

		this.$input = $input.find("button");
		this.input = this.$input.get(0);
		this.set_input_attributes();
		this.has_input = true;
		this.toggle_label(false);
	}
	get_wrapper(){
		return $(`<div class="text-center mt-3 mt-md-4 mt-lxg-3 mt-xxl-5 mb-4 pt-2 pt-md-4">
					<button class="btn btn-dark btn-lg rounded-pill" value="${this.df.placeholder}">
						${this.df.placeholder}
					</button>
				</div>`);
	}
	onclick() {
		if(this.frm && this.frm.doc) {
			if(this.frm.script_manager.has_handlers(this.df.fieldname, this.doctype)) {
				this.frm.script_manager.trigger(this.df.fieldname, this.doctype, this.docname);
			} else {
				if (this.df.options) {
					this.run_server_script();
				}
			}
		}
		else if(this.df.click) {
			this.df.click();
		}
	}
	run_server_script() {
		// DEPRECATE
		var me = this;
		if(this.frm && this.frm.docname) {
			frappe.call({
				method: "runserverobj",
				args: {'docs': this.frm.doc, 'method': this.df.options },
				btn: this.$input,
				callback: function(r) {
					if(!r.exc) {
						me.frm.refresh_fields();
					}
				}
			});
		}
	}
	hide() {
		this.$input.hide();
	}
	set_input_areas() {
		super.set_input_areas();
		$(this.disp_area).removeClass().addClass("hide");
	}
	set_empty_description() {
		this.$wrapper.find(".help-box").empty().toggle(false);
	}
	set_label() {
		$(this.label_span).html("&nbsp;");
		this.$input && this.$input.html((this.df.icon ?
			('<i class="'+this.df.icon+' fa-fw"></i> ') : "") + __(this.df.label));
	}
}
