/*
    StepForm
*/
import "./animation";
import './step';
frappe.provide("flags");

frappe.ui.StepForm = class StepForm{

    constructor(args){
        $.extend(this, args);
        this.set_global_object();
        this.disable_links();
        this.disable_mouse_hover();
        this.disable_box_shadow_on_forms();
        this.set_search_params();
        this.apply_mobile_header_settings();
        this.make();
    }

    set_global_object(){
        window.formview = this;
    }

    setup_default_classes(){
        $("body").addClass("tooltip-width body-form-with-legal-copy");
        $("main").addClass("main-form-landing client-form main-form-with-legal-copy");
    }

    disable_links(){
        frappe.dom.disable_footer_links();
        frappe.dom.disable_navbar_links();
    }
    set_search_params(){
        this.searchParams = new URLSearchParams(window.location.search);
    }
    get_search_param(key){
        return this.searchParams.get(key);
    }
    make(){
        // don't freeze dom while loading the form
        this.$loading_screen_wrapper = $(".loading-screen-wrapper");
        this.$parent = $("main");
        this.parent  = this.$parent.get(0);
        frappe.request.flags.freeze_dom = false;
        this.doc = {};
        this.formmeta = frappe.boot.formdata.form;
        this.doctype = frappe.boot.formdata.doctype;
        this.files = {};
        this.tables = {};
        this.script_manager = {};
        this.path = {};
        this.url_params = this.get_params();
        this.steps = [];
        this.fields_dict = {};
        this.fields = [];
        this.steps_dict = {};
        this.sub_steps_dict = {};
        this.forms = [];
        this.sub_steps = [];
        this.primary_action_title = frappe.boot.formdata.form.primary_action;

        this.init_form = true;
        this.setup_progress();

        if(bond_assets.device.is_mobile_device()){
            this.$parent.addClass("main-mob-form-landing");
        }
        this.$parent.addClass("first-step-padding");
        this.setup_default_classes();

        if(this.form_controller){
            this.form_controller.frm = this;
        }
        this.make_animation_controller();
        this.animation_controller.play_form_start_animation().then(()=>{
            if(frappe.boot.formdata.path){
                this.handle_path(frappe.boot.formdata.path);
            }else{
                this.make_steps().then(()=>{
                    this.set_steps_background();
                    this.trigger_step_setup();
                    this.init_form  = true;
                    if(this.controller_resolve){
                        this.controller_resolve(this);
                    }
                });
            }
        });
    }

    is_one_sub_step_form_only(){
        let steps = frappe.boot.formdata.steps;
        if(steps.length === 1 && steps[0].sub_steps.length === 1){
            return true;
        }
        return false;
    }

    make_animation_controller(){
        this.animation_controller = new frappe.ui.form.AnimationController({
            frm: this,
            formmeta: this.formmeta
        });
    }

    set_steps_background(){
        this.animation_controller.set_steps_background_image();
    }

    setup_progress(){
        this.$steps_wrapper = $(`<div class="progress-wrap col-12 col-lg-11 col-xl-9">
                                    <div class="container-form container-progress">
                                        <div class="form-row justify-content-center align-items-end text-muted steps-container"></div>
                                    </div>
                                </div>`).appendTo(this.$parent);

        $(".steps-wrapper").addClass("bg-dark py-2").hide();

        if(bond_assets.device.is_mobile_device()){
            this.$steps_wrapper.removeClass("col-12 col-lg-11 col-xl-9");
        }

    }

    make_steps(is_path){

        return new Promise((resolve, reject)=>{

            var me = this;
            if(is_path){
                this.reset_path_area();
            }

            this.$steps_wrapper.show().attr("id", "progress-wrap");

            //this.$parent.removeClass("first-step-padding");

            $.each(frappe.boot.formdata.steps, (i, step)=>{

                step.next_step = null;
                step.prev_step = null;
                if(i < frappe.boot.formdata.steps.length){
                    if(i !== 0){
                        step.prev_step = frappe.boot.formdata.steps[i-1].df.fieldname;
                    }
                    // validate last step
                    if(i < frappe.boot.formdata.steps.length-1){
                        step.next_step = frappe.boot.formdata.steps[i+1].df.fieldname;
                    }
                }
                if(!this.fields_dict.hasOwnProperty(step.df.doctype_fieldname)){
                    this.fields_dict[step.df.doctype_fieldname] = {};
                }

                this.doc[step.df.doctype_fieldname] = {};
                this.steps_dict[step.df.fieldname] = this.init_step(i, step);
                // setup next and prev steps
                this.steps.push(this.steps_dict[step.df.fieldname]);
            });
            frappe.request.flags.freeze_dom = true;

            this.setup_sub_steps_navigation();

            resolve()
        });
            
    }

    init_step(idx, step){
        let _step = new frappe.ui.Step({
            frm: this,
            prev_step: step.prev_step,
            next_step: step.next_step,
            df: step.df,
            sub_steps: step.sub_steps,
            idx: idx+1,
            parent: this.parent,
        });

        return _step;
    }

    setup_sub_steps_navigation(){
        /*
            Setup the sub next and prev steps
        */
        $.each(this.sub_steps, (i, sub_step)=>{
            if(i < this.sub_steps.length){
                if(i < this.sub_steps.length-1){
                    sub_step.next_sub_step = this.sub_steps[i+1].df.fieldname;
                    sub_step.next_sub_step_obj = this.sub_steps[i+1];
                }
                // validate last step
                if(i > 0){
                    sub_step.prev_sub_step = this.sub_steps[i-1].df.fieldname;
                    sub_step.prev_sub_step_obj = this.sub_steps[i-1];
                }
            }
        });
    }

    handle_path(path){
        this.doc[path.df.doctype_fieldname] = {};
        this.fields_dict[path.df.doctype_fieldname] = {};
        this.path_controller = new frappe.ui.Step({
            frm: this,
            prev_step:null,
            next_step: null,
            df: path.df,
            sub_steps: path.sub_steps,
            idx: 1,
            parent: this.parent,
            is_path:true
        });
        if(this.path_page_handler){
            try{
                this.path_page_handler(this, this.path_controller);
            }catch(e){
                console.error(e);
            }
        }

    }
    is_last_sub_step(sub_step){
        if(this.sub_steps.indexOf(sub_step) == this.sub_steps.length-1){
            return true;
        }
        return false;
    }

    is_first_sub_step(sub_step){
        return this.sub_steps.indexOf(sub_step) == 0;
    }

    is_first_form_field(fieldobj){
        return this.fields.indexOf(fieldobj) == 0;
    }

    is_last_form_field(fieldobj, ignore_column_break=false, from_actions=false){
        let is_last_field = false;
        let last_field_idx = this.get_last_visible_field_idx();
        if(fieldobj && !ignore_column_break
            && (fieldobj.$wrapper.hasClass("column-break") || fieldobj.$wrapper.hasClass("sync-with-field-up-control"))){
            let cur_field_idx = this.fields.indexOf(fieldobj);
            let temp;
            for(var i=cur_field_idx; i<this.fields.length-1; i++){
                temp = this.fields[i+1];
                if(temp && temp.df.fieldname != fieldobj.df.fieldname
                    && i+1 == last_field_idx){
                    is_last_field = true;
                }
                if(temp && temp.$wrapper.attr("data-fieldname") != fieldobj.$wrapper.attr("data-fieldname")){
                    // only process the fields in the same column-break, and sync with up wrapper
                    break;
                }
            }
        }else{
            if(from_actions && fieldobj &&
                    (fieldobj.$wrapper.hasClass("column-break") || fieldobj.$wrapper.hasClass("sync-with-field-up-control"))){
                let fieldname = fieldobj.$wrapper.find("input, select").last().attr("data-fieldname");
                fieldobj = fieldobj.sub_step.form.fields_dict[fieldname];
            }

            is_last_field = this.fields.indexOf(fieldobj) == last_field_idx;
        }
        return is_last_field;
    }

    get_last_visible_field_idx(){
        let idx = 0;
        for(var i=this.fields.length-1; i>=0; i--){
            if(!this.fields[i].$wrapper.hasClass("hide-control")){
                idx = i;
                break;
            }
        }
        return idx;   
    }

    get_next_field(fieldobj){
        if(!fieldobj){
            return;
        }
        let fieldname = fieldobj.$wrapper.attr("data-fieldname");
        let idx = this.fields.indexOf(fieldobj);
        let nextfieldobj;
        for(var i=idx; i < this.fields.length; i++){
            nextfieldobj = this.fields[i+1];
            // this is to validate column break and sync with up condition
            // field isn't hidden
            if(nextfieldobj && !nextfieldobj.$wrapper.hasClass("hide-control")
                    && fieldname != nextfieldobj.$wrapper.attr("data-fieldname")){
                break;
            }
        }
        return nextfieldobj;
    }

    get_prev_field(fieldobj){
        if(!fieldobj){
            return;
        }
        let fieldname = fieldobj.$wrapper.attr("data-fieldname");
        let idx = this.fields.indexOf(fieldobj);
        let prevfieldobj;
        for(var i=idx; i>0; i--){
            prevfieldobj = this.fields[i-1];
            if(!prevfieldobj.$wrapper.hasClass("hide-control")
                && fieldname != prevfieldobj.$wrapper.attr("data-fieldname")){
                break
            }
        }
        return prevfieldobj;

    }

    reset_path_area(){
        //this.$parent.empty();
    }

    trigger(form, fieldobj){
        this._trigger_func(fieldobj.df.fieldname, form, fieldobj);
    }

    trigger_table_field(form, fieldobj, tablefield){
        let func = `${tablefield.df.fieldname}_${fieldobj.df.fieldname}`;
        this._trigger_func(func, form, fieldobj, tablefield);
    }

    trigger_table_field_on_render(form, fieldobj, tablefield){
        let func = `${tablefield.df.fieldname}_${fieldobj.df.fieldname}_render`;
        this._trigger_func(func, form, fieldobj, tablefield);
    }
    
    _trigger_func(func, form, fieldobj, tablefield=null){
        try{
            if(this.form_controller && this.form_controller[func]
                    && $.isFunction(this.form_controller[func])){
                this.form_controller[func](form, fieldobj, tablefield)
            }
        }catch(e){
            console.log(e);
        }
    }

    trigger_step_setup(){

        if(this.form_controller && this.form_controller.step_setup &&
                    $.isFunction(this.form_controller.step_setup)){
            this.form_controller.step_setup();
        }
    }

    trigger_refresh(step){

        if(this.form_controller && this.form_controller.refresh &&
                    $.isFunction(this.form_controller.refresh)){
            this.form_controller.refresh(step);
        }

        if(this.form_controller && this.form_controller[step.df.fieldname+"_refresh"] &&
                    $.isFunction(this.form_controller[step.df.fieldname+"_refresh"])){
            this.form_controller[step.df.fieldname+"_refresh"](step);
        }
    }
    set_value(fieldname, value, doctype){
        doctype = this.get_doctype(doctype);
        if(this.fields_dict[doctype]
            && this.fields_dict[doctype][fieldname]){
                this.doc[doctype][fieldname] = value;
                this.fields_dict[doctype][fieldname].refresh();
                this.fields_dict[doctype][fieldname].layout.refresh_dependency();
        }
    }
    set_input(fieldname, value, doctype){
        doctype = this.get_doctype(doctype);
        if(this.fields_dict[doctype]
            && this.fields_dict[doctype][fieldname]){
                this.doc[doctype][fieldname] = value;
                console.log(value);
                this.fields_dict[doctype][fieldname].set_input(value);
        }
    }
    set_df_property(fieldname, prop, prop_value, doctype){
        doctype = this.get_doctype(doctype);
        if(this.fields_dict[doctype]
            && this.fields_dict[doctype][fieldname]){
                this.fields_dict[doctype][fieldname].layout.set_df_property(fieldname, prop, prop_value);
        }
    }
    get_value(fieldname, doctype){
        doctype = this.get_doctype(doctype);
        if(this.fields_dict[doctype]
            && this.fields_dict[doctype][fieldname]){
            return this.doc[doctype][fieldname];
        }
        return '';
    }
    refresh_field(fieldname, doctype){
        doctype = this.get_doctype(doctype);
        if(this.fields_dict[doctype]
            && this.fields_dict[doctype][fieldname]){
            this.fields_dict[doctype][fieldname].refresh();
        }
    }
    refresh(doctype){
        let refresh_all = doctype?false:true;
        doctype = this.get_doctype(doctype);
        $.each(this.sub_steps, (idx, sub_step)=>{
            if(refresh_all){
                sub_step.form.refresh();
            }else if(sub_step.df.doctype_fieldname == doctype){
                sub_step.form.refresh();
            }
        });
    }
    get_field(fieldname, doctype){
        doctype = this.get_doctype(doctype)
        if(this.fields_dict[doctype] && this.fields_dict[doctype][fieldname]){
            return this.fields_dict[doctype][fieldname];
        }
    }
    get_doctype(doctype){
        if(!doctype) doctype = this.doctype; // set default doctype
        return frappe.scrub(doctype);
    }
    refresh_dependency(){
        let events = [];
        $.each(this.forms, (idx, form)=>{
            events.push(() => form.refresh_dependency());
        });
        frappe.run_serially(events);
    }
    save(){
        // implememt this method in subclass
    }

    save_form($btn, e){
        // implement this method in subclass
    }
    show_loading_screen(title, msg){
        this.animation_controller.play_form_finish_animation();
    }
    hide_loading_screen(){
        this.animation_controller.stop_form_finish_animation();
    }
    is_all_quotes_loaded(service_type, service_name){
        // implement this method in subclass
    }

    disable_box_shadow_on_forms(){
        $("header").css("box-shadow", 'none');
    }

    apply_mobile_header_settings(){
        //$(`<div class="mobile-header-btn"></div>`).prependTo("header .mobile-header");
        //$("header").addClass("header--car-form bg-dark ");
    }

    disable_mouse_hover(){
        $(".navbar-brand").attr("onmouseover", "this.style.cursor='default';");
    }

    get_params(){
        let params = {};
        try{
            let search = new URLSearchParams(window.location.search);
            search.forEach((val, key)=>{
                params[key] = val;
            });
        }catch(e){

        }
        return params;
    }

    enable_schedule_button(reference_doc){
        if(this.animation_controller && this.animation_controller.$schedule_appointment_btn){
            this.animation_controller.$schedule_appointment_btn.removeClass("disabled");
        }
    }

    handle_awesomplete_bugs(){
        this.$parent.find(".control-input-field > .awesomplete").find(".awesomplete").css("width", "100%");
    }
};
frappe.ui.DesktopStepForm = class DesktopStepForm extends frappe.ui.StepForm{

    constructor(args){
        super(args);
    }

    make(){
        super.make();
    }

    make_steps(is_path){

        var me = this;
        if(is_path){
            this.reset_path_area();
        }

        this.$steps_wrapper.show().attr("id", "progress-wrap");

        //this.$parent.removeClass("first-step-padding");

        $.each(frappe.boot.formdata.steps, (i, step)=>{

            step.next_step = null;
            step.prev_step = null;
            if(i < frappe.boot.formdata.steps.length){
                if(i !== 0){
                    step.prev_step = frappe.boot.formdata.steps[i-1].df.fieldname;
                }
                // validate last step
                if(i < frappe.boot.formdata.steps.length-1){
                    step.next_step = frappe.boot.formdata.steps[i+1].df.fieldname;
                }
            }
            if(!this.fields_dict.hasOwnProperty(step.df.doctype_fieldname)){
                this.fields_dict[step.df.doctype_fieldname] = {};
            }

            this.doc[step.df.doctype_fieldname] = {};
            this.steps_dict[step.df.fieldname] = new frappe.ui.Step({
                frm: me,
                prev_step: step.prev_step,
                next_step: step.next_step,
                df: step.df,
                sub_steps: step.sub_steps,
                idx: i+1,
                parent: this.parent,
            });
            // setup next and prev steps
            this.steps.push(step);
        });
        frappe.request.flags.freeze_dom = true;

        this.setup_sub_steps_navigation();   
    }

    setup_sub_steps_navigation(){
        /*
            Setup the sub next and prev steps
        */
        $.each(this.sub_steps, (i, sub_step)=>{
            if(i < this.sub_steps.length){
                if(i < this.sub_steps.length-1){
                    sub_step.next_sub_step = this.sub_steps[i+1].df.fieldname;
                    sub_step.next_sub_step_obj = this.sub_steps[i+1];
                }
                // validate last step
                if(i > 0){
                    sub_step.prev_sub_step = this.sub_steps[i-1].df.fieldname;
                    sub_step.prev_sub_step_obj = this.sub_steps[i-1];
                }
            }
        });
    }
};

frappe.ui.make_form_controller = function(parent_cls, child_cls){
    return new Promise((resolve, reject)=>{
        new parent_cls({
            form_controller: new child_cls(),
            controller_resolve: resolve,
            controller_reject: reject
        });
    });
};