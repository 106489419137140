
frappe.ui.form.ControlMultiSelect = class BondControlMultiSelect extends frappe.ui.form.ControlSelect{
	static html_element = 'select';
	make_input() {

		if(this.$input){
			return;
		}

		this.$main_select = $(this.get_select_wrapper(this.df)).appendTo(this.$wrapper.find(".input-main-wrapper"));
		this.$main_select.addClass("main-select");
		this.$add_btn = $(`<button class="mt-3 mt-sm-4 btn btn-outline-warning rounded-pill btn-lg">
							Add Another +
						</button>`).appendTo(this.$wrapper.find(".input-main-wrapper"));

		this.$remove_btn = $(`<button class="mt-3 mt-sm-4 btn btn-outline-dark btn-lg rounded-pill mx-1 mb-1">
								Remove -
							</button>`).appendTo(this.$wrapper.find(".input-main-wrapper"));

		this.$add_btn.attr("disabled", true);
		this.$remove_btn.attr("disabled", true);
		this.$input = this.$wrapper.find(`select[data-fieldname='${this.df.fieldname}']`);

		//this.only_input = true;
		this.set_options(this.df.options);
		this.$input.niceSelect();
		this.$input.next()
				.attr("data-fieldname", this.df.fieldname);

		this.bind_change_event(this.$input);
		this.bind_change_event1();
	}

	get_select_wrapper(df){
		return `<div class="col-12 multiselect">
					<div class="control-tooltip"></div>
					<select class="js-next-field-select wide nice-select-custom nice-select-update text-muted"
						data-fieldname="${df.fieldname}">
					</select>
				</div>`;
	}

	set_options(options, is_new_select) {
		// reset options, if something new is set
		let $input = this.$wrapper
						.find(`select[data-fieldname='${this.df.fieldname}']`).last();

		if(typeof options==="string") {
			options = options.split("\n");
		}

		// nothing changed
		if (is_null(is_new_select) && JSON.stringify(options) === this.last_options) {
			return;
		}

		if(is_null(is_new_select)){
			// set as default options
			this.df.options = options;
		}
		this.last_options = JSON.stringify(options);
		window.$input = $input;
		var selected = options[0];
		$input.empty().add_options(options || []);
		if(selected) {
			$input.val(selected.value?selected.value: selected);
		}
		$input.niceSelect("update");

	}

	set_formatted_input(value, e) {
		// refresh options first - (new ones??)
		if(value==null) value = '';

		if(e){
			value = e.target.value;
		}

		//this.$input.val(value);

		if(!is_null(value)){
			this.$input.next().removeClass("text-muted").addClass("select-active");
		}else{
			this.$input.next().removeClass("select-active").addClass("text-muted");
		}

		if(value){
			this.$add_btn.attr("disabled", false);
		}
	}

	_set_values(){
		this.values = [];
		$.each(this.$wrapper.find(`select[data-fieldname='${this.df.fieldname}']`), (idx, $input)=>{
			this.values.push($input.value);
		});

		if(this.df.length && this.values.length >= this.df.length){
			this.$add_btn.hide();
			this.$remove_btn.hide();
		}

		this.value = this.values.join(",");

		frappe.run_serially([
			()=>this.parse_validate_and_set_in_model(this.value),
			()=>this.set_icons(this.value),
		]);
	}

	get_input_value(){
		return this.value;
	}

	add_another_select(){
		var me = this;
		let $input = $(this.get_select_wrapper(this.df))
								.insertBefore(this.$add_btn);

		$input.addClass("mt-3 mt-sm-4");
		$input.find("select").niceSelect();
		$input.find("select").next()
					.attr("data-fieldname", this.df.fieldname);

		this.set_options(this.df.options, true);

		$input.find("select").on("change", this.df.change || this.df.onchange || function(e){
			$input.find("select").next().removeClass("text-muted").addClass("select-active");
			me.$add_btn.attr("disabled", false);
			me._set_values();
		});

	}

	remove_last_select(){
		let last_select = this.$wrapper.find(".input-main-wrapper .multiselect").last();
		if(!last_select.hasClass("main-select")){
			last_select.fadeOut(600);
			last_select.remove();
			if(this.$wrapper.find(".input-main-wrapper .multiselect").last().hasClass("main-select")){
				this.$remove_btn.attr("disabled", true);
				this.$add_btn.attr("disabled", false);
			}
		}else{
			this.$remove_btn.attr("disabled", true);
			this.$add_btn.attr("disabled", false);
		}
	}

	bind_change_event1(){

		this.$add_btn.on("click", (e)=>{
			e.preventDefault();
			this.add_another_select();
			this.$remove_btn.attr("disabled", false);
			this.$add_btn.attr("disabled", true);
			return false;
		});

		this.$remove_btn.on("click", (e)=>{
			e.preventDefault();
			this.remove_last_select();
			this._set_values();
			return false;
		});
	}

	bind_change_event($input){
		var me = this;
		$input.on("change", function(e){
			me._set_values();
		});
	}
};

// add <option> list to <select>
(function($) {
	$.fn.add_options = function(options_list) {
		// create options
		for(var i=0, j=options_list.length; i<j; i++) {
			var v = options_list[i];
			var value = null;
			var label = null;
			if (!is_null(v)) {
				var is_value_null = is_null(v.value);
				var is_label_null = is_null(v.label);
				var is_disabled = Boolean(v.disabled);

				if (is_value_null && is_label_null) {
					value = v;
					label = __(v);
				} else {
					value = is_value_null ? "" : v.value;
					label = is_label_null ? __(value) : __(v.label);
				}
			}
			if(i===0){
				is_disabled=true;
			}
			$('<option>').html(cstr(label))
				.attr('value', value)
				.prop('disabled', is_disabled)
				.appendTo(this);
		}
		// select the first option
		this.selectedIndex = 0;
		return $(this);
	};
	$.fn.set_working = function() {
		this.prop('disabled', true);
	};
	$.fn.done_working = function() {
		this.prop('disabled', false);
	};
})(jQuery);