/*
    Scanning field
*/

frappe.ui.form.ControlScanning = class BondControlScanning extends frappe.ui.form.ControlInput{
	make_input() {
		var me = this;
        this.$upload_btn = $(`<div class="text-center col-12">
            <label class="btn btn-dark btn-lg rounded-pill">
                ${this.df.placeholder}
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                    <g><g>
                        <g>
                            <path fill="currentColor" d="M3.49 1a.355.355 0 1 1 0 .711H1.71V3.49a.355.355 0 1 1-.711 0V1.711C1 1.32 1.319 1 1.711 1z"/>
                            <path fill="currentColor" stroke="currentColor" stroke-miterlimit="20" stroke-width=".7" d="M3.49 1a.355.355 0 1 1 0 .711H1.71V3.49a.355.355 0 1 1-.711 0V1.711C1 1.32 1.319 1 1.711 1z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M12.38 3.49a.355.355 0 1 1-.71 0V1.71H9.89a.355.355 0 1 1 0-.711h1.779c.392 0 .71.319.71.711z"/>
                            <path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width=".7" d="M12.38 3.49a.355.355 0 1 1-.71 0V1.71v0H9.89a.355.355 0 1 1 0-.711h1.779c.392 0 .71.319.71.711z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M1 9.891a.355.355 0 1 1 .711 0v1.778H3.49a.355.355 0 1 1 0 .712H1.711A.712.712 0 0 1 1 11.669z"/>
                            <path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width=".7" d="M1 9.891a.355.355 0 1 1 .711 0v1.778H3.49a.355.355 0 1 1 0 .712H1.711A.712.712 0 0 1 1 11.669z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M12.38 9.891a.355.355 0 1 0-.71 0v1.778H9.89a.355.355 0 1 0 0 .712h1.779a.712.712 0 0 0 .71-.712z"/>
                            <path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width=".7" d="M12.38 9.891a.355.355 0 1 0-.71 0v1.778H9.89a.355.355 0 1 0 0 .712h1.779a.712.712 0 0 0 .71-.712z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M3.024 7.046c-.136 0-.245-.159-.245-.356 0-.196.11-.355.245-.355h7.333c.135 0 .245.159.245.355 0 .197-.11.356-.245.356z"/>
                            <path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width=".7" d="M3.024 7.046c-.136 0-.245-.159-.245-.356 0-.196.11-.355.245-.355h7.333c.135 0 .245.159.245.355 0 .197-.11.356-.245.356z"/>
                        </g>
                    </g></g>
                </svg>
                <input type="file" accept="image/*" capture="camera" style="display: none;" data-fieldname='${this.df.fieldname}'>
            </label>
        </div>`).appendTo(this.$wrapper.find(".input-main-wrapper"));

        this.$upload_btn.find("input").on("change", (e)=>{
            e.preventDefault();
            this.on_attach_click(e);
        });

		this.$wrapper.find(".field-info")
				.addClass("text-center");
		this.$wrapper.find(".field-description")
				.addClass("text-center");
		if(this.df.only_mobile){
			this.$wrapper.addClass("d-lg-none hide-control");
		}

        this.$input = this.$upload_btn.find("button");
		this.input = this.$input.get(0);
		//this.set_input_attributes();
		this.has_input = true;

		//frappe.utils.bind_actions_with_object(this.$value, this);
		this.toggle_reload_button();
	}
	set_description(description){
		this._super(description);
		if(this.$description){
			this.$description
				.addClass("text-muted")
				.removeClass("text-left");
			
		}
	}
	clear_attachment() {
		var me = this;
		if(this.frm) {
			me.parse_validate_and_set_in_model(null);
			me.refresh();
			me.frm.attachments.remove_attachment_by_filename(me.value, function() {
				me.parse_validate_and_set_in_model(null);
				me.refresh();
				me.frm.doc.docstatus == 1 ? me.frm.save('Update') : me.frm.save();
			});
		} else {
			this.dataurl = null;
			this.fileobj = null;
			this.set_input(null);
			this.parse_validate_and_set_in_model(null);
			this.refresh();
		}
	}
	reload_attachment() {
		if (this.file_uploader) {
			this.file_uploader.uploader.upload_files();
		}
	}
	on_attach_click(e) {

        const files = e.target.files;
        let file = null;
        for(var i=0;i<files.length;i++){
            file = files[i];
            if(file.type  && !file.type.startsWith("image/")){
                // file not supported
            }
            this.read_file(file);
        }

		//this.set_upload_options();
		//this.file_uploader = new frappe.ui.FileUploader(this.upload_options);
	}
    read_file(file){
        const reader = new FileReader();
        reader.addEventListener('load', (e)=>{
            this.value = e.target.result;
			this.parse_validate_and_set_in_model(this.value, e);
			this.set_icons(this.value);
        });
        reader.readAsDataURL(file);

    }
    validate(value){
        return value;
    }
	set_upload_options() {
		let options = {
			allow_multiple: false,
			on_success: file => {
				this.on_upload_complete(file);
				this.toggle_reload_button();
			}
		};

		if (this.frm) {
			options.doctype = this.frm.doctype;
			options.docname = this.frm.docname;
		}

		if (this.df.options) {
			Object.assign(options, this.df.options);
		}
		this.upload_options = options;
	}

	set_input(value, dataurl) {
        // don't set value;
	}

	get_value() {
		return this.value || null;
	}

    bind_change_event(){
        // no need to find change event
    }
	toggle_reload_button() {
		//this.$value.find('[data-action="reload_attachment"]')
		//	.toggle(this.file_uploader && this.file_uploader.uploader.files.length > 0);
	}
}
