import 'frappe/public/js/frappe/form/controls/base_control';

frappe.ui.form.Control = class BondControl extends frappe.ui.form.Control{
	constructor(opts) {
		super(opts);
		this.add_custom_properties();
		$.extend(this, opts);
		// if developer_mode=1, show fieldname as tooltip
		if(frappe.boot.user && frappe.boot.user === "Administrator" && this.$wrapper) {
			this.$wrapper.attr("title", __(this.df.fieldname));
		}

		if(this.render_input) {
			this.refresh();
		}
	}
	set perm(_perm) {
		//console.error("Setting perm on controls isn't supported, update form's perm instead");
	}
	make() {
		this.make_wrapper();
		if(!this.$wrapper.attr("data-fieldtype")){
			this.$wrapper
				.attr("data-fieldtype", this.df.fieldtype)
				.attr("data-fieldname", this.df.fieldname);
			this.wrapper = this.$wrapper.get(0);
			this.wrapper.fieldobj = this; // reference for event handlers
		}
	}

	make_wrapper() {
		this.$wrapper = $("<div class='frappe-control'></div>").appendTo(this.parent);

		// alias
		this.wrapper = this.$wrapper;
	}

	toggle(show) {
		this.df.hidden = show ? 0 : 1;
		this.refresh();
	}

	// returns "Read", "Write" or "None"
	// as strings based on permissions
	get_status(explain) {
		if (this.df.get_status) {
			return this.df.get_status(this);
		}

		if((!this.doctype && !this.docname) || this.df.parenttype === 'Web Form') {
			// like in case of a dialog box
			if (cint(this.df.hidden)) {
				// eslint-disable-next-line
				if(explain) console.log("By Hidden: None");
				return "None";

			} else if (cint(this.df.hidden_due_to_dependency)) {
				// eslint-disable-next-line
				if(explain) console.log("By Hidden Dependency: None");
				return "None";

			} else if (cint(this.df.read_only)) {
				// eslint-disable-next-line
				if(explain) console.log("By Read Only: Read");
				return "Read";

			}

			return "Write";
		}

		var status = frappe.perm.get_field_display_status(this.df,
			frappe.model.get_doc(this.doctype, this.docname), this.perm || (this.frm && this.frm.perm), explain);

		// hide if no value
		if (this.doctype && status==="Read" && !this.only_input
			&& is_null(frappe.model.get_value(this.doctype, this.docname, this.df.fieldname))
			&& !in_list(["HTML", "Image", "Button"], this.df.fieldtype)) {

			// eslint-disable-next-line
			if(explain) console.log("By Hide Read-only, null fields: None");
			status = "None";
		}

		return status;
	}
	add_custom_properties(){
		this.custom_df = {};
		if(this.df.custom_df){
			try{
				this.custom_df = JSON.parse(this.df.custom_df);
				$.extend(this.df, this.custom_df);
			}catch(e){
				console.log(e)
			}
		}
	}
	refresh() {
		this.disp_status = this.get_status();
		
		this.$wrapper
			&& this.$wrapper.toggleClass("hide-control", this.disp_status=="None")
			&& this.refresh_input
			&& this.refresh_input();

		// check if parent field sync with this field
		// and this field is hidden
		if(this.df.hidden_due_to_dependency === true
			&& this.$wrapper.prev()
			&& this.$wrapper.prev().attr("data-fieldname")
			&& this.layout.fields_dict[this.$wrapper.prev().attr("data-fieldname")].df.sync_with_field_bottom){
				this.$wrapper.prev().addClass("mb-2 mb-sm-4 pb-4");
		}else if(this.df.hidden_due_to_dependency === false
			&& !this.$wrapper.hasClass("hide-control")
			&& this.$wrapper.prev()
			&& this.$wrapper.prev().attr("data-fieldname")
			&& this.layout.fields_dict[this.$wrapper.prev().attr("data-fieldname")].df.sync_with_field_bottom){
			this.$wrapper.prev().removeClass("mb-2 mb-sm-4 pb-4");
		}

		var value = this.get_value();
		this.show_translatable_button(value);

	}
	show_translatable_button(value) {
		// Disable translation non-string fields or special string fields
		if (!frappe.model
			|| !this.frm
			|| !this.doc
			|| !this.df.translatable
			|| !frappe.model.can_write('Translation')
			|| !value) return;

		// Disable translation in website
		if (!frappe.views || !frappe.views.TranslationManager) return;

		// Already attached button
		if (this.$wrapper.find('.clearfix .btn-translation').length) return;

		const translation_btn =
			`<a class="btn-translation no-decoration text-muted" title="${__('Open Translation')}">
				<i class="fa fa-globe"></i>
			</a>`;

		$(translation_btn)
			.appendTo(this.$wrapper.find('.clearfix'))
			.on('click', () => {
				if (!this.doc.__islocal) {
					new frappe.views.TranslationManager({
						'df': this.df,
						'source_name': value,
						'target_language': this.doc.language,
						'doc': this.doc
					});
				}
			});

	}
	get_doc() {
		return this.doctype && this.docname
			&& locals[this.doctype] && locals[this.doctype][this.docname] || {};
	}
	get_model_value() {
		if(this.doc) {
			return this.doc[this.step.df.doctype_fieldname][this.df.fieldname];
		}
	}
	set_value(value) {
		return this.validate_and_set_in_model(value);
	}
	parse_validate_and_set_in_model(value, e) {
		return new Promise((resolve, reject)=>{
			if(this.parse) {
				value = this.parse(value);
			}
			this.validate_and_set_in_model(value, e).then(()=>{
				resolve(value);
			});
		});
	}
	validate_and_set_in_model(value, e) {
		return new Promise((resolve, reject)=>{
			var me = this;
			if(this.inside_change_event) {
				return resolve();
			}
			this.inside_change_event = true;
			var set = function(value) {
				me.inside_change_event = false;
				return frappe.run_serially([
					() => {
						me.set_model_value(value, e)
					},
					() => {
						me.set_mandatory && me.set_mandatory(value, e);
	
						if(me.df.change || me.df.onchange) {
							// onchange event specified in df
							return (me.df.change || me.df.onchange).apply(me, [e]);
						}
					},
					() =>{
						resolve();
					}
				]);
			};
	
			value = this.validate(value, e);
			if (value && value.then) {
				// got a promise
				return value.then((value) =>{
					return resolve(set(value));
				});
			} else {
				// all clear
				return resolve(set(value));
			}
		});

	}
	get_value(fieldname) {
		if(this.get_status()==='Write') {
			return this.get_input_value ?
				(this.parse ? this.parse(this.get_input_value()) : this.get_input_value()) :
				undefined;
		} else {
			return this.value || undefined;
		}
	}
	set_model_value(value, e) {
		this.set_input(value, e);
		if(this.is_multiselect_table_field){
			// Don't set values in model when it's child table.
			return;
		}

		if(this.frm && this.step && this.step.is_path){
			this.frm.path[this.df.fieldname] = value;
		}
		else if(this.frm && this.step) {
			this.doc[this.step.df.doctype_fieldname][this.df.fieldname] = value;
		}


		if(this.step && this.step.frm && this.step.frm.trigger){
			this.step.frm.trigger(this.sub_step.form, this);
		}
		return Promise.resolve();
	}
	set_focus() {
		if(this.$input) {
			this.$input.get(0).focus();
			return true;
		}
	}
	get_fullfill_status(from_tab){
		let flag = true;
		let selector = "input, select";
		if(from_tab)
			selector = `input[data-fieldname='${this.df.fieldname}'], select[data-fieldname='${this.df.fieldname}']`;
		if(this.$wrapper.hasClass("column-break") || this.$wrapper.hasClass("sync-with-field-up")){
			$.each(this.$wrapper.find(selector), (e, $input)=>{
				let _fieldobj = this.sub_step.form.fields_dict[$input.getAttribute("data-fieldname")];
				if(_fieldobj.df.reqd && is_null($input.value)){
					flag = false;
				}
			});
		}else if(is_null(this.$wrapper.find(selector).val())){
			flag = false;
		}

		return flag;
	}
	enable_next_field(fieldname){
		let field = this;
		if(!is_null(fieldname)){
			field = this.sub_step.form.fields_dict[fieldname];
		}
		this.sub_step.form._enable_next_field(field);
	}
	show_error_msg(error_message){
		let $wrapper = this.$wrapper.find(`.invalid-tooltip[data-fieldname='${this.df.fieldname}']`);
		$wrapper.removeClass("d-none");
		$wrapper.addClass("d-block");
		$wrapper.find(".error-message").text(error_message);
		let $fieldwrapper = this.$wrapper.find(`.control-input-field-wrapper[data-fieldname='${this.df.fieldname}']`)
			.addClass("invalid-field");
		
		if($fieldwrapper.parent().next().hasClass("sync-with-field-up")){
			$fieldwrapper.parent().next().addClass("mt-5")
		}
	}
	hide_error_msg(){
		this.$wrapper.find(`.invalid-tooltip[data-fieldname='${this.df.fieldname}']`)
					.addClass("d-none")
					.removeClass("d-block mb-5")
					.find(".error-message").text();
		let $fieldwrapper = this.$wrapper.find(`.control-input-field-wrapper[data-fieldname='${this.df.fieldname}']`)
					.removeClass("invalid-field")
		
		if($fieldwrapper.parent().next().hasClass("sync-with-field-up")){
			$fieldwrapper.parent().next().removeClass("mt-5");
		}
				
	}
}
