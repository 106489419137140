
frappe.ui.form.ControlDataRange = class BondControlDataRange extends frappe.ui.form.ControlData{

	make_wrapper(){

		super.make_wrapper();
		this.$wrapper.find(".input-main-wrapper")
			.removeClass("form-row")
			.addClass("row align-items-center");

		this.$wrapper.find(".field-info")
			.find(".field-description")
			.addClass("font-description m-0 text-center text-muted");

		this.$wrapper.find(".description-and-title")
			.addClass("col-12");

		if(this.df.hide_icons){
			this.$wrapper.find(".field-info")
				.removeClass("mb-sm-4")
		}

	}

	make_input() {
		if(this.$input) return;

		this.set_input_wrapper(this.df);

		if(this.df.sub_title){
			let $title_wrapper = this.$input.find(".title-and-subtitle");
			$(`<p class="font-tab m-0 text-muted" style="line-height: 1">${this.df.sub_title}</p>`)
				.appendTo(this.$description_and_title);
		}

		this.$buttons = this.$input.find(`button`);
		this.$input = this.$input.find(`input`);
		this.input = this.$input.get(0);
		this.has_input = true;
		this.bind_change_event();
		this.bind_change_event1();

		// somehow this event does not bubble up to document
		// after v7, if you can debug, remove this
	}

	set_input_wrapper(df){

		this.$input = $(`<div class="col-6 data-range-wrapper" data-fieldname='${df.fieldname}'>
			<div class="input-group input-group input-group-lg border align-items-center bg-white px-2 px-sm-3 rounded-16">
				<div class="input-group-prepend">
					<button type="button" class="btn-round minus-btn" data-fieldname='${df.fieldname}'></button>
				</div>
				<input type="number" class="form-control border-0 text-center px-0" data-fieldname='${df.fieldname}' value=0 min="0" maxlength="3">
				<div class="input-group-prepend">
					<button type="button" class="btn-round plus-btn" data-fieldname='${df.fieldname}'></button>
				</div>
				<div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname='${this.df.fieldname}'>
					<div class="d-flex align-items-center justify-content-center">
						<div class="mr-2 pb-1">
							<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
						</div>
						<div class="text-left error-message"></div>
					</div>
				</div>
			</div>
		</div>`).appendTo(this.$wrapper.find(".input-main-wrapper"));


		this.$description_and_title = $(`<div class="col-6 title-and-subtitle">
			<p class="font-description m-0" style="line-height: 1">${df.options}</p>
		</div>`).appendTo(this.$wrapper.find(".input-main-wrapper"));

		this.sync_with_field_up(this.$input);
		this.sync_with_field_up(this.$description_and_title);
	}

	bind_change_event1(){

		let me = this;
		this.$buttons.on("click", this.change || function(e){

			let value = cint(me.$input.val());
			if($(this).hasClass("minus-btn")){
				 value -= 1;
			}else{
				value += 1;
			}
			value =  value < 0?0:value;

			me.$input.val(value);
			me.$input.change();
			return false;
		})

	}

	validate(v) {
		return cint(v);
	}

	set_input(value){
		value = cint(value);
		this.$input.val(value);
		this.value = value;
	}

	effective_date(){

	}
	choose_a_date(form, field){
		if(form.get_value("choose_a_date")){

		}
	}
}
