
frappe.ui.form.ControlCheck = class BondControlCheck extends frappe.ui.form.ControlData{
	static input_type = "checkbox";
	make_input() {
		//this._super();

		if(this.$input){
			return
		}
		let $wrapper = $(`<div class="col-12 mb-4">
							<div class="btn-group-toggle btn-group-lg d-flex justify-content-center w-100 checkbox-wrapper"
								data-toggle="buttons">
							</div>
						</div>`).appendTo(this.$wrapper.find(".input-main-wrapper")).find(".checkbox-wrapper");
		
		if(!bond_assets.device.is_mobile_device()){
			//$wrapper.parent().addClass("pl-1");
		}
		this.sync_with_field_up($wrapper);
		
		this.$wrapper.find(".input-main-wrapper")
			.removeClass("form-row")
			.addClass("row");
		
		let $input = null;
		if(this.df.is_radio){
			$input = this.get_radio_wrapper(this.df);
		}else{
			$input = this.get_checkbox_wrapper(this.df);
		}

		this.$input = $($input).appendTo($wrapper);

		if(this.df.is_radio){
			if(bond_assets.device.is_mobile_device() && this.df.use_rows_on_mobile){
				this.$wrapper.find(".checkbox-wrapper").addClass("flex-wrap justify-content-between w-100");
			}else{
				if(cstr(this.df.options).split("\n").length >= 3){
					this.$wrapper.find(".checkbox-wrapper")
						.addClass("flex-wrap justify-content-between w-100")
						.removeClass("d-flex");
					
					this.$wrapper.find("label").addClass("w-100");
					this.$wrapper.find("label:not(:first)").addClass("mt-sm-4 mt-4");
				}
			}
		}else{
			$wrapper.removeClass("btn-group-toggle btn-group-lg")
				.removeAttr("data-toggle");
			
		}

		this.set_input_attributes();
		this.input = this.$input;
		this.has_input = true;
		this.bind_change_event();
		this.$input.removeClass("form-control");

	}
	get_checkbox_wrapper(df){
		return `<div class="btn-group-toggle btn-group-lg w-100" data-toggle="buttons">
					<label class="d-flex justify-content-between align-items-center btn btn-outline-warning w-auto"
						data-fieldname="${df.fieldname}">
						<input type="checkbox" class="input-area js-next-field-checkbox" data-fieldname='${df.fieldname}'>
						<span class="label-placeholder helvetica-font">${df.placeholder}</span>
						<abbr title="required" aria-label="required"></abbr>
						${this.get_input_icon()}
					</label>
				</div>`;
	}

	get_radio_wrapper(df){
		let options = cstr(df.options).split("\n");
		let temp = []
		let custom_cls = "";
		$.each(options, (idx, opt)=>{
			custom_cls = ""
			if(bond_assets.device.is_mobile_device() && this.df.use_rows_on_mobile){
				custom_cls = "w-100";
				if(idx !== 0){
					custom_cls = "w-100 mt-3";
				}
			}else if(options.length == 2 && idx == 0){
				custom_cls = "mr-3"
			}
			temp.push(`<label class="d-flex justify-content-between align-items-center btn btn-outline-warning ${ custom_cls }"
							data-fieldname="${df.fieldname}">
							<input type="radio" name="${df.fieldname}" value="${opt}" data-fieldname='${df.fieldname}'>
							<span class="label-placeholder  helvetica-font">${__(opt)}</span>
							<abbr title="required" aria-label="required"></abbr>
							${this.get_input_icon()}
						</label>`);
		});
		return temp.join("\n");
	}

	get_input_icon(){
		return `<span class="circle-icon">
			<svg id="Star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.256" height="13.183" viewBox="0 0 13.256 13.183">
				<defs>
					<clipPath id="clip-path">
					<rect id="Прямоугольник_5179" data-name="Прямоугольник 5179" width="13.256" height="13.183" transform="translate(0 0)" fill="none"/>
					</clipPath>
				</defs>
				<g id="Сгруппировать_36839" data-name="Сгруппировать 36839" transform="translate(0 0)" clip-path="url(#clip-path)">
					<path id="Контур_92589" data-name="Контур 92589" d="M6.154.341a.5.5,0,0,1,.948,0l1.29,3.837a.5.5,0,0,0,.461.341l3.917.105a.5.5,0,0,1,.3.889L9.919,8.052a.5.5,0,0,0-.167.525l1.121,3.97a.5.5,0,0,1-.774.541l-3.177-2.3a.5.5,0,0,0-.586,0l-3.177,2.3a.5.5,0,0,1-.774-.541l1.121-3.97a.5.5,0,0,0-.167-.525L.186,5.513a.5.5,0,0,1,.3-.889L4.4,4.519a.5.5,0,0,0,.461-.341Z" fill="#fff"/>
				</g>
			</svg>
		</span>`;
	}
	get_input_value() {

		let value = null;
		if(this.df.is_radio){
			value =  this.$wrapper.find(`input[name='${this.df.fieldname}']:checked`).val();

		}else{
			value = this.$wrapper.find(`input[data-fieldname='${this.df.fieldname}']`)
													.is(":checked")?1:0;
		}
		return value;

	}
	validate(value) {
		return value;
	}
	set_input(value) {

		let $input = this.$wrapper.find(`input[data-fieldname='${this.df.fieldname}']`);
		if($input.attr("type") == "radio"){
			if(is_null(value)){
				$input.prop("checked", false);
				$input.parent().removeClass('focus active');
			}else{
				$.each(this.$wrapper.find(`input[data-fieldname='${this.df.fieldname}']`), (idx, input)=>{
					if(input.value == value){
						$(input).parent().addClass("focus active").trigger("click", [true]);
					}else{
						$(input).parent().removeClass("focus active");
					}
				});
			}

		}else if($input.attr("type") === "checkbox"){
			
			$input.prop("checked", value===1?true:false);
			if(!$input.is(":checked")){
				$input.parent().removeClass('focus active');
			}else{
				$input.parent().addClass("focus active");
			}
		}

		this.last_value = value;
		this.set_mandatory(value);
		this.set_disp_area(value);
	}

	bind_change_event() {
		var me = this;
		me.values = {};

		this.$input.find("input").on("change", function(e) {
			let value = undefined;
			if($(this).attr("type") === "checkbox"){
				value = $(this).is(":checked")?1:0;
			}else{
				value = $(this).val();
			}
			me.values[$(this).attr("data-fieldname")] = value;

			me.parse_validate_and_set_in_model(value, e);

			if(!me.df.discard_icons){
				me.set_icons(value);
			}
		});

	}
	set_icons(value){
		if(this.icons_updated){
			return;
		}else{
			this.$icons.find(".inactive").addClass("d-none");
			this.$icons.find(".active").removeClass("d-none");
		}
		this.icons_updated = true;
	}

	sync_with_field_bottom(){
		super.sync_with_field_bottom();
		this.$wrapper.find(".checkbox-wrapper")
			.removeClass("mb-3 mb-sm-4");
	}

	clear_checked_items(){
		this.$wrapper.find("input").prop("checked", false);
	}

	set_field_placeholder(placeholder){
		this.$wrapper.find(`label[data-fieldname='${this.df.fieldname}']`)
			.find(".label-placeholder").text(placeholder);
	}
}
