
frappe.ui.form.ControlHTML = class BondControlHTML extends frappe.ui.form.ControlInput{
	make() {
		this._super();
		this.limit = 5;
		if(this.custom_df && this.custom_df.limit){
			this.limit = this.custom_df.limit;
		}

		this.disp_area = this.wrapper;
	}

	make_wrapper() {

		if(this.df.column_break || this.df.sync_with_field_up){
			this.$wrapper = $(this.parent)
				.find(".js-form-field").last();
			if(!this.$wrapper.hasClass("sync-with-field-up-control")){
				this.$wrapper.addClass("sync-with-field-up-control");
			}
		}else{
			this.$wrapper = $(`<div class="js-form-field col-12 col-lg-11 col-xl-9 mb-2 mb-sm-4 pb-1 pb-sm-4">
				<div class="form-disabled-div"></div>
				<div class="d-flex mb-3 mb-sm-4 justify-content-center text-center field-info">
					<div>
						<p class="form-font font-weight-normal field-label"></p>
						<p class="field-description font-description mt-2 mt-sm-1 mb-0"></p>
					</div>
				</div>
				<div class="form-wrap mx-auto form-field-inner-wrapper">
					<div class="data-tooltip"></div>
					<div class="form-row input-main-wrapper"></div>
				</div>
			</div>`).appendTo(this.parent);
		}
		if(bond_assets.device.is_mobile_device()){
			this.$wrapper.addClass("form-question").addClass("mt-1");
			if(!this.df.description || this.df.hide_on_mobile){
				this.$wrapper.find(".field-info").removeClass("mb-3 mb-sm-4");
			}else{
				this.$wrapper.find(".field-label").addClass("mb-0");
			}
			this.$wrapper.find(".form-field-inner-wrapper").removeClass("form-wrap").addClass("pl-3 pr-3");
			this.$wrapper.find(".field-info").addClass("pl-3 pr-3");
			this.$wrapper.addClass("pl-4 pr-4");
		}

	}

	make_input(){
		if(this.$input){
			return;
		}
		this.$input = this.$wrapper.find("input, select");
	}

	set_input(){

	}

	set_value(html) {
	}

	set_inputs_fields(){
		let fields = [];
		if(this.custom_df && this.custom_df.fields){
			fields = this.custom_df.fields;
		}
		this.$wrapper.find(".form-field-inner-wrapper").remove();
	
		this._fields_dict = {};
		this._fields = [];
		this.values = [];
		let control, temp={};

		// generate number of fields
		for(var i=0;i<this.limit;i++){
			// traverse fields
			let temp = {};
			for(var j=0;j<fields.length;j++){
				control = this.make_field(fields[j]);
				temp[control.df.fieldname] = control;
			}
			this._fields.push(temp);
		}
		this.$wrapper.find(".form-wrap:not(:first)").addClass("mt-4 mt-sm-4");
		if(bond_assets.device.is_mobile_device()){
			this.$wrapper.find(".form-wrap").removeClass("pl-4 pr-4 form-question").addClass("pr-3 pl-3");
			this.$wrapper.find(".form-wrap").removeClass("form-wrap");
		}
		this.$wrapper.find("input, select").addClass("html-input-field");
		this.$wrapper.show();
	}

	set_limit(limit){
		if(is_null(limit)){
			limit = 5;
		}
		this.limit = limit;
	}
	make_field(field_df){
		field_df = $.extend(field_df, {
			onchange: (e)=>{return this.onchange()},
			parentfieldtype: "HTML"

		});
		let control = frappe.ui.form.make_control({
			df: field_df,
			parent: this.$wrapper,
			render_input:true,
		});
		return control;
	}

	get_input_value(){
		return this.get_value();
	}
	set_value(val){
		console.log(val);
	}
	bind_change_event() {
		var me = this;
		this.$input && this.$input.on("change", this.change || function(e) {
			let value = me.get_input_value();
			me.parse_validate_and_set_in_model(value, e);
			me.set_icons(value);
		});
		let datetime_fields = ["Date", "Datetime", "Time"];
		if(!in_list(datetime_fields, this.df.fieldtype)){
			// add hack for date field for being called multiple times.
			this.$input && this.$input.on("keypress", (e)=>{
				if(this.$wrapper.find(".invalid-tooltip").is(":visible")){
					this.hide_error_msg();
				}
			});
		}
	}
	get_value(){
		let value = this._get_values();
		if(value.length === 0 ){
			return null;
		}
		return value;

	}
	validate(value){
		return value;
	}
	onchange(e){
		let value = this.get_input_value();
		this.parse_validate_and_set_in_model(value, e);
		if(this.layout){
			this.layout.refresh_dependency(this).then(()=>{						
				frappe.run_serially([
					() => this.layout.move_to_next_field(this),
					() => frappe.timeout(0.1),
					() => this.layout.toggle_actions_buttons(this),
					() => frappe.timeout(0.1),
					() => this.layout.store_form(this),
				]);
			});
		}
	}
	get_fullfill_status(from_tab){
		return this._get_values().length === 0?false:true;
	}

	_get_values(){
		let values = [];
		if(!this._fields){
			return values;
		}
		let flag = true;
		for(var i=0;i<this._fields.length; i++){
			// traverse the field object
			let temp = {};
			for(var fieldobj in this._fields[i]){
				fieldobj = this._fields[i][fieldobj];
				if(fieldobj.df.reqd && !fieldobj.get_value()){
					flag = false;
					break;
				}
				temp[fieldobj.df.fieldname] = fieldobj.get_value();
			}
			if(!flag)
				break;
			values.push(temp);
		}
		if(!flag){
			return [];
		}
		return values;
	}
}
