import SignaturePad from "bond_assets/public/node_modules/signature_pad/dist/signature_pad.js";
frappe.ui.form.ControlSignatureV2 = class BondControlSignatureV2 extends frappe.ui.form.ControlData{
	saving = false;
	loading = false;
	make(){
		super.make();
		this.make_input();
	}
	make_input(){
		if(this.$body){
			return;
		}
		this.$body = this.get_body_wrapper();
		this.$input = this.$body.find("canvas");
		this.canvas = this.$input.get(0);
		this.input = this.canvas;
		this.$wrapper.find(".form-disabled-div").remove();
		this.set_input_attributes();
		this.attach_to_fieldname = ""
		this.file_type = "Signature";
		if(this.custom_df){
			this.file_type = this.custom_df.file_type? this.custom_df.file_type: "Signature";
			this.attach_to_fieldname = this.custom_df.attach_to_fieldname;
		}
		this.has_input = true;
		//this.bind_change_event();
		//this.bind_focusout();
		//this.init_input_mask();
		this.make_pad();

	}

	get_body_wrapper(){
		let $wrapper = $(`
		<div class="col-12 flex-column d-flex justify-content-center signature-field">
			<div class="control-input-field input-group input-group-lg   control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper disabled" data-fieldname='${this.df.fieldname}'>
				<canvas id="field-can" data-fieldname='${this.df.fieldname}' class="bold border" style="touch-action: none; user-select: none;border-radius: 8px;" width="536"></canvas>
				<button id="field-can-clear" class="btn btn-dark mt-3 mx-1 mb-2 primary-action-btn  text-center mb-2 clear-signature clear-canvas-btn">
				<span>Clean</span>
				<div class="clear-circle-icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="10.967" height="10.968" viewBox="0 0 10.967 10.968">
						<g id="Add_Guest_icon" data-name="Add Guest icon" transform="translate(-1.241 5.484) rotate(-45)">
							<path id="Контур_71762" data-name="Контур 71762" d="M0,9.51V0" transform="translate(4.755)" fill="none" stroke="#feffff" stroke-linecap="square" stroke-width="3"/>
							<path id="Контур_71763" data-name="Контур 71763" d="M0,9.51V0" transform="translate(9.51 4.755) rotate(90)" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="3"/>
						</g>
					</svg>                                                                                                                 
				</div>
			</button>
				<div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname='${this.df.fieldname}'>
					<div class="d-flex align-items-center justify-content-center">
						<div class="mr-2 pb-1">
							<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
						</div>
						<div class="text-left error-message"></div>
					</div>
				</div>
			</div>
			
		</div>
	`).appendTo(this.$wrapper.find(".input-main-wrapper"));

		return $wrapper.find(".control-input-field");
	}

	make_pad() {
		this.$clear_signature = this.$body.find(".clear-signature");

		this.signaturePad = new SignaturePad(this.canvas, {
			backgroundColor: 'rgba(255, 255, 255, 0)',
			penColor: 'rgb(0, 0, 0)',
		});

		this.signaturePad.addEventListener("endStroke", (e)=>{
			let value = this.get_input_value();
			this.parse_validate_and_set_in_model(value, e);
			if(this.layout && this.layout.call_events){
				this.layout.call_events({
					target: this.$input
				});
			}
			if(!this.signaturePad.isEmpty()){
				let _signature = {
					filename: "Signature.png",
					data: value,
					file_type: this.file_type,
					fieldname: this.attach_to_fieldname,
					is_signature: 1,
				};
				if(this.custom_df && this.custom_df.filename){
					_signature.filename = this.custom_df.filename;
				}
				this.frm.files[this.df.fieldname] = [_signature];

			}else{
				this.frm.files[this.df.fieldname] = [];
			}
			return false;
		});

		this.$body.find(".clear-signature").on("click", (e)=>{
			this.signaturePad.clear();
			return false;
		});

		setTimeout(()=>{
			$(this.canvas).attr("width", cint($(".control-input-field").css("width")));
		}, 200);

	}
	refresh_input(e) {
		// prevent to load the second time
		super.refresh_input(e);
	}

	get_input_value() {
		let value = this.signaturePad.toDataURL();
		if(this.signaturePad.isEmpty()){
			return ""
		}
		return value;
	}

	set_input(value){
	}

	set_formatted_input(value){

	}

	get_fullfill_status(){
		return this.signaturePad.isEmpty()?false:true;
	}
};
