	frappe.ui.form.ControlInput = class BondControlInput extends frappe.ui.form.Control{
	horizontal = true
	make() {
		// parent element
		super.make();
		this.set_input_areas();

		// set description
		this.set_max_width();
	}
	make_wrapper() {
		if(this.df.parentfieldtype === "HTML"){
			// HTML field can have multiple combo fields, so not need to init the parent wrapper again.
			this.$wrapper = $(`<div class="form-wrap mx-auto form-field-inner-wrapper">
				<div class="data-tooltip"></div>
				<div class="form-row input-main-wrapper"></div>
			</div>`).appendTo(this.parent);
		}else if(this.df.column_break || this.df.sync_with_field_up){
			this.$wrapper = $(this.parent)
				.find(".js-form-field").last();
			if(!this.$wrapper.hasClass("sync-with-field-up-control")){
				this.$wrapper.addClass("sync-with-field-up-control");
			}
		}else{
			this.$wrapper = $(`<div class="js-form-field col-12 col-lg-11 col-xl-9 mb-2 mb-sm-4 pb-1 pb-sm-4">
				<div class="form-disabled-div"></div>
				<div class="d-flex flex-column pt-0 pt-sm-3 mb-3 mb-sm-4 mx-auto form-wrap field-info">
					<p class="helvetica-font form-label-title field-label mb-2 pb-1"></p>
					<p class="helvetica-font form-label-subtitle field-description-1 m-0"></p>
				</div>
				<div class="form-wrap mx-auto form-field-inner-wrapper">
					<div class="data-tooltip"></div>
					<div class="form-row input-main-wrapper"></div>
				</div>
			</div>`).appendTo(this.parent);
		}
		if(bond_assets.device.is_mobile_device()){
			this.$wrapper.removeClass("mt-4").addClass("mt-5");
			if((!this.df.description || this.df.hide_on_mobile)
				&& !this.$wrapper.hasClass("sync-with-field-up-control")){
				this.$wrapper.find(".field-info").removeClass("mb-3 mb-sm-4");
			}else{
				this.$wrapper.find(".field-info").addClass("mb-0");
			}
			this.$wrapper.find(".form-field-inner-wrapper").removeClass("form-wrap")

			this.$wrapper.find(".field-info").removeClass("mx-auto form-wrap");
			if(this.frm){
				this.$wrapper.find(".form-field-inner-wrapper").addClass("pl-3 pr-3");
				this.$wrapper.find(".field-info").addClass("pl-3 pr-3");
				this.$wrapper.addClass("form-question mt-4");
				this.$wrapper.addClass("pl-4 pr-4");
			}
		}else{
		}

	}
	toggle_label(show) {
		this.$wrapper.find(".field-info .field-label").toggleClass("hide", !show);
	}
	toggle_description(show) {
		this.$wrapper.find(".field-info .field-description-1").toggleClass("hide", !show);
	}
	set_input_areas() {
		this.label_area = this.$label;
		this.label_span = this.$label;
		this.input_area = this.$wrapper.find(`.control-input-field[data-fieldname='${this.df.fieldname}']`).get(0);
		this.$input_wrapper = this.$wrapper.find(`.control-input-field-wrapper[data-fieldname='${this.df.fieldname}']`);
		this.$input_group = this.$wrapper.find(`.control-input-field[data-fieldname='${this.df.fieldname}']`);
		// keep a separate display area to rendered formatted values
		// like links, currencies, HTMLs etc.
		this.disp_area = this.$wrapper.find(".control-input-value").get(0);
	}
	set_input_attributes() {
		// no need to set the attributes
		this.$wrapper.find(`${this.html_element}[data-fieldname='${this.df.fieldname}']`)
			.attr("autocomplete", "off")

		if(this.is_capitalize_input()){
			this.$wrapper.find(`${this.html_element}[data-fieldname='${this.df.fieldname}']`)
				.css("text-transform", "uppercase");
		}
		if(!in_list(["Check"], this.df.fieldtype)){
			this.$wrapper.find(`${this.html_element}[data-fieldname='${this.df.fieldname}']`)
				.attr("placeholder", this.df.placeholder || "")
				.addClass("js-text-form form-control border-0");
		}

		if (in_list(['Data', 'Link', 'Dynamic Link', 'Password', 'Select', 'Read Only', 'Attach', 'Attach Image'],
			this.df.fieldtype)) {
			this.$wrapper.find(`${this.html_element}[data-fieldname='${this.df.fieldname}']`)
					.attr("maxlength", this.df.length || 140);
			this.$wrapper.find("input")
				.attr("name", "notASearchField")
				.attr("autocomplete", "off");
		}
	}
	set_max_width() {
		if(this.horizontal) {
			this.$wrapper.addClass("input-max-width");
		}
	}

	// update input value, label, description
	// display (show/hide/read-only),
	// mandatory style on refresh
	refresh_input() {
		var me = this;
		var make_input = function() {
			if (!me.has_input) {
				me.make_input();
				if (me.df.on_make) {
					me.df.on_make(me);
				}
			}
		};

		var update_input = function() {
			if (me.doctype && me.docname) {
				me.set_input(me.value);
			} else {
				me.set_input(me.value || null);
			}
		};
		if (me.disp_status != "None") {
			// refresh value
			if (me.frm) {
				me.value = this.get_model_value()
			} else if (me.doc) {
				me.value = me.doc[me.df.fieldname];
			}

			if (me.can_write()) {
				me.disp_area && $(me.disp_area).toggle(false);
				$(me.input_area).toggle(true);
				me.$input && me.$input.prop("disabled", false);
				make_input();
				update_input();
			} else {
				if (me.only_input) {
					make_input();
					update_input();
				} else {
					$(me.input_area).toggle(false);
					if (me.disp_area) {
						me.set_disp_area(me.value);
						$(me.disp_area).toggle(true);
					}
				}
				me.$input && me.$input.prop("disabled", true);
			}

			if(!this.df.column_break && !this.df.sync_with_field_up){
				me.set_label();
				me.set_description();
				me.validate_field_info();
			}else if(this.df.column_break){
				me.add_column_break();
			}
			me.set_tooltip();
			me.add_icons();
			me.set_custom_classes();
			me.set_mandatory(me.value);
			me.set_bold();
			me.trigger_callback();

			if(this.df.sync_with_field_bottom){
				this.sync_with_field_bottom();
			}
		}
	}

	can_write() {
		return this.disp_status == "Write";
	}

	disable_field(){
		this.toggle_enable_disable(true);
	}
	enable_field(){
		this.toggle_enable_disable(false);
	}
	toggle_enable_disable(enable){
		let _find = "data-fieldname='" + this.df.fieldname + "'";
		let flag = enable;
		if(in_list(["Select", "MultiSelect"], this.df.fieldtype)){
			this.$wrapper.find(`select[${_find}]`).toggleClass("disabled", flag);
			this.$wrapper.find(`select[${_find}]`).next().toggleClass("disabled", flag);

		}else if(in_list(["Check"], this.df.fieldtype)){
			this.$wrapper.find(`label[${_find}]`).toggleClass("disabled", flag);
		}else if(this.df.fieldtype == "Address"){
			// no need to apply filter for fieldname,
			// as address field can't be column breaked
			this.$wrapper.find(".input-group").toggleClass("disabled", flag);
		}else if(in_list(["Int"], this.df.fieldtype)){
			// disabled input
			this.$wrapper.find(`.input-group[${_find}]`).toggleClass("disabled", flag);
			// disabled input field label
			this.$wrapper.find(`.int-field-label[${_find}]`).toggleClass("text-muted", flag);
		}else{
			this.$wrapper.find(`.control-input-field[${_find}]`).toggleClass("disabled", flag);
		}
		
		this.$wrapper.find(".form-disabled-div").toggleClass("form-disabled", flag);
		this.$wrapper.find(`input[${_find}],select[${_find}], button[${_find}]`)
				.prop("disabled", flag);

	}
	set_disp_area(value) {
		if(in_list(["Currency", "Int", "Float"], this.df.fieldtype)
			&& (this.value === 0 || value === 0)) {
			// to set the 0 value in readonly for currency, int, float field
			value = 0;
		} else {
			value = this.value || value;
		}
		if (this.df.fieldtype === 'Data') {
			value = frappe.utils.escape_html(value);
		}
		let doc = this.doc || (this.frm && this.frm.doc);
		let display_value = frappe.format(value, this.df, { no_icon: true, inline: true }, doc);
		this.disp_area && $(this.disp_area).html(display_value);
	}

	bind_change_event() {
		var me = this;
		this.$input && this.$input.on("change", this.change || function(e) {
			let value = me.get_input_value();
			me.parse_validate_and_set_in_model(value, e);
			me.set_icons(value);
		});
		let datetime_fields = ["Date", "Datetime", "Time"];
		if(!in_list(datetime_fields, this.df.fieldtype)){
			// add hack for date field for being called multiple times.
			this.$input && this.$input.on("keypress", (e)=>{
				if(this.$wrapper.find(".invalid-tooltip").is(":visible")){
					this.hide_error_msg();
				}
			});
		}
		
	}
	bind_focusout() {
		// on touchscreen devices, scroll to top
		// so that static navbar and page head don't overlap the input
		return; // don't need this functionality on website form
		if (frappe.dom.is_touchscreen()) {
			var me = this;
			this.$input && this.$input.on("focusout", function() {
				if (frappe.dom.is_touchscreen()) {
					frappe.utils.scroll_to(me.$wrapper);
				}
			});
		}
	}
	set_label(label) {

		this.$label = this.$wrapper.find(".field-info .field-label").empty();
		if(this.df.label){
			this.$label.text(`${this.df.label}`);
			if(bond_assets.device.is_mobile_device()){
				this.$label.addClass("form-question__title")
					.removeClass("form-font font-weight-normal");
			}
		}
	}
	set_description(description) {
		if(this.df.hide_on_mobile && bond_assets.device.is_mobile_device()){
			return;
		}
		if (!is_null(description)) {
			this.df.description = description;
		}
		if (this.only_input || this.df.description===this._description) {
			return;
		}
		
		this.$description = this.$wrapper.find(".field-info .field-description-1");
		if (this.df.description) {
			this.$description.text(`${this.df.description}`).addClass("mt-2 mt-sm-1 mb-0");
		} else {
			this.set_empty_description();
		}

		if(this.$description.hasClass("text-muted") && this.df.bold_description){
			this.$description.removeClass("text-muted");
		}
		this._description = this.df.description;
	}
	set_new_description(description) {
		this.$description.text(`${this.df.description}`);

	}
	set_empty_description() {
		this.$wrapper.find(".field-info .field-description-1").remove();
	}
	set_mandatory(value) {
		this.$wrapper.toggleClass("has-error", (this.df.reqd && is_null(value)) ? true : false);
	}
	set_bold() {
		if(this.$input) {
			this.$input.toggleClass("bold", !!(this.df.bold || this.df.reqd));
		}
		if(this.disp_area) {
			$(this.disp_area).toggleClass("bold", !!(this.df.bold || this.df.reqd));
		}
	}

	add_icons(){

		this.$icons = this.$wrapper.find(".check-icons").empty();
		if(!this.df.hide_icons){
			if(this.df.icons){
				$(this.df.icons).appendTo(this.$icons).addClass("mr-2 mr-sm-3");
			}else{
				$(`<img class="active d-none" src="/assets/bond_assets/images/active-check.svg" alt="">
							`).appendTo(this.$icons);
				$(`<img class="inactive" src="/assets/bond_assets/images/inactive-check.svg" alt="">`)
						.appendTo(this.$icons);
				//.addClass("mr-2 mr-sm-3");
			}
		}else{
			this.$wrapper.find(".check-icons").remove();
			this.$wrapper.find(".description-and-title")
				.addClass("ml-4 pl-sm-3")
		}

	}

	set_icons(value){
		if(is_null(value)){
			this.$icons.find(".active").addClass("d-none");
			this.$icons.find(".inactive").removeClass("d-none");
		}else{
			this.$icons.find(".inactive").addClass("d-none");
			this.$icons.find(".active").removeClass("d-none");
		}
	}

	set_tooltip(){
		this.$tooltip = this.$wrapper.find(".data-tooltip").empty();

		if(this.df.tooltip){
			this.$tooltip.attr("data-toggle", "tooltip")
						.attr("data-html", "true")
						.attr("title", `<p>${this.df.tooltip}</p>`)
						.addClass("mr-1");
			$(`<img src="/assets/bond_assets/images/icons/info-dark.png" alt="" class="img-fluid" style="width: 25px">`)
				.appendTo(this.$tooltip);

			this.$tooltip.tooltip('enable');
		}
	}

	set_custom_classes(){
		if(this.df.custom_classes){
			window.custom_classes  = this.df.custom_classes;
		}
	}

	trigger_callback(){

		if(this.df.callback){
			try{
				let fieldobj = this;
				eval(this.df.callback);
			}catch(e){
				console.log(e);
			}
		}
	}

	validate_field_info(){
		if(this.df.hide_field_info){
			this.$wrapper.find(".field-info").remove();
		}
	}

	remove_field_info(){
		this.$wrapper.find(".field-info").remove();
	}

	is_capitalize_input(){
		return this.custom_df && this.custom_df.capitalize_input;
	}

	sync_with_field_bottom(){

		if(this.sync_with_bottom_field){
			return;
		}
		/*
		this.$wrapper.removeClass("mb-2 pb-sm-4 mb-sm-4 pb-1");

		if(!this.df.column_break){
			this.$wrapper.find(".input-main-wrapper")
				.addClass("mb-3 mb-sm-4");
		}
		*/
		this.sync_with_bottom_field = true;

	}

	sync_with_field_up($wrapper){
		if(this.df.sync_with_field_up){
			$wrapper.addClass("sync-with-field-up");
		}
		let is_mobile_device = bond_assets.device.is_mobile_device();
		if(!this.$wrapper.hasClass("column-break")){
			if(!is_mobile_device){
				/*this.$wrapper.find(".sync-with-field-up").addClass("mt-4 mt-sm-4");*/
			}else if(is_mobile_device && this.$wrapper.find(".checkbox-wrapper").length){
				this.$wrapper.find(".input-main-wrapper > div.checkbox-wrapper")
					.addClass("mb-4 mb-sm-4");
			}else if(is_mobile_device && this.$wrapper.find(".data-range-wrapper").length){
				this.$wrapper.find(".input-main-wrapper > div.data-range-wrapper")
					.addClass("mb-4 mb-sm-4");
	
				this.$wrapper.find(".input-main-wrapper > div.title-and-subtitle")
					.addClass("mb-4 mb-sm-4");
			}
		}else{
			if(!bond_assets.device.is_mobile_device()){
				/*
					this.$wrapper.find(".sync-with-field-up:not(:first)").addClass("mt-4 mt-sm-4");
				*/
			}else if(is_mobile_device && this.$wrapper.find(".checkbox-wrapper").length){
				this.$wrapper.find(".input-main-wrapper > div.checkbox-wrapper")
					.addClass("mb-4 mb-sm-4");
			}else if(is_mobile_device && this.$wrapper.find(".data-range-wrapper").length){
				this.$wrapper.find(".input-main-wrapper > div.data-range-wrapper")
					.addClass("mb-4 mb-sm-4");
	
				this.$wrapper.find(".input-main-wrapper > div.title-and-subtitle")
					.addClass("mb-4 mb-sm-4");
			}
		}

	}

	unsync_with_field_bottom(){
		this.$wrapper.addClass("mb-2 mb-sm-5 pb-4");
	}

	add_column_break(){

		this.$icons = $("<div>");
		// small margin
		if(!this.$wrapper.hasClass("column-break")){
			this.$wrapper.addClass("column-break");
			// main wrapper doesn't need data tooltip
			this.$wrapper.find(".input-main-wrapper").find(".data-tooltip").remove();
			this.$wrapper.removeClass("pb-sm-4 pb-1 pl-0");
		}
		// doesn't work for tooltip object
		let is_mobile_device = bond_assets.device.is_mobile_device();
		this.$wrapper.find(".input-main-wrapper > div:not(.sync-with-field-up)")
			.addClass("col-6 col-sm-6").removeClass("col-12");
		if(!is_mobile_device){
			/*
			this.$wrapper.find(".input-main-wrapper > div:not(.sync-with-field-up)")
				.addClass("mb-4 mb-sm-4");
			*/
		}else if(is_mobile_device && this.$wrapper.find(".checkbox-wrapper").length){
			this.$wrapper.find(".input-main-wrapper > .checkbox-wrapper")
				.addClass("mb-4 mb-sm-4");
		}
		
		if(this.df.fieldtype == "Check"){
			this.$wrapper.find(".input-main-wrapper .col-6:nth-child(odd)")
				.addClass("pr-2");
			this.$wrapper.find(".input-main-wrapper .col-6:nth-child(even)")
				.addClass("pl-2");
		}

		// remove margin bottom from last two class
		this.$wrapper.find(".input-main-wrapper")
				.removeClass("mb-4 mb-sm-4");
	}
}
