
frappe.ui.form.ControlData = class BondControlData extends frappe.ui.form.ControlInput{
	static html_element = "input";
	static input_type = "text";
	make_input() {
		if(this.$input) return;

		let $input = this.get_data_input_wrapper();
		this.set_input_type_and_html_element()
		$("<"+ this.html_element +">")
			.attr("type", this.input_type)
			.attr("data-fieldname", this.df.fieldname)
			.prependTo($input);

		this.set_input_attributes();

		this.$input = this.$wrapper.find(`${this.html_element}[data-fieldname='${this.df.fieldname}']`);

		this.input = this.$input.get(0);
		this.setup_ssn();
		this.has_input = true;
		this.bind_change_event();
		this.bind_focusout();
		this.init_input_mask();

		// somehow this event does not bubble up to document
		// after v7, if you can debug, remove this
	}

	setup_ssn(){
		let possible_password_options = ["SSN"];
		let me = this;
		if(this.df.options == "SSN"){
		}
	}

	set_input_type_and_html_element(){
		let { html_element, input_type } = this.constructor;
		this.html_element = html_element;
		this.input_type = input_type;
	}
	get_data_input_wrapper(){
		let $input = $(`<div class="col-12 d-flex justify-content-center">
			<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
				data-fieldname='${this.df.fieldname}'>
				${this.get_input_icon()}
				<div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname="${this.df.fieldname}">
					<div class="d-flex align-items-center justify-content-center">
						<div class="text-left error-message"></div>
					</div>
				</div>
			</div>
		</div>`).appendTo(this.$wrapper.find(".input-main-wrapper"));
		if(!bond_assets.device.is_mobile_device()){
			//$input.addClass("pl-0");
		}
		this.sync_with_field_up($input);

		return $input.find(".control-input-field");
	}

	get_wrapper(){

	}
	get_input_icon(){

		if(this.custom_df && this.custom_df.custom_icon_name){
			return frappe.ui.form.custom_icons[this.custom_df.custom_icon_name];
		}
		return `<div class="input-group-prepend">
			<span class="input-group-text">
				<span class="circle-icon">
					<svg id="PENCIL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.352" height="13.394" viewBox="0 0 12.352 13.394">
						<defs>
							<clipPath id="clip-path">
								<rect id="Прямоугольник_5177" data-name="Прямоугольник 5177" width="12.352" height="13.394" fill="#fff"/>
							</clipPath>
						</defs>
						<g id="Сгруппировать_36833" data-name="Сгруппировать 36833" clip-path="url(#clip-path)">
							<path id="Контур_92588" data-name="Контур 92588" d="M11.415.733A3.268,3.268,0,0,1,11.609,5.1L7.051,1.024A3.175,3.175,0,0,1,11.415.733ZM10.83,5.907,5.437,11.746,1.071,13.327A.761.761,0,0,1,.037,12.4L.879,7.673,6.272,1.834Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd"/>
						</g>
					</svg>  
					<svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
						<g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
							<path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
						</g>
					</svg> 
				</span>
			</span>
		</div>`;
	}
	get_input_value(){
		return this.$input.val();
	}
	set_input(value, e) {
		if(this.is_capitalize_input()){
			value = cstr(value).toUpperCase();
		}
		this.last_value = this.value;
		this.value = value;
		this.set_formatted_input(value, e);
		this.set_disp_area(value, e);
	}
	set_formatted_input(value, e) {
		this.$input.val(this.format_for_input(this.validate(value)));

		if(!is_null(value)){
			this.$input.parent().addClass("not-empty");
		}else{
			this.$input.parent().removeClass("not-empty");
		}

		if(this.$input.parent().hasClass("disabled")){
			this.$input.parent().removeClass("disabled");
		}
	}
	format_for_input(val) {
		return val==null ? "" : val;
	}
	validate(v, e) {
		var me = this;
		if(this.df.is_filter) {
			return v;
		}
		v = is_null(v)?cstr(v):v;
		if(this.df.options == 'Email') {
			if(v+''=='') {
				return '';
			}

			var email_list = frappe.utils.split_emails(v);
			if (!email_list) {
				// invalid email
				return '';
			} else {
				var invalid_email = false;
				email_list.forEach(function(email) {
					if (!me.validate_email(email)) {
						me.show_error_msg(__("Invalid Email: {0}", [email]));
						invalid_email = true;
					}
				});

				if (invalid_email) {
					// at least 1 invalid email
					return '';
				} else {
					me.hide_error_msg();
					// all good
					return v;
				}
			}

		} else if (this.df.options == "Name"){
			if(v+''=='') {
				return '';
			}
			if(cstr(v).length <= 1){
				me.show_error_msg(__("Name must be two or more letters."));
				return '';
			}
			if(!/^[a-zA-Z]+$/.test(v)){
				me.show_error_msg(__("Only letters can be used for name."));
				return '';
			}
			return v;
		} else{
			return v;
		}
	}
	validate_email(email){
		let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regExp.test(email);

	}

	init_input_mask(){
		let input_mask = null;
		if(in_list(["Phone", "Mobile"], this.df.options)){
			input_mask = Inputmask({
				mask: "(999)-999-9999",
				clearIncomplete: true,
			}).mask(this.get_input_tag());
			this.$input.attr("inputmode", "numeric");

		}else if(this.df.options == "Email!!"){
			input_mask = Inputmask({
				mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
				greedy: false,
				onBeforePaste: function (pastedValue, opts) {
					pastedValue = pastedValue.toLowerCase();
					return pastedValue.replace("mailto:", "");
				},
				definitions: {
					'*': {
						validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
						casing: "lower"
					}
				}
			}).mask(this.get_input_tag());
		}else if(this.df.options === "VIN"){
			input_mask = Inputmask({
				mask: "V{8}vV{4}9{4}",
				definitions: {
					'V': {
						validator: "[A-HJ-NPR-Za-hj-npr-z\\d]",
						cardinality: 1,
						casing: "upper"
					},
					'v': {
						validator: "[Xx\\d]",
						cardinality: 1,
						casing: "upper"
					}
				},
				clearIncomplete: true,
				autoUnmask: true
			}).mask(this.get_input_tag());
		}else if(this.df.options == "Int" && this.df.length){
			input_mask = Inputmask({
				mask: `9{${1, this.df.length}}`,
				clearIncomplete: true,
				reverse: false,
				showMaskOnFocus: true,
				showMaskOnHover: false,
			}).mask(this.get_input_tag());
			this.$input.attr("inputmode", "numeric");
			this.$input.css("text-align", "left");
		}else if(this.df.options == "Int"){
			input_mask = Inputmask({
				regex: "\\d*",
				showMaskOnHover: false,
			}).mask(this.$input.get(0));
			this.$input.attr("inputmode", "numeric");
			this.$input.css("text-align", "left");
		}else if(this.df.options == "Currency" || this.df.fieldtype=="Currency"){
			input_mask = Inputmask({
				alias: "currency",
				rightAlignNumerics: false,
				showMaskOnHover: false,
			}).mask(this.get_input_tag());
			this.$input.css("text-align", "left");
		}else if(this.custom_df && this.custom_df.input_mask_format){
			input_mask = Inputmask({
				mask: this.custom_df.input_mask_format,
				clearIncomplete: true,
				reverse: false,
				showMaskOnFocus: true,
				showMaskOnHover: false,
			}).mask(this.$input.get(0));
			this.$input.attr("inputmode", "numeric");
		}

		this._input_mask = input_mask;
	}

	get_input_tag(){
		return this.$input.get(0);
	}
};


String.prototype.replaceAt = function(index, character) {
    return this.substr(0, index) + character + this.substr(index+character.length);
}

