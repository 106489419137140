/*
*/

frappe.provide("frappe.ui.form.field_validations");

$.extend(frappe.ui.form.field_validations, {

    validate_zip_code: function($input){
        $input.keypress(function(e){
            let value = $(this).val();
            if(value && !$.isNumeric(value)){
                return false;
            }
            if(cstr(value).length >= 5){
                return false;
            }
        });

        $input.on("input", function(e){
            let value = $(this).val();
            if(!$.isNumeric(value)){
                $(this).val('');
            }
        });
    }
});